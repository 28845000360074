.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: fixed;
top: 0;
right: 0;
left: 0;
z-index: 9;
background: var(--bg-color-1);
text-align: center;
}
@media (max-width: 600px) {
.cls2 {
font-weight: 600 !important;
font-size: 16px !important
}

.cls2 * {
font-weight: 600 !important;
font-size: 16px !important;
}
}
.cls2.hide {
display: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vYmlsZS9Nb2JpbGVQYWdlSGVhZGVyLVNsaWNrSGVhZGVyLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsZUFBZTtBQUNmLE1BQU07QUFDTixRQUFRO0FBQ1IsT0FBTztBQUNQLFVBQVU7QUFDViw2QkFBNkI7QUFDN0Isa0JBQWtCO0FBZWxCO0FBYkE7QUFYQTtBQVlBLDJCQUEyQjtBQUMzQjtBQVdBOztBQVRBO0FBQ0EsMkJBQTJCO0FBQzNCLDBCQUEwQjtBQUMxQjtBQUNBO0FBRUE7QUFDQSxhQUFhO0FBQ2IiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbW9iaWxlL01vYmlsZVBhZ2VIZWFkZXItU2xpY2tIZWFkZXIubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbnBvc2l0aW9uOiBmaXhlZDtcbnRvcDogMDtcbnJpZ2h0OiAwO1xubGVmdDogMDtcbnotaW5kZXg6IDk7XG5iYWNrZ3JvdW5kOiB2YXIoLS1iZy1jb2xvci0xKTtcbnRleHQtYWxpZ246IGNlbnRlcjtcblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG5mb250LXdlaWdodDogNjAwICFpbXBvcnRhbnQ7XG5mb250LXNpemU6IDE2cHggIWltcG9ydGFudDtcblxuKiB7XG5mb250LXdlaWdodDogNjAwICFpbXBvcnRhbnQ7XG5mb250LXNpemU6IDE2cHggIWltcG9ydGFudDtcbn1cbn1cblxuJi5oaWRlIHtcbmRpc3BsYXk6IG5vbmU7XG59XG59Il19 */