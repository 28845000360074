.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: inline-block;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;

&.highlight {
padding: 4px 8px;
background: var(--bg-color-2);
border-radius: 4px;
}
}