.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin: 60px 30px 0;
}
@media (max-width: 600px) {
.cls2 {
margin: 0 28px;
text-align: center
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RhYnMvSGlzdG9yeUNhcmRUcmFuc2ZlcnNUYWItRW1wdHlTdHlsZWQubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxtQkFBbUI7QUFNbkI7QUFKQTtBQUxBO0FBTUEsY0FBYztBQUNkO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90YWJzL0hpc3RvcnlDYXJkVHJhbnNmZXJzVGFiLUVtcHR5U3R5bGVkLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5tYXJnaW46IDYwcHggMzBweCAwO1xuXG5AbWVkaWEgKG1heC13aWR0aDogNjAwcHgpIHtcbm1hcmdpbjogMCAyOHB4O1xudGV4dC1hbGlnbjogY2VudGVyO1xufVxufSJdfQ== */