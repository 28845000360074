.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
top: 7px;
right: 9px;
width: 8px;
height: 8px;
background: rgba(255, 82, 82, 1);
box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
border: 2px solid var(--bg-color-2);
border-radius: 100%;
transition: 0.25s border-color;
animation: pulse-red 2s infinite;

@keyframes pulse-red {
0% {
transform: scale(0.9);
box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
}

70% {
transform: scale(1);
box-shadow: 0 0 0 6px rgba(255, 82, 82, 0);
}

100% {
transform: scale(0.9);
box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
}
}

@media (max-width: 500px) {
top: -3px;
right: 0;
border-width: 1px;
}
}