@value cls1 as a0 from "./UserDropDown-ChevronDownStyledSvg.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
margin-bottom: 3px;
line-height: 1;
font-size: 15px;
font-weight: 600;
transition: 0.25s color;
cursor: pointer;

&.center {
justify-content: center;
}

.a0 {
margin-left: 10px;
color: #2281ff;
transform-origin: center;
transform: scaleY(1);
transition: 0.25s transform;
}

@media (max-width: 500px) {
justify-content: flex-start !important;
font-size: 20px;
}
}