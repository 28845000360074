.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 6px;
height: 6px;
border-radius: 6px;
background-color: #dfe2e8;
transition: 0.25s width;
}
.cls2.active {
width: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3BhZ2VzL01vYmlsZUhvbWVQYWdlLURvdC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLFVBQVU7QUFDVixXQUFXO0FBQ1gsa0JBQWtCO0FBQ2xCLHlCQUF5QjtBQUN6Qix1QkFBdUI7QUFLdkI7QUFIQTtBQUNBLFdBQVc7QUFDWCIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9wYWdlcy9Nb2JpbGVIb21lUGFnZS1Eb3QubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbndpZHRoOiA2cHg7XG5oZWlnaHQ6IDZweDtcbmJvcmRlci1yYWRpdXM6IDZweDtcbmJhY2tncm91bmQtY29sb3I6ICNkZmUyZTg7XG50cmFuc2l0aW9uOiAwLjI1cyB3aWR0aDtcblxuJi5hY3RpdmUge1xud2lkdGg6IDIwcHg7XG59XG59Il19 */