.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: space-between;
flex-wrap: wrap;
align-items: center;
margin-bottom: 20px;
padding: 0 32px;
}
.cls2.noMarginBottom {
margin-bottom: 0;
}
@media (max-width: 1100px) {
.cls2 {
padding: 0 20px
}
}
@media (max-width: 420px) {
.cls2 {
padding: 0 15px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9IaXN0b3J5T3BlcmF0aW9uc1RlbXBsYXRlLU9wZXJhdGlvbkxpc3RIZWFkZXIubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxhQUFhO0FBQ2IsOEJBQThCO0FBQzlCLGVBQWU7QUFDZixtQkFBbUI7QUFDbkIsbUJBQW1CO0FBQ25CLGVBQWU7QUFhZjtBQVhBO0FBQ0EsZ0JBQWdCO0FBQ2hCO0FBRUE7QUFkQTtBQWVBO0FBTUE7QUFMQTtBQUVBO0FBbEJBO0FBbUJBO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvSGlzdG9yeU9wZXJhdGlvbnNUZW1wbGF0ZS1PcGVyYXRpb25MaXN0SGVhZGVyLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5kaXNwbGF5OiBmbGV4O1xuanVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuZmxleC13cmFwOiB3cmFwO1xuYWxpZ24taXRlbXM6IGNlbnRlcjtcbm1hcmdpbi1ib3R0b206IDIwcHg7XG5wYWRkaW5nOiAwIDMycHg7XG5cbiYubm9NYXJnaW5Cb3R0b20ge1xubWFyZ2luLWJvdHRvbTogMDtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDExMDBweCkge1xucGFkZGluZzogMCAyMHB4O1xufVxuXG5AbWVkaWEgKG1heC13aWR0aDogNDIwcHgpIHtcbnBhZGRpbmc6IDAgMTVweDtcbn1cbn0iXX0= */