.cls1 { /*!*/ }
.cls2 {
composes: cls1;

justify-content: center;
font-size: 20px;
}
@media (min-width: 600px) {
.cls2 {
align-self: flex-start
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9BdXRoVGVtcGxhdGUtQXV0aEJ1dHRvbi5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLHVCQUF1QjtBQUN2QixlQUFlO0FBS2Y7QUFIQTtBQU5BO0FBT0E7QUFFQTtBQURBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9BdXRoVGVtcGxhdGUtQXV0aEJ1dHRvbi5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuanVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG5mb250LXNpemU6IDIwcHg7XG5cbkBtZWRpYSAobWluLXdpZHRoOiA2MDBweCkge1xuYWxpZ24tc2VsZjogZmxleC1zdGFydDtcbn1cbn0iXX0= */