.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
position: relative;
width: 100%;
min-height: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9Qcml2YXRlVGVtcGxhdGUtUGFnZUNvbnRlbnRXcmFwLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsYUFBYTtBQUNiLHNCQUFzQjtBQUN0QixrQkFBa0I7QUFDbEIsV0FBVztBQUNYLGdCQUFnQjtBQUNoQiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvUHJpdmF0ZVRlbXBsYXRlLVBhZ2VDb250ZW50V3JhcC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuZGlzcGxheTogZmxleDtcbmZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG5wb3NpdGlvbjogcmVsYXRpdmU7XG53aWR0aDogMTAwJTtcbm1pbi1oZWlnaHQ6IDEwMCU7XG59Il19 */