.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 13px;
line-height: 170%;
}
.cls2 b {
font-weight: 600;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vZGFscy9UcmFuc2ZlckRldGFpbHMvQ2FyZFJlcXVpc2l0ZXMtUmVxdWlzaXRlcy5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGVBQWU7QUFDZixpQkFBaUI7QUFLakI7QUFIQTtBQUNBLGdCQUFnQjtBQUNoQiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2RhbHMvVHJhbnNmZXJEZXRhaWxzL0NhcmRSZXF1aXNpdGVzLVJlcXVpc2l0ZXMubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmZvbnQtc2l6ZTogMTNweDtcbmxpbmUtaGVpZ2h0OiAxNzAlO1xuXG5iIHtcbmZvbnQtd2VpZ2h0OiA2MDA7XG59XG59Il19 */