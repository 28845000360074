@value cls1 as a0 from "./UserDropDown-UserName.module.css";
@value cls1 as a1 from "./UserDropDown-UserEmail.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 267px;
margin-left: -40px;
}
.cls2 .a0 {
font-size: 16px;
}
.cls2 .a1 {
margin-top: 8px;
font-size: 13px;
}
@media (max-width: 768px) {
.cls2 {
width: 100%;
margin-top: -15px;
margin-left: 0
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Vc2VyRHJvcERvd24tRHJvcERvd25TdHlsZWQubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSwyREFBMkQ7QUFDM0QsNERBQTREOztBQUU1RCxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxZQUFZO0FBQ1osa0JBQWtCO0FBZ0JsQjtBQWRBO0FBQ0EsZUFBZTtBQUNmO0FBRUE7QUFDQSxlQUFlO0FBQ2YsZUFBZTtBQUNmO0FBRUE7QUFmQTtBQWdCQSxXQUFXO0FBQ1gsaUJBQWlCO0FBQ2pCO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvVXNlckRyb3BEb3duLURyb3BEb3duU3R5bGVkLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAdmFsdWUgY2xzMSBhcyBhMCBmcm9tIFwiLi9Vc2VyRHJvcERvd24tVXNlck5hbWUubW9kdWxlLmNzc1wiO1xuQHZhbHVlIGNsczEgYXMgYTEgZnJvbSBcIi4vVXNlckRyb3BEb3duLVVzZXJFbWFpbC5tb2R1bGUuY3NzXCI7XG5cbi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbndpZHRoOiAyNjdweDtcbm1hcmdpbi1sZWZ0OiAtNDBweDtcblxuLmEwIHtcbmZvbnQtc2l6ZTogMTZweDtcbn1cblxuLmExIHtcbm1hcmdpbi10b3A6IDhweDtcbmZvbnQtc2l6ZTogMTNweDtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDc2OHB4KSB7XG53aWR0aDogMTAwJTtcbm1hcmdpbi10b3A6IC0xNXB4O1xubWFyZ2luLWxlZnQ6IDA7XG59XG59Il19 */