.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
align-items: center;
width: 240px;
min-width: 240px;
max-height: 100vh;
overflow: auto;
box-sizing: border-box;
padding: 16px 0 32px;
background: var(--bg-color-1);
box-shadow: 10px 0 -2px 5px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
.cls2 {
position: fixed;
top: 0;
bottom: 0;
left: 0;
z-index: 10;
padding: 80px 0 40px;
width: 280px;
min-width: 280px;
transform: translateX(-100%);
opacity: 0;
transition:
0.25s transform,
0.25s opacity
}

.cls2.opened {
transform: translateX(0);
opacity: 1;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9TaWRlYmFyLUNvbnRhaW5lci5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGFBQWE7QUFDYixzQkFBc0I7QUFDdEIsbUJBQW1CO0FBQ25CLFlBQVk7QUFDWixnQkFBZ0I7QUFDaEIsaUJBQWlCO0FBQ2pCLGNBQWM7QUFDZCxzQkFBc0I7QUFDdEIsb0JBQW9CO0FBQ3BCLDZCQUE2QjtBQUM3Qiw4Q0FBOEM7QUFzQjlDO0FBcEJBO0FBZkE7QUFnQkEsZUFBZTtBQUNmLE1BQU07QUFDTixTQUFTO0FBQ1QsT0FBTztBQUNQLFdBQVc7QUFDWCxvQkFBb0I7QUFDcEIsWUFBWTtBQUNaLGdCQUFnQjtBQUNoQiw0QkFBNEI7QUFDNUIsVUFBVTtBQUNWOzs7QUFTQTs7QUFMQTtBQUNBLHdCQUF3QjtBQUN4QixVQUFVO0FBQ1Y7QUFDQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvU2lkZWJhci1Db250YWluZXIubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmRpc3BsYXk6IGZsZXg7XG5mbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuYWxpZ24taXRlbXM6IGNlbnRlcjtcbndpZHRoOiAyNDBweDtcbm1pbi13aWR0aDogMjQwcHg7XG5tYXgtaGVpZ2h0OiAxMDB2aDtcbm92ZXJmbG93OiBhdXRvO1xuYm94LXNpemluZzogYm9yZGVyLWJveDtcbnBhZGRpbmc6IDE2cHggMCAzMnB4O1xuYmFja2dyb3VuZDogdmFyKC0tYmctY29sb3ItMSk7XG5ib3gtc2hhZG93OiAxMHB4IDAgLTJweCA1cHggcmdiYSgwLCAwLCAwLCAwLjEpO1xuXG5AbWVkaWEgKG1heC13aWR0aDogNzY4cHgpIHtcbnBvc2l0aW9uOiBmaXhlZDtcbnRvcDogMDtcbmJvdHRvbTogMDtcbmxlZnQ6IDA7XG56LWluZGV4OiAxMDtcbnBhZGRpbmc6IDgwcHggMCA0MHB4O1xud2lkdGg6IDI4MHB4O1xubWluLXdpZHRoOiAyODBweDtcbnRyYW5zZm9ybTogdHJhbnNsYXRlWCgtMTAwJSk7XG5vcGFjaXR5OiAwO1xudHJhbnNpdGlvbjpcbjAuMjVzIHRyYW5zZm9ybSxcbjAuMjVzIG9wYWNpdHk7XG5cbiYub3BlbmVkIHtcbnRyYW5zZm9ybTogdHJhbnNsYXRlWCgwKTtcbm9wYWNpdHk6IDE7XG59XG59XG59Il19 */