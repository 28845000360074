.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
gap: 12px;
justify-content: space-between;
flex-wrap: wrap-reverse;
align-items: center;
padding: 0 0 30px 0;
width: 100%;
box-sizing: border-box;
color: var(--main-second-text-color);
}
.cls2.posStatic {
position: static !important;
padding: 20px 28px;
}
@media (max-width: 768px) {
.cls2 {
padding: 16px 15px !important
}

.cls2 > * {
flex: 1 0 100%;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9Gb290ZXItQ29udGVudC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGFBQWE7QUFDYixTQUFTO0FBQ1QsOEJBQThCO0FBQzlCLHVCQUF1QjtBQUN2QixtQkFBbUI7QUFDbkIsbUJBQW1CO0FBQ25CLFdBQVc7QUFDWCxzQkFBc0I7QUFDdEIsb0NBQW9DO0FBY3BDO0FBWkE7QUFDQSwyQkFBMkI7QUFDM0Isa0JBQWtCO0FBQ2xCO0FBRUE7QUFsQkE7QUFtQkE7QUFNQTs7QUFKQTtBQUNBLGNBQWM7QUFDZDtBQUNBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9Gb290ZXItQ29udGVudC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuZGlzcGxheTogZmxleDtcbmdhcDogMTJweDtcbmp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbmZsZXgtd3JhcDogd3JhcC1yZXZlcnNlO1xuYWxpZ24taXRlbXM6IGNlbnRlcjtcbnBhZGRpbmc6IDAgMCAzMHB4IDA7XG53aWR0aDogMTAwJTtcbmJveC1zaXppbmc6IGJvcmRlci1ib3g7XG5jb2xvcjogdmFyKC0tbWFpbi1zZWNvbmQtdGV4dC1jb2xvcik7XG5cbiYucG9zU3RhdGljIHtcbnBvc2l0aW9uOiBzdGF0aWMgIWltcG9ydGFudDtcbnBhZGRpbmc6IDIwcHggMjhweDtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDc2OHB4KSB7XG5wYWRkaW5nOiAxNnB4IDE1cHggIWltcG9ydGFudDtcblxuJiA+ICoge1xuZmxleDogMSAwIDEwMCU7XG59XG59XG59Il19 */