@value cls1 as a0 from "./NoticeDropDown-NoticeIndicator.module.css";
@value cls1 as a1 from "./NoticeDropDown-NoticeIndicator.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

cursor: pointer;
transition:
0.25s background,
0.25s color;
}
.cls2:hover {
background: #2281ff;
color: #fff;
}
.cls2:hover .a0 {
border-color: #2281ff;
}
@media (max-width: 600px) {
.cls2 {
width: 20px;
height: 20px;
background: none !important;
color: #2281ff !important
}

.cls2 .a1 {
border-color: #fff !important;
}

.cls2 svg {
width: 100%;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Ob3RpY2VEcm9wRG93bi1Ob3RpY2VJY29uLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsb0VBQW9FO0FBQ3BFLG9FQUFvRTs7QUFFcEUsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsZUFBZTtBQUNmOztXQUVXO0FBeUJYO0FBdkJBO0FBQ0EsbUJBQW1CO0FBQ25CLFdBQVc7QUFLWDtBQUhBO0FBQ0EscUJBQXFCO0FBQ3JCO0FBR0E7QUFqQkE7QUFrQkEsV0FBVztBQUNYLFlBQVk7QUFDWiwyQkFBMkI7QUFDM0I7QUFVQTs7QUFSQTtBQUNBLDZCQUE2QjtBQUM3Qjs7QUFFQTtBQUNBLFdBQVc7QUFDWDtBQUNBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Ob3RpY2VEcm9wRG93bi1Ob3RpY2VJY29uLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAdmFsdWUgY2xzMSBhcyBhMCBmcm9tIFwiLi9Ob3RpY2VEcm9wRG93bi1Ob3RpY2VJbmRpY2F0b3IubW9kdWxlLmNzc1wiO1xuQHZhbHVlIGNsczEgYXMgYTEgZnJvbSBcIi4vTm90aWNlRHJvcERvd24tTm90aWNlSW5kaWNhdG9yLm1vZHVsZS5jc3NcIjtcblxuLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuY3Vyc29yOiBwb2ludGVyO1xudHJhbnNpdGlvbjpcbjAuMjVzIGJhY2tncm91bmQsXG4wLjI1cyBjb2xvcjtcblxuJjpob3ZlciB7XG5iYWNrZ3JvdW5kOiAjMjI4MWZmO1xuY29sb3I6ICNmZmY7XG5cbi5hMCB7XG5ib3JkZXItY29sb3I6ICMyMjgxZmY7XG59XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xud2lkdGg6IDIwcHg7XG5oZWlnaHQ6IDIwcHg7XG5iYWNrZ3JvdW5kOiBub25lICFpbXBvcnRhbnQ7XG5jb2xvcjogIzIyODFmZiAhaW1wb3J0YW50O1xuXG4uYTEge1xuYm9yZGVyLWNvbG9yOiAjZmZmICFpbXBvcnRhbnQ7XG59XG5cbnN2ZyB7XG53aWR0aDogMTAwJTtcbn1cbn1cbn0iXX0= */