@value cls1 as a0 from "./Modal-ModalLoading.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: fixed;
display: flex;
justify-content: center;
align-items: center;
top: 0;
left: 0;
width: 100vw;
height: calc(var(--vh, 1vh) * 100);
background: rgba(0, 0, 0, 0.5);
z-index: 1000;

&.theme-dark {
.a0 {
background: rgba(0, 0, 0, 0.3) !important;
}
}

@media (max-width: 600px) {
align-items: flex-end;
height: 100svh;
box-sizing: border-box;
}
}