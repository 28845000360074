.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin: 0;

thead td {
padding-top: 0 !important;
}

td {
padding: 10px 15px !important;
font-size: 1em;

&:last-child {
white-space: nowrap;
width: 100px;
}
}

@media (max-width: 600px) {
width: calc(100% + 30px);
margin: 0 -15px;
}
}