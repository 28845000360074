.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
padding: 32px 0;
transition: none;

@media (max-width: 600px) {
padding: 0;
background: transparent !important;
margin: 0 -15px;
width: calc(100% + 30px);
box-shadow: none !important;
}
}