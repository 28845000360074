.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: inline-flex !important;
align-items: center !important;
color: white !important;
background: #49ca1c !important;
border-radius: 20px !important;
padding: 2px 4px 2px 3px !important;
font-size: 12px !important;
line-height: 1 !important;

&.gray {
background: #ccc !important;
}

svg {
min-width: 9px !important;
}
}