.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
top: 0;
right: 0;
height: 18px;
width: 2px;
background: #2281ff;
transition: 0.25s transform ease-in-out;
}
.cls2.hidden {
opacity: 0;
}
@media (max-width: 600px) {
.cls2 {
display: none
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL29yZ2FuaXNtcy9TaWRlYmFyTmF2LVNsaWRlci5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGtCQUFrQjtBQUNsQixNQUFNO0FBQ04sUUFBUTtBQUNSLFlBQVk7QUFDWixVQUFVO0FBQ1YsbUJBQW1CO0FBQ25CLHVDQUF1QztBQVN2QztBQVBBO0FBQ0EsVUFBVTtBQUNWO0FBRUE7QUFmQTtBQWdCQTtBQUVBO0FBREEiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvb3JnYW5pc21zL1NpZGViYXJOYXYtU2xpZGVyLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5wb3NpdGlvbjogYWJzb2x1dGU7XG50b3A6IDA7XG5yaWdodDogMDtcbmhlaWdodDogMThweDtcbndpZHRoOiAycHg7XG5iYWNrZ3JvdW5kOiAjMjI4MWZmO1xudHJhbnNpdGlvbjogMC4yNXMgdHJhbnNmb3JtIGVhc2UtaW4tb3V0O1xuXG4mLmhpZGRlbiB7XG5vcGFjaXR5OiAwO1xufVxuXG5AbWVkaWEgKG1heC13aWR0aDogNjAwcHgpIHtcbmRpc3BsYXk6IG5vbmU7XG59XG59Il19 */