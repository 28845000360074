.cls1 { /*!*/ }
.cls2 {
composes: cls1;

max-width: 700px;
font-size: 18px;

span {
color: #2281ff;
}

b {
font-weight: 600;
}

a {
text-decoration: none;
color: #2281ff;
transition: 0.25s color;

&:hover {
color: #7ccefd;
}
}

@media (max-width: 500px) {
font-size: 15px;
}
}