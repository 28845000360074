.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@media (max-width: 768px) {
margin-top: -20px !important;
}

@media (max-width: 500px) {
margin-top: 20px !important;
}
}