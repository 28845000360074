.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
top: 15px;
right: 18px;
color: #b8bdc4;
transition: 0.2s color;
cursor: pointer;
}
.cls2:hover {
color: #2281ff;
}
@media (max-width: 768px) {
.cls2 {
top: 42px;
right: 45px
}
}
@media (max-width: 600px) {
.cls2 {
top: 15px;
right: 20px
}

.cls2 svg {
width: 12px !important;
color: #9ba1aa;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Nb2RhbC1DbG9zZS5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGtCQUFrQjtBQUNsQixTQUFTO0FBQ1QsV0FBVztBQUNYLGNBQWM7QUFDZCxzQkFBc0I7QUFDdEIsZUFBZTtBQW9CZjtBQWxCQTtBQUNBLGNBQWM7QUFDZDtBQUVBO0FBZEE7QUFlQSxTQUFTO0FBQ1Q7QUFZQTtBQVhBO0FBRUE7QUFuQkE7QUFvQkEsU0FBUztBQUNUO0FBT0E7O0FBTEE7QUFDQSxzQkFBc0I7QUFDdEIsY0FBYztBQUNkO0FBQ0EiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbW9sZWN1bGVzL01vZGFsLUNsb3NlLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5wb3NpdGlvbjogYWJzb2x1dGU7XG50b3A6IDE1cHg7XG5yaWdodDogMThweDtcbmNvbG9yOiAjYjhiZGM0O1xudHJhbnNpdGlvbjogMC4ycyBjb2xvcjtcbmN1cnNvcjogcG9pbnRlcjtcblxuJjpob3ZlciB7XG5jb2xvcjogIzIyODFmZjtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDc2OHB4KSB7XG50b3A6IDQycHg7XG5yaWdodDogNDVweDtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG50b3A6IDE1cHg7XG5yaWdodDogMjBweDtcblxuc3ZnIHtcbndpZHRoOiAxMnB4ICFpbXBvcnRhbnQ7XG5jb2xvcjogIzliYTFhYTtcbn1cbn1cbn0iXX0= */