@value cls1 as a0 from "./PrivateTemplate-Container.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
flex-direction: column;
flex: 1;
max-height: 100vh;
overflow-y: auto;
overflow-x: hidden;

@media (max-width: 600px) {
height: calc(100vh - 55px - env(safe-area-inset-bottom)) !important;
overflow: auto;

&.hideMenu .a0 {
padding-bottom: 0 !important;
}
}

&::after {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
background: rgba(0, 0, 0, 0.5);
content: '';
opacity: 0;
transition:
0.25s opacity,
0.25s visibility;
visibility: hidden;
z-index: 9;
}

&.openedMenu::after {
opacity: 1;
visibility: visible;
}
}