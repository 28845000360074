.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin: 0;
}
.cls2 thead td {
padding-top: 0 !important;
}
.cls2 td {
padding: 10px 15px !important;
font-size: 1em;
}
.cls2 td:last-child {
white-space: nowrap;
width: 100px;
}
@media (max-width: 600px) {
.cls2 {
width: calc(100% + 30px);
margin: 0 -15px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL29yZ2FuaXNtcy9DYXJkRmVlc1RhYmxlLVRhYmxlU3R5bGVkLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsU0FBUztBQW9CVDtBQWxCQTtBQUNBLHlCQUF5QjtBQUN6QjtBQUVBO0FBQ0EsNkJBQTZCO0FBQzdCLGNBQWM7QUFNZDtBQUpBO0FBQ0EsbUJBQW1CO0FBQ25CLFlBQVk7QUFDWjtBQUdBO0FBbkJBO0FBb0JBLHdCQUF3QjtBQUN4QjtBQUVBO0FBREEiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvb3JnYW5pc21zL0NhcmRGZWVzVGFibGUtVGFibGVTdHlsZWQubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbm1hcmdpbjogMDtcblxudGhlYWQgdGQge1xucGFkZGluZy10b3A6IDAgIWltcG9ydGFudDtcbn1cblxudGQge1xucGFkZGluZzogMTBweCAxNXB4ICFpbXBvcnRhbnQ7XG5mb250LXNpemU6IDFlbTtcblxuJjpsYXN0LWNoaWxkIHtcbndoaXRlLXNwYWNlOiBub3dyYXA7XG53aWR0aDogMTAwcHg7XG59XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xud2lkdGg6IGNhbGMoMTAwJSArIDMwcHgpO1xubWFyZ2luOiAwIC0xNXB4O1xufVxufSJdfQ== */