.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
font-size: 14px;
color: var(--main-text-color);
white-space: nowrap;

&.hide {
display: none;
}
}