.cls1 { /*!*/ }
.cls2 {
composes: cls1;

white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
font-size: 14px;
line-height: 1.5;
color: var(--main-text-color);

b {
font-weight: 600;
}

@media (max-width: 600px) {
line-height: 1.15;
max-width: 170px;

&.isExchange {
margin-top: 4px;
}

b {
font-weight: 400;
}
}
}