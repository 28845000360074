.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
min-width: 100vw;
height: 100%;
gap: 32px;
color: #1d2534;
text-align: center;
box-sizing: border-box;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3BhZ2VzL01vYmlsZUhvbWVQYWdlLVNsaWRlLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsYUFBYTtBQUNiLHNCQUFzQjtBQUN0Qix1QkFBdUI7QUFDdkIsbUJBQW1CO0FBQ25CLGdCQUFnQjtBQUNoQixZQUFZO0FBQ1osU0FBUztBQUNULGNBQWM7QUFDZCxrQkFBa0I7QUFDbEIsc0JBQXNCO0FBQ3RCIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3BhZ2VzL01vYmlsZUhvbWVQYWdlLVNsaWRlLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5kaXNwbGF5OiBmbGV4O1xuZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbmp1c3RpZnktY29udGVudDogY2VudGVyO1xuYWxpZ24taXRlbXM6IGNlbnRlcjtcbm1pbi13aWR0aDogMTAwdnc7XG5oZWlnaHQ6IDEwMCU7XG5nYXA6IDMycHg7XG5jb2xvcjogIzFkMjUzNDtcbnRleHQtYWxpZ246IGNlbnRlcjtcbmJveC1zaXppbmc6IGJvcmRlci1ib3g7XG59Il19 */