@value cls1 as a0 from "./PrivateTemplate-Container.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
flex-direction: column;
flex: 1;
max-height: 100vh;
overflow-y: auto;
overflow-x: hidden;
}
@media (max-width: 600px) {
.cls2 {
height: calc(100vh - 55px - env(safe-area-inset-bottom)) !important;
overflow: auto
}

.cls2.hideMenu .a0 {
padding-bottom: 0 !important;
}
}
.cls2::after {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
background: rgba(0, 0, 0, 0.5);
content: '';
opacity: 0;
transition:
0.25s opacity,
0.25s visibility;
visibility: hidden;
z-index: 9;
}
.cls2.openedMenu::after {
opacity: 1;
visibility: visible;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9Qcml2YXRlVGVtcGxhdGUtQ29udGVudC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLCtEQUErRDs7QUFFL0QsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsa0JBQWtCO0FBQ2xCLGFBQWE7QUFDYixzQkFBc0I7QUFDdEIsT0FBTztBQUNQLGlCQUFpQjtBQUNqQixnQkFBZ0I7QUFDaEIsa0JBQWtCO0FBK0JsQjtBQTdCQTtBQVhBO0FBWUEsbUVBQW1FO0FBQ25FO0FBMkJBOztBQXpCQTtBQUNBLDRCQUE0QjtBQUM1QjtBQUNBO0FBRUE7QUFDQSxrQkFBa0I7QUFDbEIsTUFBTTtBQUNOLFFBQVE7QUFDUixTQUFTO0FBQ1QsT0FBTztBQUNQLDhCQUE4QjtBQUM5QixXQUFXO0FBQ1gsVUFBVTtBQUNWOztnQkFFZ0I7QUFDaEIsa0JBQWtCO0FBQ2xCLFVBQVU7QUFDVjtBQUVBO0FBQ0EsVUFBVTtBQUNWLG1CQUFtQjtBQUNuQiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvUHJpdmF0ZVRlbXBsYXRlLUNvbnRlbnQubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIkB2YWx1ZSBjbHMxIGFzIGEwIGZyb20gXCIuL1ByaXZhdGVUZW1wbGF0ZS1Db250YWluZXIubW9kdWxlLmNzc1wiO1xuXG4uY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5wb3NpdGlvbjogcmVsYXRpdmU7XG5kaXNwbGF5OiBmbGV4O1xuZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbmZsZXg6IDE7XG5tYXgtaGVpZ2h0OiAxMDB2aDtcbm92ZXJmbG93LXk6IGF1dG87XG5vdmVyZmxvdy14OiBoaWRkZW47XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xuaGVpZ2h0OiBjYWxjKDEwMHZoIC0gNTVweCAtIGVudihzYWZlLWFyZWEtaW5zZXQtYm90dG9tKSkgIWltcG9ydGFudDtcbm92ZXJmbG93OiBhdXRvO1xuXG4mLmhpZGVNZW51IC5hMCB7XG5wYWRkaW5nLWJvdHRvbTogMCAhaW1wb3J0YW50O1xufVxufVxuXG4mOjphZnRlciB7XG5wb3NpdGlvbjogYWJzb2x1dGU7XG50b3A6IDA7XG5yaWdodDogMDtcbmJvdHRvbTogMDtcbmxlZnQ6IDA7XG5iYWNrZ3JvdW5kOiByZ2JhKDAsIDAsIDAsIDAuNSk7XG5jb250ZW50OiAnJztcbm9wYWNpdHk6IDA7XG50cmFuc2l0aW9uOlxuMC4yNXMgb3BhY2l0eSxcbjAuMjVzIHZpc2liaWxpdHk7XG52aXNpYmlsaXR5OiBoaWRkZW47XG56LWluZGV4OiA5O1xufVxuXG4mLm9wZW5lZE1lbnU6OmFmdGVyIHtcbm9wYWNpdHk6IDE7XG52aXNpYmlsaXR5OiB2aXNpYmxlO1xufVxufSJdfQ== */