.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
gap: 10px;
height: 100svh;
overflow: hidden;
flex-direction: column;
justify-content: space-between;
background: #1d2534;
padding: calc(env(safe-area-inset-top) + 28px) 28px calc(env(safe-area-inset-bottom) + 28px);
box-sizing: border-box;
line-height: 1.2;
color: #fff;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3BhZ2VzL01vYmlsZUhvbWVQYWdlLVdyYXAubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxrQkFBa0I7QUFDbEIsYUFBYTtBQUNiLFNBQVM7QUFDVCxjQUFjO0FBQ2QsZ0JBQWdCO0FBQ2hCLHNCQUFzQjtBQUN0Qiw4QkFBOEI7QUFDOUIsbUJBQW1CO0FBQ25CLDRGQUE0RjtBQUM1RixzQkFBc0I7QUFDdEIsZ0JBQWdCO0FBQ2hCLFdBQVc7QUFDWCIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9wYWdlcy9Nb2JpbGVIb21lUGFnZS1XcmFwLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5wb3NpdGlvbjogcmVsYXRpdmU7XG5kaXNwbGF5OiBmbGV4O1xuZ2FwOiAxMHB4O1xuaGVpZ2h0OiAxMDBzdmg7XG5vdmVyZmxvdzogaGlkZGVuO1xuZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbmp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbmJhY2tncm91bmQ6ICMxZDI1MzQ7XG5wYWRkaW5nOiBjYWxjKGVudihzYWZlLWFyZWEtaW5zZXQtdG9wKSArIDI4cHgpIDI4cHggY2FsYyhlbnYoc2FmZS1hcmVhLWluc2V0LWJvdHRvbSkgKyAyOHB4KTtcbmJveC1zaXppbmc6IGJvcmRlci1ib3g7XG5saW5lLWhlaWdodDogMS4yO1xuY29sb3I6ICNmZmY7XG59Il19 */