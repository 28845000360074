@value cls1 as a0 from "./UserDropDown-ChevronDownStyledSvg.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
margin-bottom: 3px;
line-height: 1;
font-size: 15px;
font-weight: 600;
transition: 0.25s color;
cursor: pointer;
}
.cls2.center {
justify-content: center;
}
.cls2 .a0 {
margin-left: 10px;
color: #2281ff;
transform-origin: center;
transform: scaleY(1);
transition: 0.25s transform;
}
@media (max-width: 500px) {
.cls2 {
justify-content: flex-start !important;
font-size: 20px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Vc2VyRHJvcERvd24tVXNlck5hbWUubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSx1RUFBdUU7O0FBRXZFLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGFBQWE7QUFDYixtQkFBbUI7QUFDbkIsa0JBQWtCO0FBQ2xCLGNBQWM7QUFDZCxlQUFlO0FBQ2YsZ0JBQWdCO0FBQ2hCLHVCQUF1QjtBQUN2QixlQUFlO0FBa0JmO0FBaEJBO0FBQ0EsdUJBQXVCO0FBQ3ZCO0FBRUE7QUFDQSxpQkFBaUI7QUFDakIsY0FBYztBQUNkLHdCQUF3QjtBQUN4QixvQkFBb0I7QUFDcEIsMkJBQTJCO0FBQzNCO0FBRUE7QUF4QkE7QUF5QkEsc0NBQXNDO0FBQ3RDO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvVXNlckRyb3BEb3duLVVzZXJOYW1lLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAdmFsdWUgY2xzMSBhcyBhMCBmcm9tIFwiLi9Vc2VyRHJvcERvd24tQ2hldnJvbkRvd25TdHlsZWRTdmcubW9kdWxlLmNzc1wiO1xuXG4uY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5kaXNwbGF5OiBmbGV4O1xuYWxpZ24taXRlbXM6IGNlbnRlcjtcbm1hcmdpbi1ib3R0b206IDNweDtcbmxpbmUtaGVpZ2h0OiAxO1xuZm9udC1zaXplOiAxNXB4O1xuZm9udC13ZWlnaHQ6IDYwMDtcbnRyYW5zaXRpb246IDAuMjVzIGNvbG9yO1xuY3Vyc29yOiBwb2ludGVyO1xuXG4mLmNlbnRlciB7XG5qdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbn1cblxuLmEwIHtcbm1hcmdpbi1sZWZ0OiAxMHB4O1xuY29sb3I6ICMyMjgxZmY7XG50cmFuc2Zvcm0tb3JpZ2luOiBjZW50ZXI7XG50cmFuc2Zvcm06IHNjYWxlWSgxKTtcbnRyYW5zaXRpb246IDAuMjVzIHRyYW5zZm9ybTtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDUwMHB4KSB7XG5qdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtc3RhcnQgIWltcG9ydGFudDtcbmZvbnQtc2l6ZTogMjBweDtcbn1cbn0iXX0= */