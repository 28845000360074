.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
gap: 12px;
justify-content: space-between;
flex-wrap: wrap-reverse;
align-items: center;
padding: 0 0 30px 0;
width: 100%;
box-sizing: border-box;
color: var(--main-second-text-color);

&.posStatic {
position: static !important;
padding: 20px 28px;
}

@media (max-width: 768px) {
padding: 16px 15px !important;

& > * {
flex: 1 0 100%;
}
}
}