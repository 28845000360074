@value cls1 as a0 from "./Modal-ModalLoading.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: fixed;
display: flex;
justify-content: center;
align-items: center;
top: 0;
left: 0;
width: 100vw;
height: calc(var(--vh, 1vh) * 100);
background: rgba(0, 0, 0, 0.5);
z-index: 1000;
}
.cls2.theme-dark .a0 {
background: rgba(0, 0, 0, 0.3) !important;
}
@media (max-width: 600px) {
.cls2 {
align-items: flex-end;
height: 100svh;
box-sizing: border-box
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Nb2RhbC1Nb2RhbE92ZXJsYXkubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSx3REFBd0Q7O0FBRXhELFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGVBQWU7QUFDZixhQUFhO0FBQ2IsdUJBQXVCO0FBQ3ZCLG1CQUFtQjtBQUNuQixNQUFNO0FBQ04sT0FBTztBQUNQLFlBQVk7QUFDWixrQ0FBa0M7QUFDbEMsOEJBQThCO0FBQzlCLGFBQWE7QUFhYjtBQVZBO0FBQ0EseUNBQXlDO0FBQ3pDO0FBR0E7QUFwQkE7QUFxQkEscUJBQXFCO0FBQ3JCLGNBQWM7QUFDZDtBQUVBO0FBREEiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbW9sZWN1bGVzL01vZGFsLU1vZGFsT3ZlcmxheS5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQHZhbHVlIGNsczEgYXMgYTAgZnJvbSBcIi4vTW9kYWwtTW9kYWxMb2FkaW5nLm1vZHVsZS5jc3NcIjtcblxuLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxucG9zaXRpb246IGZpeGVkO1xuZGlzcGxheTogZmxleDtcbmp1c3RpZnktY29udGVudDogY2VudGVyO1xuYWxpZ24taXRlbXM6IGNlbnRlcjtcbnRvcDogMDtcbmxlZnQ6IDA7XG53aWR0aDogMTAwdnc7XG5oZWlnaHQ6IGNhbGModmFyKC0tdmgsIDF2aCkgKiAxMDApO1xuYmFja2dyb3VuZDogcmdiYSgwLCAwLCAwLCAwLjUpO1xuei1pbmRleDogMTAwMDtcblxuJi50aGVtZS1kYXJrIHtcbi5hMCB7XG5iYWNrZ3JvdW5kOiByZ2JhKDAsIDAsIDAsIDAuMykgIWltcG9ydGFudDtcbn1cbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG5hbGlnbi1pdGVtczogZmxleC1lbmQ7XG5oZWlnaHQ6IDEwMHN2aDtcbmJveC1zaXppbmc6IGJvcmRlci1ib3g7XG59XG59Il19 */