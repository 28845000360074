.cls1 { /*!*/ }
.cls2 {
composes: cls1;

box-sizing: border-box;
padding: 0 12%;

@media (max-width: 600px) {
padding: 24px 28px 0;
}

&.isMobile {
flex-direction: column;
display: flex;
justify-content: space-between;
flex: 1;
padding: 0;
}
}