@value cls1 as a0 from "./SidebarNav-NavItem.module.css";
@value cls1 as a1 from "./SidebarNav-NavItem.module.css";
@value cls1 as a2 from "./SidebarNav-NavItem.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
flex-direction: column;

@media (max-width: 768px) {
align-items: center;
}

&.theme-light {
.a0 {
&:hover {
background: #f2f9fd;
}
}
}

&.theme-dark {
.a1 {
&:hover {
background: #2f3e5b;
}
svg {
color: #2281ff;
}
}
}

@media (max-width: 600px) {
position: fixed;
bottom: 0;
z-index: 99;
flex-direction: row;
border-top: 1px solid var(--lighter-bg-color);
background: var(--bg-color-1);
padding-bottom: calc(env(safe-area-inset-bottom) - 7px);

.a2:hover {
background: transparent !important;
}
}
}