.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
flex-wrap: wrap;
justify-content: space-between;
padding-top: 10px;
}
@media (max-width: 1200px) {
.cls2 {
padding-top: 0
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL29yZ2FuaXNtcy9DYXJkSGlzdG9yeU9wZXJhdGlvbnNGaWx0ZXJzLUZpbHRlcnNGb290ZXIubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxhQUFhO0FBQ2IsbUJBQW1CO0FBQ25CLGVBQWU7QUFDZiw4QkFBOEI7QUFDOUIsaUJBQWlCO0FBS2pCO0FBSEE7QUFUQTtBQVVBO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9vcmdhbmlzbXMvQ2FyZEhpc3RvcnlPcGVyYXRpb25zRmlsdGVycy1GaWx0ZXJzRm9vdGVyLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5kaXNwbGF5OiBmbGV4O1xuYWxpZ24taXRlbXM6IGNlbnRlcjtcbmZsZXgtd3JhcDogd3JhcDtcbmp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbnBhZGRpbmctdG9wOiAxMHB4O1xuXG5AbWVkaWEgKG1heC13aWR0aDogMTIwMHB4KSB7XG5wYWRkaW5nLXRvcDogMDtcbn1cbn0iXX0= */