.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
font-size: 8px;
font-weight: 600;
color: var(--blue-to-white);
border: 1px solid var(--bg-color-6);
border-radius: 8px;
padding: 3px 5px;
}
.cls2.isActive {
cursor: pointer;
}
.cls2.isActive svg {
color: #49ca1c;
}
.cls2 svg {
margin-right: 3px;
color: var(--blue-to-white);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Vc2VyRHJvcERvd24tSUJBTi5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGFBQWE7QUFDYixtQkFBbUI7QUFDbkIsY0FBYztBQUNkLGdCQUFnQjtBQUNoQiwyQkFBMkI7QUFDM0IsbUNBQW1DO0FBQ25DLGtCQUFrQjtBQUNsQixnQkFBZ0I7QUFjaEI7QUFaQTtBQUNBLGVBQWU7QUFLZjtBQUhBO0FBQ0EsY0FBYztBQUNkO0FBR0E7QUFDQSxpQkFBaUI7QUFDakIsMkJBQTJCO0FBQzNCIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Vc2VyRHJvcERvd24tSUJBTi5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuZGlzcGxheTogZmxleDtcbmFsaWduLWl0ZW1zOiBjZW50ZXI7XG5mb250LXNpemU6IDhweDtcbmZvbnQtd2VpZ2h0OiA2MDA7XG5jb2xvcjogdmFyKC0tYmx1ZS10by13aGl0ZSk7XG5ib3JkZXI6IDFweCBzb2xpZCB2YXIoLS1iZy1jb2xvci02KTtcbmJvcmRlci1yYWRpdXM6IDhweDtcbnBhZGRpbmc6IDNweCA1cHg7XG5cbiYuaXNBY3RpdmUge1xuY3Vyc29yOiBwb2ludGVyO1xuXG5zdmcge1xuY29sb3I6ICM0OWNhMWM7XG59XG59XG5cbnN2ZyB7XG5tYXJnaW4tcmlnaHQ6IDNweDtcbmNvbG9yOiB2YXIoLS1ibHVlLXRvLXdoaXRlKTtcbn1cbn0iXX0= */