.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
left: 0;
right: 0;
text-align: center;
}
.cls2.left {
text-align: left;
}
.cls2.left.leftContentExist {
top: 50px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vYmlsZS9Nb2JpbGVQYWdlSGVhZGVyLVRpdGxlLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsa0JBQWtCO0FBQ2xCLE9BQU87QUFDUCxRQUFRO0FBQ1Isa0JBQWtCO0FBU2xCO0FBUEE7QUFDQSxnQkFBZ0I7QUFLaEI7QUFIQTtBQUNBLFNBQVM7QUFDVCIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2JpbGUvTW9iaWxlUGFnZUhlYWRlci1UaXRsZS5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxucG9zaXRpb246IGFic29sdXRlO1xubGVmdDogMDtcbnJpZ2h0OiAwO1xudGV4dC1hbGlnbjogY2VudGVyO1xuXG4mLmxlZnQge1xudGV4dC1hbGlnbjogbGVmdDtcblxuJi5sZWZ0Q29udGVudEV4aXN0IHtcbnRvcDogNTBweDtcbn1cbn1cbn0iXX0= */