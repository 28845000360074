.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 13.5px;
}
@media (max-width: 600px) {
.cls2 {
display: none
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9Gb290ZXItQ29weXJpZ2h0Lm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsaUJBQWlCO0FBS2pCO0FBSEE7QUFMQTtBQU1BO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvRm9vdGVyLUNvcHlyaWdodC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuZm9udC1zaXplOiAxMy41cHg7XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xuZGlzcGxheTogbm9uZTtcbn1cbn0iXX0= */