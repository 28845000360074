.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
box-sizing: border-box;
width: 100%;
height: 45px;
padding-left: 48px;
margin-bottom: 15px;
text-decoration: none;
line-height: 1;
color: var(--main-third-text-color);
overflow: hidden;
font-size: 14px;
transition:
0.25s color,
0.25s background;

&.active {
font-weight: 600;
color: var(--main-text-color) !important;

svg {
color: var(--blue-to-white) !important;
}
}

&:hover {
svg {
color: #2281ff;
}
}

@media (max-width: 768px) {
font-size: 16px;
}

@media (max-width: 600px) {
flex-direction: column;
justify-content: center;
padding: 10px 0;
height: auto;
margin: 0;
font-size: 11px;

&.active {
color: var(--blue-to-white) !important;
font-weight: 400 !important;

svg {
color: var(--blue-to-white) !important;
}
}
}
}