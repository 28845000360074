.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: none;
}
@media (max-width: 600px) {
.cls2 {
display: block;
min-width: 26px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Ob3RpY2VEcm9wRG93bi1QcmFjdGlrYUxvZ28ubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxhQUFhO0FBTWI7QUFKQTtBQUxBO0FBTUEsY0FBYztBQUNkO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvTm90aWNlRHJvcERvd24tUHJhY3Rpa2FMb2dvLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5kaXNwbGF5OiBub25lO1xuXG5AbWVkaWEgKG1heC13aWR0aDogNjAwcHgpIHtcbmRpc3BsYXk6IGJsb2NrO1xubWluLXdpZHRoOiAyNnB4O1xufVxufSJdfQ== */