.cls1 { /*!*/ }
.cls2 {
composes: cls1;
}
@media (max-width: 500px) {
.cls2 {
width: 22px;
height: 22px;
background: none !important;
color: #2281ff !important
}

.cls2 svg {
width: 100%;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Vc2VyRHJvcERvd24tQXZhdGFySWNvbkJsb2NrLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7QUFZZDtBQVZBO0FBSEE7QUFJQSxXQUFXO0FBQ1gsWUFBWTtBQUNaLDJCQUEyQjtBQUMzQjtBQU1BOztBQUpBO0FBQ0EsV0FBVztBQUNYO0FBQ0EiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbW9sZWN1bGVzL1VzZXJEcm9wRG93bi1BdmF0YXJJY29uQmxvY2subW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA1MDBweCkge1xud2lkdGg6IDIycHg7XG5oZWlnaHQ6IDIycHg7XG5iYWNrZ3JvdW5kOiBub25lICFpbXBvcnRhbnQ7XG5jb2xvcjogIzIyODFmZiAhaW1wb3J0YW50O1xuXG5zdmcge1xud2lkdGg6IDEwMCU7XG59XG59XG59Il19 */