.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 40px;
height: 40px;
margin-right: 16px;
}
.cls2:not(.inProcess) {
background: #fff1e1;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9WZXJpZnlBbGVydC1Sb3VuZEljb25TdHlsZWQubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxXQUFXO0FBQ1gsWUFBWTtBQUNaLGtCQUFrQjtBQUtsQjtBQUhBO0FBQ0EsbUJBQW1CO0FBQ25CIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9WZXJpZnlBbGVydC1Sb3VuZEljb25TdHlsZWQubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbndpZHRoOiA0MHB4O1xuaGVpZ2h0OiA0MHB4O1xubWFyZ2luLXJpZ2h0OiAxNnB4O1xuXG4mOm5vdCguaW5Qcm9jZXNzKSB7XG5iYWNrZ3JvdW5kOiAjZmZmMWUxO1xufVxufSJdfQ== */