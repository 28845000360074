.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
gap: 10px;
height: 100vh;
overflow: hidden;
flex-direction: column;
align-items: center;
justify-content: space-between;
background: #1d2534;
padding: 28px;
box-sizing: border-box;
line-height: 1.2;
color: #fff;
}