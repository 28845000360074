.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
align-items: center;
width: 240px;
min-width: 240px;
max-height: 100vh;
overflow: auto;
box-sizing: border-box;
padding: 16px 0 32px;
background: var(--bg-color-1);
box-shadow: 10px 0 -2px 5px rgba(0, 0, 0, 0.1);

@media (max-width: 768px) {
position: fixed;
top: 0;
bottom: 0;
left: 0;
z-index: 10;
padding: 80px 0 40px;
width: 280px;
min-width: 280px;
transform: translateX(-100%);
opacity: 0;
transition:
0.25s transform,
0.25s opacity;

&.opened {
transform: translateX(0);
opacity: 1;
}
}
}