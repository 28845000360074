.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: space-between;
align-items: center;
padding: 16px 48px;
box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.1);
background: #fff;
background: var(--bg-color-1);
}
.cls2 svg {
color: var(--main-text-color);
}
@media (max-width: 600px) {
.cls2 {
padding: 8px 28px 16px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9BdXRoVGVtcGxhdGUtSGVhZGVyLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsYUFBYTtBQUNiLDhCQUE4QjtBQUM5QixtQkFBbUI7QUFDbkIsa0JBQWtCO0FBQ2xCLDhDQUE4QztBQUM5QyxnQkFBZ0I7QUFDaEIsNkJBQTZCO0FBUzdCO0FBUEE7QUFDQSw2QkFBNkI7QUFDN0I7QUFFQTtBQWZBO0FBZ0JBO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvQXV0aFRlbXBsYXRlLUhlYWRlci5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuZGlzcGxheTogZmxleDtcbmp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbmFsaWduLWl0ZW1zOiBjZW50ZXI7XG5wYWRkaW5nOiAxNnB4IDQ4cHg7XG5ib3gtc2hhZG93OiAxMHB4IDAgNXB4IC0ycHggcmdiYSgwLCAwLCAwLCAwLjEpO1xuYmFja2dyb3VuZDogI2ZmZjtcbmJhY2tncm91bmQ6IHZhcigtLWJnLWNvbG9yLTEpO1xuXG5zdmcge1xuY29sb3I6IHZhcigtLW1haW4tdGV4dC1jb2xvcik7XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xucGFkZGluZzogOHB4IDI4cHggMTZweDtcbn1cbn0iXX0= */