@value cls1 as a0 from "./NoticeDropDown-NoticeIndicator.module.css";
@value cls1 as a1 from "./NoticeDropDown-NoticeIndicator.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

cursor: pointer;
transition:
0.25s background,
0.25s color;

&:hover {
background: #2281ff;
color: #fff;

.a0 {
border-color: #2281ff;
}
}

@media (max-width: 600px) {
width: 20px;
height: 20px;
background: none !important;
color: #2281ff !important;

.a1 {
border-color: #fff !important;
}

svg {
width: 100%;
}
}
}