@value cls1 as a0 from "./SwitchLang-Lang.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
width: 100%;
border-radius: 20px;
padding: 7px 13px 7px 10px;
background: #f2f9fd;
background: var(--bg-color-2);
cursor: pointer;
font-size: 13px;
}
.cls2 svg {
color: #2281ff;
transform-origin: center;
transform: scaleY(1);
transition: 0.25s transform;
}
.cls2.visible > svg {
transform: scaleY(-1);
}
.cls2 .a0 {
flex: 1;
margin-left: 0;
}
.cls2 .a0 svg {
opacity: 1;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Td2l0Y2hMYW5nLUFjdGl2ZUxhbmcubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxxREFBcUQ7O0FBRXJELFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGFBQWE7QUFDYixtQkFBbUI7QUFDbkIsV0FBVztBQUNYLG1CQUFtQjtBQUNuQiwwQkFBMEI7QUFDMUIsbUJBQW1CO0FBQ25CLDZCQUE2QjtBQUM3QixlQUFlO0FBQ2YsZUFBZTtBQXVCZjtBQXJCQTtBQUNBLGNBQWM7QUFDZCx3QkFBd0I7QUFDeEIsb0JBQW9CO0FBQ3BCLDJCQUEyQjtBQUMzQjtBQUdBO0FBQ0EscUJBQXFCO0FBQ3JCO0FBR0E7QUFDQSxPQUFPO0FBQ1AsY0FBYztBQUtkO0FBSEE7QUFDQSxVQUFVO0FBQ1YiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbW9sZWN1bGVzL1N3aXRjaExhbmctQWN0aXZlTGFuZy5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQHZhbHVlIGNsczEgYXMgYTAgZnJvbSBcIi4vU3dpdGNoTGFuZy1MYW5nLm1vZHVsZS5jc3NcIjtcblxuLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuZGlzcGxheTogZmxleDtcbmFsaWduLWl0ZW1zOiBjZW50ZXI7XG53aWR0aDogMTAwJTtcbmJvcmRlci1yYWRpdXM6IDIwcHg7XG5wYWRkaW5nOiA3cHggMTNweCA3cHggMTBweDtcbmJhY2tncm91bmQ6ICNmMmY5ZmQ7XG5iYWNrZ3JvdW5kOiB2YXIoLS1iZy1jb2xvci0yKTtcbmN1cnNvcjogcG9pbnRlcjtcbmZvbnQtc2l6ZTogMTNweDtcblxuc3ZnIHtcbmNvbG9yOiAjMjI4MWZmO1xudHJhbnNmb3JtLW9yaWdpbjogY2VudGVyO1xudHJhbnNmb3JtOiBzY2FsZVkoMSk7XG50cmFuc2l0aW9uOiAwLjI1cyB0cmFuc2Zvcm07XG59XG5cbiYudmlzaWJsZSB7XG4mID4gc3ZnIHtcbnRyYW5zZm9ybTogc2NhbGVZKC0xKTtcbn1cbn1cblxuLmEwIHtcbmZsZXg6IDE7XG5tYXJnaW4tbGVmdDogMDtcblxuc3ZnIHtcbm9wYWNpdHk6IDE7XG59XG59XG59Il19 */