.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: space-between;
align-items: center;
background: var(--bg-color-1);
padding: 16px 20px;
margin-bottom: 5px;
font-size: 16px;
border-radius: 6px;
}
.cls2 > div {
flex: 1;
border-bottom: 1px solid var(--bg-color-6);
padding-bottom: 16px;
}
.cls2 svg {
color: #2281ff;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3BhZ2VzL01vYmlsZVByb2ZpbGVQYWdlLU1vYmlsZVRhYi5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGFBQWE7QUFDYiw4QkFBOEI7QUFDOUIsbUJBQW1CO0FBQ25CLDZCQUE2QjtBQUM3QixrQkFBa0I7QUFDbEIsa0JBQWtCO0FBQ2xCLGVBQWU7QUFDZixrQkFBa0I7QUFXbEI7QUFUQTtBQUNBLE9BQU87QUFDUCwwQ0FBMEM7QUFDMUMsb0JBQW9CO0FBQ3BCO0FBRUE7QUFDQSxjQUFjO0FBQ2QiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvcGFnZXMvTW9iaWxlUHJvZmlsZVBhZ2UtTW9iaWxlVGFiLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5kaXNwbGF5OiBmbGV4O1xuanVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuYWxpZ24taXRlbXM6IGNlbnRlcjtcbmJhY2tncm91bmQ6IHZhcigtLWJnLWNvbG9yLTEpO1xucGFkZGluZzogMTZweCAyMHB4O1xubWFyZ2luLWJvdHRvbTogNXB4O1xuZm9udC1zaXplOiAxNnB4O1xuYm9yZGVyLXJhZGl1czogNnB4O1xuXG4mID4gZGl2IHtcbmZsZXg6IDE7XG5ib3JkZXItYm90dG9tOiAxcHggc29saWQgdmFyKC0tYmctY29sb3ItNik7XG5wYWRkaW5nLWJvdHRvbTogMTZweDtcbn1cblxuc3ZnIHtcbmNvbG9yOiAjMjI4MWZmO1xufVxufSJdfQ== */