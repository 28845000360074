.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
width: 27px;
height: 27px;
justify-content: center;
align-items: center;
border-radius: 100%;
border: 1px solid #2281ff;
transform-origin: center;
transition: 0.25s transform;

svg {
margin: 2px 0 0 0 !important;
}
}