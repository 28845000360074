.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@media (max-width: 500px) {
width: 22px;
height: 22px;
background: none !important;
color: #2281ff !important;

svg {
width: 100%;
}
}
}