.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 66px;
height: 66px;
margin: 0 auto 20px;
cursor: pointer;

@media (max-width: 500px) {
display: none;
}
}