.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
background: var(--bg-color-2);
border: 1px solid var(--bg-color-3);
font-weight: 600;
}
.cls2.from {
top: 0;
left: 0;
color: #2281ff;
}
.cls2.to {
bottom: 0;
right: 0;
color: #49ca1c;
}
.cls2.smallSize {
min-width: 22px !important;
width: 22px !important;
height: 22px !important;
font-size: 13px;
}
@media (max-width: 600px) {
.cls2 {
background: #2281ff;
color: #fff !important
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9IaXN0b3J5T3BlcmF0aW9uc1RlbXBsYXRlLUV4Y2hhbmdlSWNvbi5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGtCQUFrQjtBQUNsQiw2QkFBNkI7QUFDN0IsbUNBQW1DO0FBQ25DLGdCQUFnQjtBQXlCaEI7QUF2QkE7QUFDQSxNQUFNO0FBQ04sT0FBTztBQUNQLGNBQWM7QUFDZDtBQUVBO0FBQ0EsU0FBUztBQUNULFFBQVE7QUFDUixjQUFjO0FBQ2Q7QUFFQTtBQUNBLDBCQUEwQjtBQUMxQixzQkFBc0I7QUFDdEIsdUJBQXVCO0FBQ3ZCLGVBQWU7QUFDZjtBQUVBO0FBM0JBO0FBNEJBLG1CQUFtQjtBQUNuQjtBQUVBO0FBREEiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvdGVtcGxhdGVzL0hpc3RvcnlPcGVyYXRpb25zVGVtcGxhdGUtRXhjaGFuZ2VJY29uLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5wb3NpdGlvbjogYWJzb2x1dGU7XG5iYWNrZ3JvdW5kOiB2YXIoLS1iZy1jb2xvci0yKTtcbmJvcmRlcjogMXB4IHNvbGlkIHZhcigtLWJnLWNvbG9yLTMpO1xuZm9udC13ZWlnaHQ6IDYwMDtcblxuJi5mcm9tIHtcbnRvcDogMDtcbmxlZnQ6IDA7XG5jb2xvcjogIzIyODFmZjtcbn1cblxuJi50byB7XG5ib3R0b206IDA7XG5yaWdodDogMDtcbmNvbG9yOiAjNDljYTFjO1xufVxuXG4mLnNtYWxsU2l6ZSB7XG5taW4td2lkdGg6IDIycHggIWltcG9ydGFudDtcbndpZHRoOiAyMnB4ICFpbXBvcnRhbnQ7XG5oZWlnaHQ6IDIycHggIWltcG9ydGFudDtcbmZvbnQtc2l6ZTogMTNweDtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG5iYWNrZ3JvdW5kOiAjMjI4MWZmO1xuY29sb3I6ICNmZmYgIWltcG9ydGFudDtcbn1cbn0iXX0= */