.cls1 { /*!*/ }
.cls2 {
composes: cls1;

box-sizing: border-box;
flex: 1;
padding: 38px 46px;
background: var(--bg-color-2);
}
.cls2.isHomePage {
padding: 0;
}
.cls2.openedMenu {
overflow: hidden;
}
@media (max-width: 600px) {
.cls2 {
padding: calc(env(safe-area-inset-top) + 10px) 15px 48px
}

.cls2.mobileGradientBg {
background: linear-gradient(150deg, #0367df, #1ba9f8 50%);
}

.cls2.mobileGradientDarkBg {
background: linear-gradient(150deg, #141c2c, #3c4a65 50%);
}

.cls2.isHomePage {
padding: calc(env(safe-area-inset-top) + 10px) 15px 0 !important;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9Qcml2YXRlVGVtcGxhdGUtUGFnZS5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLHNCQUFzQjtBQUN0QixPQUFPO0FBQ1Asa0JBQWtCO0FBQ2xCLDZCQUE2QjtBQXlCN0I7QUF2QkE7QUFDQSxVQUFVO0FBQ1Y7QUFFQTtBQUNBLGdCQUFnQjtBQUNoQjtBQUVBO0FBaEJBO0FBaUJBO0FBY0E7O0FBWkE7QUFDQSx5REFBeUQ7QUFDekQ7O0FBRUE7QUFDQSx5REFBeUQ7QUFDekQ7O0FBRUE7QUFDQSxnRUFBZ0U7QUFDaEU7QUFDQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvUHJpdmF0ZVRlbXBsYXRlLVBhZ2UubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmJveC1zaXppbmc6IGJvcmRlci1ib3g7XG5mbGV4OiAxO1xucGFkZGluZzogMzhweCA0NnB4O1xuYmFja2dyb3VuZDogdmFyKC0tYmctY29sb3ItMik7XG5cbiYuaXNIb21lUGFnZSB7XG5wYWRkaW5nOiAwO1xufVxuXG4mLm9wZW5lZE1lbnUge1xub3ZlcmZsb3c6IGhpZGRlbjtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG5wYWRkaW5nOiBjYWxjKGVudihzYWZlLWFyZWEtaW5zZXQtdG9wKSArIDEwcHgpIDE1cHggNDhweDtcblxuJi5tb2JpbGVHcmFkaWVudEJnIHtcbmJhY2tncm91bmQ6IGxpbmVhci1ncmFkaWVudCgxNTBkZWcsICMwMzY3ZGYsICMxYmE5ZjggNTAlKTtcbn1cblxuJi5tb2JpbGVHcmFkaWVudERhcmtCZyB7XG5iYWNrZ3JvdW5kOiBsaW5lYXItZ3JhZGllbnQoMTUwZGVnLCAjMTQxYzJjLCAjM2M0YTY1IDUwJSk7XG59XG5cbiYuaXNIb21lUGFnZSB7XG5wYWRkaW5nOiBjYWxjKGVudihzYWZlLWFyZWEtaW5zZXQtdG9wKSArIDEwcHgpIDE1cHggMCAhaW1wb3J0YW50O1xufVxufVxufSJdfQ== */