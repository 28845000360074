@value cls1 as a0 from "./UserDropDown-UserName.module.css";
@value cls1 as a1 from "./UserDropDown-ChevronDownStyledSvg.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
z-index: 4;

&.active {
.a0 .a1 {
transform: scaleY(-1);
}
}

@media (max-width: 768px) {
position: static;
}
}