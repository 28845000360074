.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
justify-content: center;
align-items: center;
width: 40px;
height: 40px;
border-radius: 100%;
background: var(--bg-color-2);
color: var(--blue-to-white);
transition:
0.25s background,
0.25s color;
}
.cls2:hover {
background: #2281ff;
color: #fff;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9JY29uQmxvY2stQ29udGFpbmVyLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsa0JBQWtCO0FBQ2xCLGFBQWE7QUFDYix1QkFBdUI7QUFDdkIsbUJBQW1CO0FBQ25CLFdBQVc7QUFDWCxZQUFZO0FBQ1osbUJBQW1CO0FBQ25CLDZCQUE2QjtBQUM3QiwyQkFBMkI7QUFDM0I7O1dBRVc7QUFNWDtBQUpBO0FBQ0EsbUJBQW1CO0FBQ25CLFdBQVc7QUFDWCIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvSWNvbkJsb2NrLUNvbnRhaW5lci5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxucG9zaXRpb246IHJlbGF0aXZlO1xuZGlzcGxheTogZmxleDtcbmp1c3RpZnktY29udGVudDogY2VudGVyO1xuYWxpZ24taXRlbXM6IGNlbnRlcjtcbndpZHRoOiA0MHB4O1xuaGVpZ2h0OiA0MHB4O1xuYm9yZGVyLXJhZGl1czogMTAwJTtcbmJhY2tncm91bmQ6IHZhcigtLWJnLWNvbG9yLTIpO1xuY29sb3I6IHZhcigtLWJsdWUtdG8td2hpdGUpO1xudHJhbnNpdGlvbjpcbjAuMjVzIGJhY2tncm91bmQsXG4wLjI1cyBjb2xvcjtcblxuJjpob3ZlciB7XG5iYWNrZ3JvdW5kOiAjMjI4MWZmO1xuY29sb3I6ICNmZmY7XG59XG59Il19 */