@value cls1 as a0 from "./UserDropDown-UserName.module.css";
@value cls1 as a1 from "./UserDropDown-AvatarIconBlock.module.css";
@value cls1 as a2 from "./UserDropDown-Block.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
cursor: pointer;
user-select: none;
}
.cls2:hover .a0 {
color: #2281ff;
}
.cls2:hover .a1 {
background: #2281ff;
color: #fff;
}
@media (max-width: 900px) {
.cls2 .a2 {
display: none;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Vc2VyRHJvcERvd24tQnV0dG9uQ29udGVudC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLDJEQUEyRDtBQUMzRCxrRUFBa0U7QUFDbEUsd0RBQXdEOztBQUV4RCxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxhQUFhO0FBQ2IsZUFBZTtBQUNmLGlCQUFpQjtBQWtCakI7QUFmQTtBQUNBLGNBQWM7QUFDZDtBQUVBO0FBQ0EsbUJBQW1CO0FBQ25CLFdBQVc7QUFDWDtBQUdBO0FBQ0E7QUFDQSxhQUFhO0FBQ2I7QUFDQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvVXNlckRyb3BEb3duLUJ1dHRvbkNvbnRlbnQubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIkB2YWx1ZSBjbHMxIGFzIGEwIGZyb20gXCIuL1VzZXJEcm9wRG93bi1Vc2VyTmFtZS5tb2R1bGUuY3NzXCI7XG5AdmFsdWUgY2xzMSBhcyBhMSBmcm9tIFwiLi9Vc2VyRHJvcERvd24tQXZhdGFySWNvbkJsb2NrLm1vZHVsZS5jc3NcIjtcbkB2YWx1ZSBjbHMxIGFzIGEyIGZyb20gXCIuL1VzZXJEcm9wRG93bi1CbG9jay5tb2R1bGUuY3NzXCI7XG5cbi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmRpc3BsYXk6IGZsZXg7XG5jdXJzb3I6IHBvaW50ZXI7XG51c2VyLXNlbGVjdDogbm9uZTtcblxuJjpob3ZlciB7XG4uYTAge1xuY29sb3I6ICMyMjgxZmY7XG59XG5cbi5hMSB7XG5iYWNrZ3JvdW5kOiAjMjI4MWZmO1xuY29sb3I6ICNmZmY7XG59XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA5MDBweCkge1xuLmEyIHtcbmRpc3BsYXk6IG5vbmU7XG59XG59XG59Il19 */