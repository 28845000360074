.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding: 40px;
}
@media (max-width: 600px) {
.cls2 {
padding: 20px 0 0 0;
background: transparent;
box-shadow: none
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RhYnMvc2V0dGluZ3MvU2V0dGluZ3NNYWluQWNjb3VudFRhYi1TdXJmYWNlU3R5bGVkLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsYUFBYTtBQU9iO0FBTEE7QUFMQTtBQU1BLG1CQUFtQjtBQUNuQix1QkFBdUI7QUFDdkI7QUFFQTtBQURBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3RhYnMvc2V0dGluZ3MvU2V0dGluZ3NNYWluQWNjb3VudFRhYi1TdXJmYWNlU3R5bGVkLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5wYWRkaW5nOiA0MHB4O1xuXG5AbWVkaWEgKG1heC13aWR0aDogNjAwcHgpIHtcbnBhZGRpbmc6IDIwcHggMCAwIDA7XG5iYWNrZ3JvdW5kOiB0cmFuc3BhcmVudDtcbmJveC1zaGFkb3c6IG5vbmU7XG59XG59Il19 */