.cls1 { /*!*/ }
.cls2 {
composes: cls1;

white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
font-size: 14px;
line-height: 1.5;
color: var(--main-text-color);
}
.cls2 b {
font-weight: 600;
}
@media (max-width: 600px) {
.cls2 {
line-height: 1.15;
max-width: 170px
}

.cls2.isExchange {
margin-top: 4px;
}

.cls2 b {
font-weight: 400;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9IaXN0b3J5T3BlcmF0aW9uc1RlbXBsYXRlLU9wZXJhdGlvbk5hbWUubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxtQkFBbUI7QUFDbkIsZ0JBQWdCO0FBQ2hCLHVCQUF1QjtBQUN2QixlQUFlO0FBQ2YsZ0JBQWdCO0FBQ2hCLDZCQUE2QjtBQWtCN0I7QUFoQkE7QUFDQSxnQkFBZ0I7QUFDaEI7QUFFQTtBQWRBO0FBZUEsaUJBQWlCO0FBQ2pCO0FBVUE7O0FBUkE7QUFDQSxlQUFlO0FBQ2Y7O0FBRUE7QUFDQSxnQkFBZ0I7QUFDaEI7QUFDQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvSGlzdG9yeU9wZXJhdGlvbnNUZW1wbGF0ZS1PcGVyYXRpb25OYW1lLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG53aGl0ZS1zcGFjZTogbm93cmFwO1xub3ZlcmZsb3c6IGhpZGRlbjtcbnRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xuZm9udC1zaXplOiAxNHB4O1xubGluZS1oZWlnaHQ6IDEuNTtcbmNvbG9yOiB2YXIoLS1tYWluLXRleHQtY29sb3IpO1xuXG5iIHtcbmZvbnQtd2VpZ2h0OiA2MDA7XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xubGluZS1oZWlnaHQ6IDEuMTU7XG5tYXgtd2lkdGg6IDE3MHB4O1xuXG4mLmlzRXhjaGFuZ2Uge1xubWFyZ2luLXRvcDogNHB4O1xufVxuXG5iIHtcbmZvbnQtd2VpZ2h0OiA0MDA7XG59XG59XG59Il19 */