.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: fixed;
top: 0;
right: 0;
left: 0;
z-index: 9;
background: var(--bg-color-1);
text-align: center;

@media (max-width: 600px) {
font-weight: 600 !important;
font-size: 16px !important;

* {
font-weight: 600 !important;
font-size: 16px !important;
}
}

&.hide {
display: none;
}
}