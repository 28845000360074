.cls1 { /*!*/ }
.cls2 {
composes: cls1;

justify-content: center;
font-size: 20px;

@media (min-width: 600px) {
align-self: flex-start;
}
}