.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-bottom: 30px;
font-size: 32px;
font-weight: 700;

a {
text-decoration: none;
color: var(--main-second-text-color);
}

@media (max-width: 768px) {
font-size: 24px;
}

@media (max-width: 512px) {
font-size: 18px;
}
}