.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
min-width: 100vw;
height: 100%;
gap: 32px;
color: #1d2534;
text-align: center;
box-sizing: border-box;
}