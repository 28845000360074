.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-weight: 700 !important;
font-size: 16px !important;
padding-bottom: 12px !important;
color: var(--main-text-color) !important;
text-align: left !important;
border-bottom: none !important;
}
.cls2.topSpace {
padding-top: 35px !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9zZXJ2aWNlcy9Gb3JtYXRTZXJ2aWNlLVNlY3Rpb25UaXRsZS5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLDJCQUEyQjtBQUMzQiwwQkFBMEI7QUFDMUIsK0JBQStCO0FBQy9CLHdDQUF3QztBQUN4QywyQkFBMkI7QUFDM0IsOEJBQThCO0FBSzlCO0FBSEE7QUFDQSw0QkFBNEI7QUFDNUIiLCJmaWxlIjoic3JjL3NlcnZpY2VzL0Zvcm1hdFNlcnZpY2UtU2VjdGlvblRpdGxlLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5mb250LXdlaWdodDogNzAwICFpbXBvcnRhbnQ7XG5mb250LXNpemU6IDE2cHggIWltcG9ydGFudDtcbnBhZGRpbmctYm90dG9tOiAxMnB4ICFpbXBvcnRhbnQ7XG5jb2xvcjogdmFyKC0tbWFpbi10ZXh0LWNvbG9yKSAhaW1wb3J0YW50O1xudGV4dC1hbGlnbjogbGVmdCAhaW1wb3J0YW50O1xuYm9yZGVyLWJvdHRvbTogbm9uZSAhaW1wb3J0YW50O1xuXG4mLnRvcFNwYWNlIHtcbnBhZGRpbmctdG9wOiAzNXB4ICFpbXBvcnRhbnQ7XG59XG59Il19 */