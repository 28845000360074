@value cls1 as a0 from "./MobilePageHeader-LinkStyled.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
width: calc(100% - 30px);
font-weight: 600;
font-size: 15px;
z-index: 8;
}
.cls2.white {
color: #fff;
}
.cls2.white .a0 {
color: #fff;
}
.cls2.spaceBottom {
margin-bottom: 55px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vYmlsZS9Nb2JpbGVQYWdlSGVhZGVyLVN0eWxlZC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLGlFQUFpRTs7QUFFakUsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsa0JBQWtCO0FBQ2xCLHdCQUF3QjtBQUN4QixnQkFBZ0I7QUFDaEIsZUFBZTtBQUNmLFVBQVU7QUFhVjtBQVhBO0FBQ0EsV0FBVztBQUtYO0FBSEE7QUFDQSxXQUFXO0FBQ1g7QUFHQTtBQUNBLG1CQUFtQjtBQUNuQiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2JpbGUvTW9iaWxlUGFnZUhlYWRlci1TdHlsZWQubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIkB2YWx1ZSBjbHMxIGFzIGEwIGZyb20gXCIuL01vYmlsZVBhZ2VIZWFkZXItTGlua1N0eWxlZC5tb2R1bGUuY3NzXCI7XG5cbi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbnBvc2l0aW9uOiByZWxhdGl2ZTtcbndpZHRoOiBjYWxjKDEwMCUgLSAzMHB4KTtcbmZvbnQtd2VpZ2h0OiA2MDA7XG5mb250LXNpemU6IDE1cHg7XG56LWluZGV4OiA4O1xuXG4mLndoaXRlIHtcbmNvbG9yOiAjZmZmO1xuXG4uYTAge1xuY29sb3I6ICNmZmY7XG59XG59XG5cbiYuc3BhY2VCb3R0b20ge1xubWFyZ2luLWJvdHRvbTogNTVweDtcbn1cbn0iXX0= */