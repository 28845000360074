.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 40px;
height: 40px;
margin-right: 16px;

&:not(.inProcess) {
background: #fff1e1;
}
}