.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: inline-block;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}
.cls2.highlight {
padding: 4px 8px;
background: var(--bg-color-2);
border-radius: 4px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2F0b21zL0NvcHktVGV4dC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLHFCQUFxQjtBQUNyQixtQkFBbUI7QUFDbkIsZ0JBQWdCO0FBQ2hCLHVCQUF1QjtBQU92QjtBQUxBO0FBQ0EsZ0JBQWdCO0FBQ2hCLDZCQUE2QjtBQUM3QixrQkFBa0I7QUFDbEIiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvYXRvbXMvQ29weS1UZXh0Lm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5kaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG53aGl0ZS1zcGFjZTogbm93cmFwO1xub3ZlcmZsb3c6IGhpZGRlbjtcbnRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xuXG4mLmhpZ2hsaWdodCB7XG5wYWRkaW5nOiA0cHggOHB4O1xuYmFja2dyb3VuZDogdmFyKC0tYmctY29sb3ItMik7XG5ib3JkZXItcmFkaXVzOiA0cHg7XG59XG59Il19 */