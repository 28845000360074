.cls1 { /*!*/ }
.cls2 {
composes: cls1;
}
@media (max-width: 768px) {
.cls2 {
margin-top: -20px !important
}
}
@media (max-width: 500px) {
.cls2 {
margin-top: 20px !important
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3BhZ2VzL2xvZ2luLXN0ZXBzL1N0ZXAyT1RQLUJ1dHRvbkdyb3VwLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7QUFTZDtBQVBBO0FBSEE7QUFJQTtBQU1BO0FBTEE7QUFFQTtBQVBBO0FBUUE7QUFFQTtBQURBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3BhZ2VzL2xvZ2luLXN0ZXBzL1N0ZXAyT1RQLUJ1dHRvbkdyb3VwLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5AbWVkaWEgKG1heC13aWR0aDogNzY4cHgpIHtcbm1hcmdpbi10b3A6IC0yMHB4ICFpbXBvcnRhbnQ7XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA1MDBweCkge1xubWFyZ2luLXRvcDogMjBweCAhaW1wb3J0YW50O1xufVxufSJdfQ== */