.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
width: 27px;
height: 27px;
justify-content: center;
align-items: center;
border-radius: 100%;
border: 1px solid #2281ff;
transform-origin: center;
transition: 0.25s transform;
}
.cls2 svg {
margin: 2px 0 0 0 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9TcG9pbGVyLUljb25DaXJjbGUubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxhQUFhO0FBQ2IsV0FBVztBQUNYLFlBQVk7QUFDWix1QkFBdUI7QUFDdkIsbUJBQW1CO0FBQ25CLG1CQUFtQjtBQUNuQix5QkFBeUI7QUFDekIsd0JBQXdCO0FBQ3hCLDJCQUEyQjtBQUszQjtBQUhBO0FBQ0EsNEJBQTRCO0FBQzVCIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9TcG9pbGVyLUljb25DaXJjbGUubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmRpc3BsYXk6IGZsZXg7XG53aWR0aDogMjdweDtcbmhlaWdodDogMjdweDtcbmp1c3RpZnktY29udGVudDogY2VudGVyO1xuYWxpZ24taXRlbXM6IGNlbnRlcjtcbmJvcmRlci1yYWRpdXM6IDEwMCU7XG5ib3JkZXI6IDFweCBzb2xpZCAjMjI4MWZmO1xudHJhbnNmb3JtLW9yaWdpbjogY2VudGVyO1xudHJhbnNpdGlvbjogMC4yNXMgdHJhbnNmb3JtO1xuXG5zdmcge1xubWFyZ2luOiAycHggMCAwIDAgIWltcG9ydGFudDtcbn1cbn0iXX0= */