.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: space-between;
align-items: center;
background: var(--bg-color-1);
padding: 16px 20px;
margin-bottom: 5px;
font-size: 16px;
border-radius: 6px;

& > div {
flex: 1;
border-bottom: 1px solid var(--bg-color-6);
padding-bottom: 16px;
}

svg {
color: #2281ff;
}
}