.cls1 { /*!*/ }
.cls2 {
composes: cls1;

min-width: 42px;
height: 42px;
margin-right: 15px;
color: #2281ff;

svg {
height: 42px;
}
}