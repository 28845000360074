.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 12px;
max-width: 340px;
color: var(--main-third-text-color);
line-height: 1.3;
margin-top: 7px !important;

@media (max-width: 600px) {
margin-top: 4px !important;
}
}