.cls1 { /*!*/ }
.cls2 {
composes: cls1;

opacity: 1;
transform: translateY(0);
transition:
opacity 0.2s,
transform 0.2s;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21tb24uY3NzLWVudGVyQWN0aXZlMi5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLFVBQVU7QUFDVix3QkFBd0I7QUFDeEI7O2NBRWM7QUFDZCIsImZpbGUiOiJzcmMvY29tbW9uLmNzcy1lbnRlckFjdGl2ZTIubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbm9wYWNpdHk6IDE7XG50cmFuc2Zvcm06IHRyYW5zbGF0ZVkoMCk7XG50cmFuc2l0aW9uOlxub3BhY2l0eSAwLjJzLFxudHJhbnNmb3JtIDAuMnM7XG59Il19 */