.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
padding: 32px 0;
transition: none;
}
@media (max-width: 600px) {
.cls2 {
padding: 0;
background: transparent !important;
margin: 0 -15px;
width: calc(100% + 30px);
box-shadow: none !important
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9IaXN0b3J5T3BlcmF0aW9uc1RlbXBsYXRlLUNvbnRlbnRTdXJmYWNlLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsa0JBQWtCO0FBQ2xCLGVBQWU7QUFDZixnQkFBZ0I7QUFTaEI7QUFQQTtBQVBBO0FBUUEsVUFBVTtBQUNWLGtDQUFrQztBQUNsQyxlQUFlO0FBQ2Ysd0JBQXdCO0FBQ3hCO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvSGlzdG9yeU9wZXJhdGlvbnNUZW1wbGF0ZS1Db250ZW50U3VyZmFjZS5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxucG9zaXRpb246IHJlbGF0aXZlO1xucGFkZGluZzogMzJweCAwO1xudHJhbnNpdGlvbjogbm9uZTtcblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG5wYWRkaW5nOiAwO1xuYmFja2dyb3VuZDogdHJhbnNwYXJlbnQgIWltcG9ydGFudDtcbm1hcmdpbjogMCAtMTVweDtcbndpZHRoOiBjYWxjKDEwMCUgKyAzMHB4KTtcbmJveC1zaGFkb3c6IG5vbmUgIWltcG9ydGFudDtcbn1cbn0iXX0= */