.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
top: 15px;
right: 18px;
color: #b8bdc4;
transition: 0.2s color;
cursor: pointer;

&:hover {
color: #2281ff;
}

@media (max-width: 768px) {
top: 42px;
right: 45px;
}

@media (max-width: 600px) {
top: 15px;
right: 20px;

svg {
width: 12px !important;
color: #9ba1aa;
}
}
}