.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
left: 0;
right: 0;
width: 100%;
height: 1px;
margin-top: -1px;
background: var(--light-bg-color);
}