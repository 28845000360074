.cls1 { /*!*/ }
.cls2 {
composes: cls1;

opacity: 0;
transform: translateY(50px);
transition:
opacity 0.25s,
transform 0.25s;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21tb24uY3NzLWV4aXRBY3RpdmUxLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsVUFBVTtBQUNWLDJCQUEyQjtBQUMzQjs7ZUFFZTtBQUNmIiwiZmlsZSI6InNyYy9jb21tb24uY3NzLWV4aXRBY3RpdmUxLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5vcGFjaXR5OiAwO1xudHJhbnNmb3JtOiB0cmFuc2xhdGVZKDUwcHgpO1xudHJhbnNpdGlvbjpcbm9wYWNpdHkgMC4yNXMsXG50cmFuc2Zvcm0gMC4yNXM7XG59Il19 */