.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: inline-flex;
align-items: center;
justify-content: space-between;
cursor: pointer;
line-height: 1.4;

svg {
margin-left: 8px;
}
}