.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
align-items: center;
justify-content: space-between;
box-sizing: border-box;
border-radius: 20px;
cursor: pointer;
border: 1px solid #dfe2e8;
transition: 0.25s background;

&::after {
position: absolute;
border-radius: 100%;
transition-property: background, transform, box-shadow;
transition-duration: 0.25s;
content: '';
}

&.checked::after {
transform: translateX(100%);
}

&.checked {
border-color: #2281ff;
}

&::after {
background: #2281ff;
}

width: 40px;
height: 22px;
padding: 0 3px;

&::after {
width: 16px;
height: 16px;
}
}