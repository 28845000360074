.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
backdrop-filter: blur(5px);
background: rgba(255, 255, 255, 0.3);
cursor: progress;
border-radius: 6px;
z-index: 5;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Nb2RhbC1Nb2RhbExvYWRpbmcubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxrQkFBa0I7QUFDbEIsTUFBTTtBQUNOLFFBQVE7QUFDUixTQUFTO0FBQ1QsT0FBTztBQUNQLDBCQUEwQjtBQUMxQixvQ0FBb0M7QUFDcEMsZ0JBQWdCO0FBQ2hCLGtCQUFrQjtBQUNsQixVQUFVO0FBQ1YiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbW9sZWN1bGVzL01vZGFsLU1vZGFsTG9hZGluZy5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxucG9zaXRpb246IGFic29sdXRlO1xudG9wOiAwO1xucmlnaHQ6IDA7XG5ib3R0b206IDA7XG5sZWZ0OiAwO1xuYmFja2Ryb3AtZmlsdGVyOiBibHVyKDVweCk7XG5iYWNrZ3JvdW5kOiByZ2JhKDI1NSwgMjU1LCAyNTUsIDAuMyk7XG5jdXJzb3I6IHByb2dyZXNzO1xuYm9yZGVyLXJhZGl1czogNnB4O1xuei1pbmRleDogNTtcbn0iXX0= */