@value cls1 as a0 from "./SwitchLang-Lang.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
width: 100%;
border-radius: 20px;
padding: 7px 13px 7px 10px;
background: #f2f9fd;
background: var(--bg-color-2);
cursor: pointer;
font-size: 13px;

svg {
color: #2281ff;
transform-origin: center;
transform: scaleY(1);
transition: 0.25s transform;
}

&.visible {
& > svg {
transform: scaleY(-1);
}
}

.a0 {
flex: 1;
margin-left: 0;

svg {
opacity: 1;
}
}
}