.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: space-between;
align-items: center;
padding: 16px 48px;
box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.1);
background: #fff;
background: var(--bg-color-1);

svg {
color: var(--main-text-color);
}

@media (max-width: 600px) {
padding: 8px 28px 16px;
}
}