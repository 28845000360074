.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: center;
align-items: center;
width: 28px;
height: 28px;
border-radius: 100%;
border: 1px solid var(--light-bg-color);
transition: 0.25s border-color;

&::before {
width: 12px;
height: 12px;
border-radius: 100%;
transform: scale(0);
background: #2281ff;
content: '';
transition: 0.25s transform;
}
}