.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-weight: 700 !important;
font-size: 16px !important;
padding-bottom: 12px !important;
color: var(--main-text-color) !important;
text-align: left !important;
border-bottom: none !important;

&.topSpace {
padding-top: 35px !important;
}
}