.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: inline-flex;
justify-content: space-around;
align-items: center;
box-sizing: border-box;
border: 1px solid var(--light-bg-color);
border-radius: 6px;
transition: 0.25s border-color;
cursor: default;
white-space: nowrap;

svg {
margin-right: 6px;
}

&:hover {
border-color: #c2c6cc;
}

&.status-cancel,
&.status-DECLINED {
color: #b8bdc4;

svg {
width: 10px;
min-width: 10px;
color: #b8bdc4 !important;
}
}

&.status-success,
&.status-EXECUTED,
&.status-PROCESSED {
color: #7dd130;

svg {
width: 14px;
min-width: 14px;
color: #7dd130 !important;
}

&.bgMode {
color: #fff;
background: #49ca1c;
border-color: transparent;

svg {
color: #fff !important;
}
}
}

&.status-verify,
&.status-SAVED {
color: #ffca1c;

&.bgMode {
color: #000;
background: #ffe576;
border-color: transparent;

svg {
color: #000 !important;
}
}

svg {
width: 14px;
min-width: 14px;
color: #ffca1c !important;
}
}

&.status-ERROR {
color: #ff5c21;

&.bgMode {
color: #fff;
background: #ff5c21;
border-color: transparent;

svg {
color: #fff !important;
}
}

svg {
width: 14px;
min-width: 14px;
color: #ff5c21 !important;
}
}

&.status-READY,
&.status-process,
&.status-INPROCESS,
&.status-NEW,
&.status-ONEXECUTION {
color: #2281ff;

svg {
width: 18px;
min-width: 18px;
}
}

&.size-m {
width: 120px;
height: 36px;
padding: 0 8px;
font-size: 12px;
}

&.size-s {
width: 100px;
height: 30px;
padding: 0 6px;
font-size: 11px;
}

&.onlyIcon {
display: flex;
justify-content: center;
align-items: center;
width: 32px;
height: 32px;
border-radius: 100%;
background: var(--bg-color-2);
border: none;

svg {
margin: 0;
}
}
}