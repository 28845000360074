.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 12px;

ol {
margin: 10px 0 0 0;
padding: 0 0 0 15px;
}
}