.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
top: 0;
right: 0;
height: 18px;
width: 2px;
background: #2281ff;
transition: 0.25s transform ease-in-out;

&.hidden {
opacity: 0;
}

@media (max-width: 600px) {
display: none;
}
}