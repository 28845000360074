.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 13px;
line-height: 170%;

b {
font-weight: 600;
}
}