.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
font-size: 14px;
color: var(--main-text-color);
white-space: nowrap;
}
.cls2.hide {
display: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9IaXN0b3J5T3BlcmF0aW9uc1RlbXBsYXRlLU9wZXJhdGlvbkFtb3VudC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGFBQWE7QUFDYixtQkFBbUI7QUFDbkIsZUFBZTtBQUNmLDZCQUE2QjtBQUM3QixtQkFBbUI7QUFLbkI7QUFIQTtBQUNBLGFBQWE7QUFDYiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvSGlzdG9yeU9wZXJhdGlvbnNUZW1wbGF0ZS1PcGVyYXRpb25BbW91bnQubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmRpc3BsYXk6IGZsZXg7XG5hbGlnbi1pdGVtczogY2VudGVyO1xuZm9udC1zaXplOiAxNHB4O1xuY29sb3I6IHZhcigtLW1haW4tdGV4dC1jb2xvcik7XG53aGl0ZS1zcGFjZTogbm93cmFwO1xuXG4mLmhpZGUge1xuZGlzcGxheTogbm9uZTtcbn1cbn0iXX0= */