.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@keyframes :global(placeholderLoader) {
from {
background-position: 100% 50%;
}
to {
background-position: -25% 50%;
}
}
}