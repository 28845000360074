.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
z-index: 4;
}
@media (max-width: 768px) {
.cls2 {
position: static;
display: flex;
align-items: center
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Ob3RpY2VEcm9wRG93bi1Db250YWluZXIubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxrQkFBa0I7QUFDbEIsVUFBVTtBQU9WO0FBTEE7QUFOQTtBQU9BLGdCQUFnQjtBQUNoQixhQUFhO0FBQ2I7QUFFQTtBQURBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Ob3RpY2VEcm9wRG93bi1Db250YWluZXIubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbnBvc2l0aW9uOiByZWxhdGl2ZTtcbnotaW5kZXg6IDQ7XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA3NjhweCkge1xucG9zaXRpb246IHN0YXRpYztcbmRpc3BsYXk6IGZsZXg7XG5hbGlnbi1pdGVtczogY2VudGVyO1xufVxufSJdfQ== */