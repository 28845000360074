.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 125px;
color: var(--main-text-color);

@media (max-width: 500px) {
width: 110px;
margin-right: 25px;
}
}