.cls1 { /*!*/ }
.cls2 {
composes: cls1;

opacity: 1;
transform: translateY(0);
transition:
opacity 0.25s,
transform 0.25s;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21tb24uY3NzLWVudGVyQWN0aXZlMS5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLFVBQVU7QUFDVix3QkFBd0I7QUFDeEI7O2VBRWU7QUFDZiIsImZpbGUiOiJzcmMvY29tbW9uLmNzcy1lbnRlckFjdGl2ZTEubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbm9wYWNpdHk6IDE7XG50cmFuc2Zvcm06IHRyYW5zbGF0ZVkoMCk7XG50cmFuc2l0aW9uOlxub3BhY2l0eSAwLjI1cyxcbnRyYW5zZm9ybSAwLjI1cztcbn0iXX0= */