.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding-top: 20px;
border-top: 1px solid var(--light-bg-color);
}
.cls2.noFooterBorder {
border-top: none;
}
@media (max-width: 600px) {
.cls2 {
padding-top: 15px;
padding-bottom: calc(env(safe-area-inset-bottom) - 7px)
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Nb2RhbC1Gb290ZXIubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxpQkFBaUI7QUFDakIsMkNBQTJDO0FBVTNDO0FBUkE7QUFDQSxnQkFBZ0I7QUFDaEI7QUFFQTtBQVZBO0FBV0EsaUJBQWlCO0FBQ2pCO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvTW9kYWwtRm9vdGVyLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5wYWRkaW5nLXRvcDogMjBweDtcbmJvcmRlci10b3A6IDFweCBzb2xpZCB2YXIoLS1saWdodC1iZy1jb2xvcik7XG5cbiYubm9Gb290ZXJCb3JkZXIge1xuYm9yZGVyLXRvcDogbm9uZTtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG5wYWRkaW5nLXRvcDogMTVweDtcbnBhZGRpbmctYm90dG9tOiBjYWxjKGVudihzYWZlLWFyZWEtaW5zZXQtYm90dG9tKSAtIDdweCk7XG59XG59Il19 */