.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: center;
padding: 12px;
font-size: 12px;

@media (max-width: 600px) {
padding-bottom: 0;
}
}