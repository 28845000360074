.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
font-size: 60px;
height: 100vh;

span {
font-size: 20px;
}
}