.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border: 1px solid #ff5c21;
border-radius: 4px;
padding: 30px 15px 15px;
box-sizing: border-box;
text-align: center;
line-height: 1.6;
font-size: 14px;
width: 100%;
}
.cls2 svg {
color: #ff5c21;
}
.cls2 > * {
margin-bottom: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Mb2FkaW5nRXJyb3ItQ29udGVudC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGFBQWE7QUFDYixzQkFBc0I7QUFDdEIsdUJBQXVCO0FBQ3ZCLG1CQUFtQjtBQUNuQix5QkFBeUI7QUFDekIsa0JBQWtCO0FBQ2xCLHVCQUF1QjtBQUN2QixzQkFBc0I7QUFDdEIsa0JBQWtCO0FBQ2xCLGdCQUFnQjtBQUNoQixlQUFlO0FBQ2YsV0FBVztBQVNYO0FBUEE7QUFDQSxjQUFjO0FBQ2Q7QUFFQTtBQUNBLG1CQUFtQjtBQUNuQiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvTG9hZGluZ0Vycm9yLUNvbnRlbnQubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmRpc3BsYXk6IGZsZXg7XG5mbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuanVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG5hbGlnbi1pdGVtczogY2VudGVyO1xuYm9yZGVyOiAxcHggc29saWQgI2ZmNWMyMTtcbmJvcmRlci1yYWRpdXM6IDRweDtcbnBhZGRpbmc6IDMwcHggMTVweCAxNXB4O1xuYm94LXNpemluZzogYm9yZGVyLWJveDtcbnRleHQtYWxpZ246IGNlbnRlcjtcbmxpbmUtaGVpZ2h0OiAxLjY7XG5mb250LXNpemU6IDE0cHg7XG53aWR0aDogMTAwJTtcblxuc3ZnIHtcbmNvbG9yOiAjZmY1YzIxO1xufVxuXG4mID4gKiB7XG5tYXJnaW4tYm90dG9tOiAyMHB4O1xufVxufSJdfQ== */