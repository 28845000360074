.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 13px;

@media (max-width: 1200px) {
overflow: visible !important;
max-height: 100% !important;
}
}