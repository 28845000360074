.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: fixed;
display: none;
z-index: 4;
margin: auto;
transition: 0.25s transform;

@media (max-width: 1200px) {
display: block;
right: 50%;
bottom: 18px;
transform: translateX(125%);
}

@media (max-width: 768px) {
transform: translateX(50%);
}
}