@value cls1 as a0 from "./VerifyAlert-RoundIconWrap.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
margin-top: 10px;
margin-bottom: 30px;
padding: 14px 16px;
font-size: 14px;
background-color: var(--bg-color-1) !important;
color: #9ba1aa;
box-sizing: border-box;
transition:
0.25s transform,
0.25s box-shadow;
line-height: 1.45;

&.column {
flex-direction: column;
}

@media (max-width: 600px) {
align-items: flex-start;
flex-direction: column;

.a0 {
width: 36px;
height: 36px;
border: 3px solid #fff;
border-radius: 100%;
margin-top: -35px;
margin-bottom: 5px;
}
}
}