.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-right: 40px !important;
}
@media (max-width: 768px) {
.cls2 {
margin-right: 20px !important;
margin-left: 20px !important
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9IZWFkZXItVGhlbWVTd2l0Y2hlclN0eWxlZC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLDZCQUE2QjtBQU03QjtBQUpBO0FBTEE7QUFNQSw2QkFBNkI7QUFDN0I7QUFFQTtBQURBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9IZWFkZXItVGhlbWVTd2l0Y2hlclN0eWxlZC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxubWFyZ2luLXJpZ2h0OiA0MHB4ICFpbXBvcnRhbnQ7XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA3NjhweCkge1xubWFyZ2luLXJpZ2h0OiAyMHB4ICFpbXBvcnRhbnQ7XG5tYXJnaW4tbGVmdDogMjBweCAhaW1wb3J0YW50O1xufVxufSJdfQ== */