@value cls1 as a0 from "./NoticeDropDown-ReadIndicator.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
width: 100%;
padding: 20px;
text-align: left;
border-bottom: 1px solid var(--light-bg-color);
font-size: 14px;
font-weight: 600;
color: var(--main-text-color);
transition: 0.25s background-color;
}
.cls2.isRead {
font-weight: 400;
color: var(--main-third-text-color);
}
.cls2.isRead .a0 span {
background: var(--main-second-text-color);
}
.cls2:hover {
background-color: var(--bg-color-2);
}
@media (max-width: 600px) {
.cls2 {
flex-direction: column;
padding: 12px 15px;
border-bottom: none;
background: var(--loader-bg-color-1);
border-radius: 20px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Ob3RpY2VEcm9wRG93bi1Ob3RpY2VJdGVtLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsa0VBQWtFOztBQUVsRSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxhQUFhO0FBQ2IsV0FBVztBQUNYLGFBQWE7QUFDYixnQkFBZ0I7QUFDaEIsOENBQThDO0FBQzlDLGVBQWU7QUFDZixnQkFBZ0I7QUFDaEIsNkJBQTZCO0FBQzdCLGtDQUFrQztBQXdCbEM7QUF0QkE7QUFDQSxnQkFBZ0I7QUFDaEIsbUNBQW1DO0FBT25DO0FBSkE7QUFDQSx5Q0FBeUM7QUFDekM7QUFJQTtBQUNBLG1DQUFtQztBQUNuQztBQUVBO0FBNUJBO0FBNkJBLHNCQUFzQjtBQUN0QixrQkFBa0I7QUFDbEIsbUJBQW1CO0FBQ25CLG9DQUFvQztBQUNwQztBQUVBO0FBREEiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbW9sZWN1bGVzL05vdGljZURyb3BEb3duLU5vdGljZUl0ZW0ubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIkB2YWx1ZSBjbHMxIGFzIGEwIGZyb20gXCIuL05vdGljZURyb3BEb3duLVJlYWRJbmRpY2F0b3IubW9kdWxlLmNzc1wiO1xuXG4uY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5kaXNwbGF5OiBmbGV4O1xud2lkdGg6IDEwMCU7XG5wYWRkaW5nOiAyMHB4O1xudGV4dC1hbGlnbjogbGVmdDtcbmJvcmRlci1ib3R0b206IDFweCBzb2xpZCB2YXIoLS1saWdodC1iZy1jb2xvcik7XG5mb250LXNpemU6IDE0cHg7XG5mb250LXdlaWdodDogNjAwO1xuY29sb3I6IHZhcigtLW1haW4tdGV4dC1jb2xvcik7XG50cmFuc2l0aW9uOiAwLjI1cyBiYWNrZ3JvdW5kLWNvbG9yO1xuXG4mLmlzUmVhZCB7XG5mb250LXdlaWdodDogNDAwO1xuY29sb3I6IHZhcigtLW1haW4tdGhpcmQtdGV4dC1jb2xvcik7XG5cbi5hMCB7XG5zcGFuIHtcbmJhY2tncm91bmQ6IHZhcigtLW1haW4tc2Vjb25kLXRleHQtY29sb3IpO1xufVxufVxufVxuXG4mOmhvdmVyIHtcbmJhY2tncm91bmQtY29sb3I6IHZhcigtLWJnLWNvbG9yLTIpO1xufVxuXG5AbWVkaWEgKG1heC13aWR0aDogNjAwcHgpIHtcbmZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG5wYWRkaW5nOiAxMnB4IDE1cHg7XG5ib3JkZXItYm90dG9tOiBub25lO1xuYmFja2dyb3VuZDogdmFyKC0tbG9hZGVyLWJnLWNvbG9yLTEpO1xuYm9yZGVyLXJhZGl1czogMjBweDtcbn1cbn0iXX0= */