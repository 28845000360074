.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: center;
align-items: center;
width: 80px;
height: 80px;
border-radius: 80px;
background: rgba(0, 0, 0, 0.3);
font-weight: 700;
font-size: 30px;
color: #fff;

svg {
color: #2281ff;
}

&.noBg {
background: transparent;
}
}