.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-right: 14px;
}
@media (max-width: 500px) {
.cls2 {
display: none
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RhYnMvSGlzdG9yeUNhcmRUcmFuc2ZlcnNUYWItT3BlcmF0aW9uSWNvbi5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGtCQUFrQjtBQUtsQjtBQUhBO0FBTEE7QUFNQTtBQUVBO0FBREEiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvdGFicy9IaXN0b3J5Q2FyZFRyYW5zZmVyc1RhYi1PcGVyYXRpb25JY29uLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5tYXJnaW4tcmlnaHQ6IDE0cHg7XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA1MDBweCkge1xuZGlzcGxheTogbm9uZTtcbn1cbn0iXX0= */