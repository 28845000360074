.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 160px;
padding: 5px 20px 0;
border-right: 1px solid var(--light-bg-color);

button {
width: 100%;
margin-bottom: 10px;
}

&.onlyCalendar {
padding: 0;
}

@media (max-width: 600px) {
position: absolute;
display: flex;
gap: 10px;
overflow: auto;
width: calc(100% + 40px);
top: -60px;
left: -20px;
right: -20px;
box-sizing: border-box;
padding-left: 20px !important;
padding-right: 20px !important;

&::-webkit-scrollbar {
display: none;
}
}
}