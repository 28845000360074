.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
backdrop-filter: blur(5px);
background: rgba(255, 255, 255, 0.3);
cursor: progress;
border-radius: 6px;
z-index: 5;
}