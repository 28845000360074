.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 6px;
height: 6px;
border-radius: 6px;
background-color: #dfe2e8;
transition: 0.25s width;

&.active {
width: 20px;
}
}