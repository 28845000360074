.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-weight: 600;
font-size: 18px;
line-height: 1;
}
@media (max-width: 600px) {
.cls2.asPageTitleOnMobile {
font-weight: 400;
font-size: 28px;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2F0b21zL0Zvcm1Hcm91cFRpdGxlLUNvbnRhaW5lci5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGdCQUFnQjtBQUNoQixlQUFlO0FBQ2YsY0FBYztBQVFkO0FBTkE7QUFDQTtBQUNBLGdCQUFnQjtBQUNoQixlQUFlO0FBQ2Y7QUFDQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9hdG9tcy9Gb3JtR3JvdXBUaXRsZS1Db250YWluZXIubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmZvbnQtd2VpZ2h0OiA2MDA7XG5mb250LXNpemU6IDE4cHg7XG5saW5lLWhlaWdodDogMTtcblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG4mLmFzUGFnZVRpdGxlT25Nb2JpbGUge1xuZm9udC13ZWlnaHQ6IDQwMDtcbmZvbnQtc2l6ZTogMjhweDtcbn1cbn1cbn0iXX0= */