@value cls1 as a0 from "./Modal-ModalContent.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
position: relative;
max-width: 520px;
width: 100%;
max-height: calc(100% - 20px);
background: var(--bg-color-1);
box-sizing: border-box;
border-radius: 6px;
padding: 35px 45px;
margin: 0 auto;

&.noXPadding {
padding-left: 0 !important;
padding-right: 0 !important;

.a0 {
margin-right: 0;
padding-right: 0;
}
}

&.noYPadding {
padding-top: 0 !important;
padding-bottom: 0 !important;
}

@media (max-width: 600px) {
opacity: 1;
padding: 30px 20px 0;
border-radius: 20px 20px 0 0;
max-height: calc(100% - 20px - env(safe-area-inset-top));
}
}