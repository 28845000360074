.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
gap: 10px;
height: 100vh;
overflow: hidden;
flex-direction: column;
align-items: center;
justify-content: space-between;
background: #1d2534;
padding: 28px;
box-sizing: border-box;
line-height: 1.2;
color: #fff;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3BhZ2VzL01vYmlsZVBpbkNvZGVQYWdlLVdyYXAubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxrQkFBa0I7QUFDbEIsYUFBYTtBQUNiLFNBQVM7QUFDVCxhQUFhO0FBQ2IsZ0JBQWdCO0FBQ2hCLHNCQUFzQjtBQUN0QixtQkFBbUI7QUFDbkIsOEJBQThCO0FBQzlCLG1CQUFtQjtBQUNuQixhQUFhO0FBQ2Isc0JBQXNCO0FBQ3RCLGdCQUFnQjtBQUNoQixXQUFXO0FBQ1giLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvcGFnZXMvTW9iaWxlUGluQ29kZVBhZ2UtV3JhcC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxucG9zaXRpb246IHJlbGF0aXZlO1xuZGlzcGxheTogZmxleDtcbmdhcDogMTBweDtcbmhlaWdodDogMTAwdmg7XG5vdmVyZmxvdzogaGlkZGVuO1xuZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbmFsaWduLWl0ZW1zOiBjZW50ZXI7XG5qdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG5iYWNrZ3JvdW5kOiAjMWQyNTM0O1xucGFkZGluZzogMjhweDtcbmJveC1zaXppbmc6IGJvcmRlci1ib3g7XG5saW5lLWhlaWdodDogMS4yO1xuY29sb3I6ICNmZmY7XG59Il19 */