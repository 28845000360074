@value cls1 as a0 from "./UserDropDown-UserName.module.css";
@value cls1 as a1 from "./UserDropDown-UserEmail.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 267px;
margin-left: -40px;

.a0 {
font-size: 16px;
}

.a1 {
margin-top: 8px;
font-size: 13px;
}

@media (max-width: 768px) {
width: 100%;
margin-top: -15px;
margin-left: 0;
}
}