.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 66px;
height: 66px;
margin: 0 auto 20px;
cursor: pointer;
}
@media (max-width: 500px) {
.cls2 {
display: none
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Vc2VyRHJvcERvd24tRHJvcERvd25JY29uLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsV0FBVztBQUNYLFlBQVk7QUFDWixtQkFBbUI7QUFDbkIsZUFBZTtBQUtmO0FBSEE7QUFSQTtBQVNBO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvVXNlckRyb3BEb3duLURyb3BEb3duSWNvbi5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxud2lkdGg6IDY2cHg7XG5oZWlnaHQ6IDY2cHg7XG5tYXJnaW46IDAgYXV0byAyMHB4O1xuY3Vyc29yOiBwb2ludGVyO1xuXG5AbWVkaWEgKG1heC13aWR0aDogNTAwcHgpIHtcbmRpc3BsYXk6IG5vbmU7XG59XG59Il19 */