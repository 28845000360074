.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border-bottom: 1px solid var(--light-bg-color);
padding-bottom: 15px;
line-height: 1.5;
font-size: 24px;
font-weight: bold;

&.noTitleBorder {
border-bottom: none;
padding-bottom: 0;
}

@media (max-width: 600px) {
font-weight: 400;
font-size: 22px;
}
}