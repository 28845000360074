.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex !important;
width: 32px !important;
height: 32px !important;
justify-content: center !important;
align-items: center !important;
border-radius: 100% !important;
color: #fff !important;
background: #2281ff !important;
}