.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font:
normal 15px/1.7 'Open Sans',
sans-serif;
color: var(--main-text-color);

&.loading * {
cursor: progress !important;
}

&.theme-light {
--bg-color-1: #fff;
--bg-color-2: #f9fafb;
--bg-color-3: #f1f4f7;
--bg-color-4: #fafbfb;
--bg-color-5: #fff;
--bg-color-6: #dfe2e8;
--main-text-color: #2f3541;
--blue-to-white: #2281ff;
--main-second-text-color: #b8bdc4;
--main-third-text-color: #7e8289;

--loader-bg-color-1: #f4f6f7;
--loader-bg-color-2: #e5e8ee;

--light-bg-color: #d9dde4;
--lighter-bg-color: #e7e7e7;
}

&.theme-dark {
--bg-color-1: #1d2534;
--bg-color-2: #2f3e5b;
--bg-color-3: #28344a;
--bg-color-4: #1d2534;
--bg-color-5: #28344a;
--bg-color-6: #525f79;
--main-text-color: #fff;
--blue-to-white: #fff;
--main-second-text-color: #95abd6;
--main-third-text-color: #95abd6;

--loader-bg-color-1: #2f3e5b;
--loader-bg-color-2: #1d2534;

--light-bg-color: #525f79;
--lighter-bg-color: #525f79;
}
}