.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 100%;
border-spacing: 0;
margin-bottom: 65px;
}
.cls2 thead td {
border-bottom: 2px solid var(--light-bg-color);
padding-bottom: 15px;
font-weight: bold;
}
.cls2 tbody tr:first-child td {
padding-top: 20px;
}
.cls2 tr:nth-child(2n) td {
background: var(--bg-color-2);
}
.cls2 tr:nth-child(2n) td:first-child {
border-top-left-radius: 4px;
border-bottom-left-radius: 4px;
}
.cls2 tr:nth-child(2n) td:last-child {
border-top-right-radius: 4px;
border-bottom-right-radius: 4px;
}
.cls2.theme-dark tr:nth-child(2n) td {
background: #243046;
}
.cls2 td {
padding: 12px;
font-size: 14px;
}
.cls2 td:first-child {
padding-left: 32px;
}
.cls2 td:last-child {
padding-right: 32px;
}
@media (max-width: 600px) {
.cls2 td:first-child {
padding-left: 20px;
}

.cls2 td:last-child {
padding-right: 20px;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2F0b21zL1RhYmxlLUNvbnRhaW5lci5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLFdBQVc7QUFDWCxpQkFBaUI7QUFDakIsbUJBQW1CO0FBNERuQjtBQTFEQTtBQUNBLDhDQUE4QztBQUM5QyxvQkFBb0I7QUFDcEIsaUJBQWlCO0FBQ2pCO0FBRUE7QUFDQSxpQkFBaUI7QUFDakI7QUFJQTtBQUNBLDZCQUE2QjtBQVc3QjtBQVRBO0FBQ0EsMkJBQTJCO0FBQzNCLDhCQUE4QjtBQUM5QjtBQUVBO0FBQ0EsNEJBQTRCO0FBQzVCLCtCQUErQjtBQUMvQjtBQU1BO0FBQ0EsbUJBQW1CO0FBQ25CO0FBR0E7QUFDQSxhQUFhO0FBQ2IsZUFBZTtBQVNmO0FBUEE7QUFDQSxrQkFBa0I7QUFDbEI7QUFFQTtBQUNBLG1CQUFtQjtBQUNuQjtBQUdBO0FBRUE7QUFDQSxrQkFBa0I7QUFDbEI7O0FBRUE7QUFDQSxtQkFBbUI7QUFDbkI7QUFFQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9hdG9tcy9UYWJsZS1Db250YWluZXIubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbndpZHRoOiAxMDAlO1xuYm9yZGVyLXNwYWNpbmc6IDA7XG5tYXJnaW4tYm90dG9tOiA2NXB4O1xuXG50aGVhZCB0ZCB7XG5ib3JkZXItYm90dG9tOiAycHggc29saWQgdmFyKC0tbGlnaHQtYmctY29sb3IpO1xucGFkZGluZy1ib3R0b206IDE1cHg7XG5mb250LXdlaWdodDogYm9sZDtcbn1cblxudGJvZHkgdHI6Zmlyc3QtY2hpbGQgdGQge1xucGFkZGluZy10b3A6IDIwcHg7XG59XG5cbnRyIHtcbiY6bnRoLWNoaWxkKDJuKSB7XG50ZCB7XG5iYWNrZ3JvdW5kOiB2YXIoLS1iZy1jb2xvci0yKTtcblxuJjpmaXJzdC1jaGlsZCB7XG5ib3JkZXItdG9wLWxlZnQtcmFkaXVzOiA0cHg7XG5ib3JkZXItYm90dG9tLWxlZnQtcmFkaXVzOiA0cHg7XG59XG5cbiY6bGFzdC1jaGlsZCB7XG5ib3JkZXItdG9wLXJpZ2h0LXJhZGl1czogNHB4O1xuYm9yZGVyLWJvdHRvbS1yaWdodC1yYWRpdXM6IDRweDtcbn1cbn1cbn1cbn1cblxuJi50aGVtZS1kYXJrIHtcbnRyOm50aC1jaGlsZCgybikgdGQge1xuYmFja2dyb3VuZDogIzI0MzA0Njtcbn1cbn1cblxudGQge1xucGFkZGluZzogMTJweDtcbmZvbnQtc2l6ZTogMTRweDtcblxuJjpmaXJzdC1jaGlsZCB7XG5wYWRkaW5nLWxlZnQ6IDMycHg7XG59XG5cbiY6bGFzdC1jaGlsZCB7XG5wYWRkaW5nLXJpZ2h0OiAzMnB4O1xufVxufVxuXG5AbWVkaWEgKG1heC13aWR0aDogNjAwcHgpIHtcbnRkIHtcbiY6Zmlyc3QtY2hpbGQge1xucGFkZGluZy1sZWZ0OiAyMHB4O1xufVxuXG4mOmxhc3QtY2hpbGQge1xucGFkZGluZy1yaWdodDogMjBweDtcbn1cbn1cbn1cbn0iXX0= */