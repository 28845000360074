.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: inline-block;
margin: 0 28px;
color: #d9dde4;

@media (max-width: 512px) {
margin: 0 10px;
}
}