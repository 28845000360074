.cls1 { /*!*/ }
.cls2 {
composes: cls1;

box-sizing: border-box;
flex: 1;
padding: 38px 46px;
background: var(--bg-color-2);

&.isHomePage {
padding: 0;
}

&.openedMenu {
overflow: hidden;
}

@media (max-width: 600px) {
padding: calc(env(safe-area-inset-top) + 10px) 15px 48px;

&.mobileGradientBg {
background: linear-gradient(150deg, #0367df, #1ba9f8 50%);
}

&.mobileGradientDarkBg {
background: linear-gradient(150deg, #141c2c, #3c4a65 50%);
}

&.isHomePage {
padding: calc(env(safe-area-inset-top) + 10px) 15px 0 !important;
}
}
}