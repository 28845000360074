.cls1 { /*!*/ }
.cls2 {
composes: cls1;
}
@media (max-width: 500px) {
.cls2 {
padding: 0 10px !important;
height: 32px !important;
font-size: 14px !important
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9IZWFkZXItVmVyaWZ5QnV0dG9uLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7QUFPZDtBQUxBO0FBSEE7QUFJQSwwQkFBMEI7QUFDMUIsdUJBQXVCO0FBQ3ZCO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvSGVhZGVyLVZlcmlmeUJ1dHRvbi5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuQG1lZGlhIChtYXgtd2lkdGg6IDUwMHB4KSB7XG5wYWRkaW5nOiAwIDEwcHggIWltcG9ydGFudDtcbmhlaWdodDogMzJweCAhaW1wb3J0YW50O1xuZm9udC1zaXplOiAxNHB4ICFpbXBvcnRhbnQ7XG59XG59Il19 */