.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
font-size: 60px;
height: 100vh;
}
.cls2 span {
font-size: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3BhZ2VzL05vdEZvdW5kUGFnZS1Ob3RGb3VuZC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGFBQWE7QUFDYixzQkFBc0I7QUFDdEIsdUJBQXVCO0FBQ3ZCLG1CQUFtQjtBQUNuQixlQUFlO0FBQ2YsYUFBYTtBQUtiO0FBSEE7QUFDQSxlQUFlO0FBQ2YiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvcGFnZXMvTm90Rm91bmRQYWdlLU5vdEZvdW5kLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5kaXNwbGF5OiBmbGV4O1xuZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbmp1c3RpZnktY29udGVudDogY2VudGVyO1xuYWxpZ24taXRlbXM6IGNlbnRlcjtcbmZvbnQtc2l6ZTogNjBweDtcbmhlaWdodDogMTAwdmg7XG5cbnNwYW4ge1xuZm9udC1zaXplOiAyMHB4O1xufVxufSJdfQ== */