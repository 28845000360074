@value cls1 as a0 from "./Header-SwitchLangStyled.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
z-index: 11;
display: flex;
justify-content: space-between;
align-items: center;
padding: 12px 50px;
background: var(--bg-color-1);
box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.1);
}
@media (max-width: 600px) {
.cls2 {
position: fixed;
top: 0;
left: 0;
right: 0;
height: 50px;
padding: 0 15px;
backdrop-filter: saturate(180%) blur(20px);
z-index: 20
}

.cls2.theme-light {
background: rgba(255, 255, 255, 0.72);
}

.cls2.theme-dark {
background: rgba(0, 0, 0, 0.72);
}
}
@media (max-width: 512px) {
.cls2 {
box-shadow: 0 6px 12px rgba(73, 90, 102, 0.07)
}

.cls2 .a0 {
display: none;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9IZWFkZXItQ29udGFpbmVyLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsNkRBQTZEOztBQUU3RCxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxrQkFBa0I7QUFDbEIsV0FBVztBQUNYLGFBQWE7QUFDYiw4QkFBOEI7QUFDOUIsbUJBQW1CO0FBQ25CLGtCQUFrQjtBQUNsQiw2QkFBNkI7QUFDN0IsOENBQThDO0FBNEI5QztBQTFCQTtBQVpBO0FBYUEsZUFBZTtBQUNmLE1BQU07QUFDTixPQUFPO0FBQ1AsUUFBUTtBQUNSLFlBQVk7QUFDWixlQUFlO0FBQ2YsMENBQTBDO0FBQzFDO0FBa0JBOztBQWhCQTtBQUNBLHFDQUFxQztBQUNyQzs7QUFFQTtBQUNBLCtCQUErQjtBQUMvQjtBQUNBO0FBRUE7QUEvQkE7QUFnQ0E7QUFNQTs7QUFKQTtBQUNBLGFBQWE7QUFDYjtBQUNBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9IZWFkZXItQ29udGFpbmVyLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAdmFsdWUgY2xzMSBhcyBhMCBmcm9tIFwiLi9IZWFkZXItU3dpdGNoTGFuZ1N0eWxlZC5tb2R1bGUuY3NzXCI7XG5cbi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbnBvc2l0aW9uOiByZWxhdGl2ZTtcbnotaW5kZXg6IDExO1xuZGlzcGxheTogZmxleDtcbmp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbmFsaWduLWl0ZW1zOiBjZW50ZXI7XG5wYWRkaW5nOiAxMnB4IDUwcHg7XG5iYWNrZ3JvdW5kOiB2YXIoLS1iZy1jb2xvci0xKTtcbmJveC1zaGFkb3c6IDEwcHggMCA1cHggLTJweCByZ2JhKDAsIDAsIDAsIDAuMSk7XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xucG9zaXRpb246IGZpeGVkO1xudG9wOiAwO1xubGVmdDogMDtcbnJpZ2h0OiAwO1xuaGVpZ2h0OiA1MHB4O1xucGFkZGluZzogMCAxNXB4O1xuYmFja2Ryb3AtZmlsdGVyOiBzYXR1cmF0ZSgxODAlKSBibHVyKDIwcHgpO1xuei1pbmRleDogMjA7XG5cbiYudGhlbWUtbGlnaHQge1xuYmFja2dyb3VuZDogcmdiYSgyNTUsIDI1NSwgMjU1LCAwLjcyKTtcbn1cblxuJi50aGVtZS1kYXJrIHtcbmJhY2tncm91bmQ6IHJnYmEoMCwgMCwgMCwgMC43Mik7XG59XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA1MTJweCkge1xuYm94LXNoYWRvdzogMCA2cHggMTJweCByZ2JhKDczLCA5MCwgMTAyLCAwLjA3KTtcblxuLmEwIHtcbmRpc3BsYXk6IG5vbmU7XG59XG59XG59Il19 */