.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding: 40px;

@media (max-width: 600px) {
padding: 20px 0 0 0;
background: transparent;
box-shadow: none;
}
}