@value cls1 as a0 from "./CardHistoryOperationsFilters-ToggleFiltersView.module.css";
@value cls1 as a1 from "./CardHistoryOperationsFilters-FiltersRow.module.css";
@value cls1 as a2 from "./CardHistoryOperationsFilters-QuickDateTab.module.css";
@value cls1 as a3 from "./CardHistoryOperationsFilters-FiltersRow.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding: 32px;
}
@media (max-width: 1100px) {
.cls2 .a0 {
display: none;
}

.cls2 .a1 {
display: flex !important;
flex-direction: column;
}

.cls2 .a1 > * {
width: auto;
}

.cls2 .a2 {
display: none;
}
}
@media (max-width: 600px) {
.cls2 .a3 > * {
width: 100%;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL29yZ2FuaXNtcy9DYXJkSGlzdG9yeU9wZXJhdGlvbnNGaWx0ZXJzLUZpbHRlcnMubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxvRkFBb0Y7QUFDcEYsNkVBQTZFO0FBQzdFLCtFQUErRTtBQUMvRSw2RUFBNkU7O0FBRTdFLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGFBQWE7QUE0QmI7QUExQkE7QUFDQTtBQUNBLGFBQWE7QUFDYjs7QUFFQTtBQUNBLHdCQUF3QjtBQUN4QixzQkFBc0I7QUFLdEI7O0FBSEE7QUFDQSxXQUFXO0FBQ1g7O0FBR0E7QUFDQSxhQUFhO0FBQ2I7QUFDQTtBQUVBO0FBRUE7QUFDQSxXQUFXO0FBQ1g7QUFFQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9vcmdhbmlzbXMvQ2FyZEhpc3RvcnlPcGVyYXRpb25zRmlsdGVycy1GaWx0ZXJzLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAdmFsdWUgY2xzMSBhcyBhMCBmcm9tIFwiLi9DYXJkSGlzdG9yeU9wZXJhdGlvbnNGaWx0ZXJzLVRvZ2dsZUZpbHRlcnNWaWV3Lm1vZHVsZS5jc3NcIjtcbkB2YWx1ZSBjbHMxIGFzIGExIGZyb20gXCIuL0NhcmRIaXN0b3J5T3BlcmF0aW9uc0ZpbHRlcnMtRmlsdGVyc1Jvdy5tb2R1bGUuY3NzXCI7XG5AdmFsdWUgY2xzMSBhcyBhMiBmcm9tIFwiLi9DYXJkSGlzdG9yeU9wZXJhdGlvbnNGaWx0ZXJzLVF1aWNrRGF0ZVRhYi5tb2R1bGUuY3NzXCI7XG5AdmFsdWUgY2xzMSBhcyBhMyBmcm9tIFwiLi9DYXJkSGlzdG9yeU9wZXJhdGlvbnNGaWx0ZXJzLUZpbHRlcnNSb3cubW9kdWxlLmNzc1wiO1xuXG4uY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5wYWRkaW5nOiAzMnB4O1xuXG5AbWVkaWEgKG1heC13aWR0aDogMTEwMHB4KSB7XG4uYTAge1xuZGlzcGxheTogbm9uZTtcbn1cblxuLmExIHtcbmRpc3BsYXk6IGZsZXggIWltcG9ydGFudDtcbmZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG5cbiYgPiAqIHtcbndpZHRoOiBhdXRvO1xufVxufVxuXG4uYTIge1xuZGlzcGxheTogbm9uZTtcbn1cbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG4uYTMge1xuJiA+ICoge1xud2lkdGg6IDEwMCU7XG59XG59XG59XG59Il19 */