.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
left: 0;
right: 0;
text-align: center;

&.left {
text-align: left;

&.leftContentExist {
top: 50px;
}
}
}