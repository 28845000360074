.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
flex-wrap: wrap;
justify-content: space-between;
padding-top: 10px;

@media (max-width: 1200px) {
padding-top: 0;
}
}