.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 70px !important;
font-weight: 900 !important;
line-height: 1.15 !important;
margin-bottom: 0 !important;
}
@media (max-width: 768px) {
.cls2 {
font-size: 50px !important
}
}
@media (max-width: 600px) {
.cls2 {
margin-top: 0 !important;
font-weight: 400 !important;
font-size: 34px !important
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9BdXRoVGVtcGxhdGUtQXV0aFBhZ2VUaXRsZS5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLDBCQUEwQjtBQUMxQiwyQkFBMkI7QUFDM0IsNEJBQTRCO0FBQzVCLDJCQUEyQjtBQVczQjtBQVRBO0FBUkE7QUFTQTtBQVFBO0FBUEE7QUFFQTtBQVpBO0FBYUEsd0JBQXdCO0FBQ3hCLDJCQUEyQjtBQUMzQjtBQUVBO0FBREEiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvdGVtcGxhdGVzL0F1dGhUZW1wbGF0ZS1BdXRoUGFnZVRpdGxlLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5mb250LXNpemU6IDcwcHggIWltcG9ydGFudDtcbmZvbnQtd2VpZ2h0OiA5MDAgIWltcG9ydGFudDtcbmxpbmUtaGVpZ2h0OiAxLjE1ICFpbXBvcnRhbnQ7XG5tYXJnaW4tYm90dG9tOiAwICFpbXBvcnRhbnQ7XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA3NjhweCkge1xuZm9udC1zaXplOiA1MHB4ICFpbXBvcnRhbnQ7XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xubWFyZ2luLXRvcDogMCAhaW1wb3J0YW50O1xuZm9udC13ZWlnaHQ6IDQwMCAhaW1wb3J0YW50O1xuZm9udC1zaXplOiAzNHB4ICFpbXBvcnRhbnQ7XG59XG59Il19 */