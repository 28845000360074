.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin: 60px 30px 0;

@media (max-width: 600px) {
margin: 0 28px;
text-align: center;
}
}