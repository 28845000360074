.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: inline-flex;
align-items: center;
justify-content: space-between;
cursor: pointer;
line-height: 1.4;
}
.cls2 svg {
margin-left: 8px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9TcG9pbGVyLUhlYWRlci5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLG9CQUFvQjtBQUNwQixtQkFBbUI7QUFDbkIsOEJBQThCO0FBQzlCLGVBQWU7QUFDZixnQkFBZ0I7QUFLaEI7QUFIQTtBQUNBLGdCQUFnQjtBQUNoQiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvU3BvaWxlci1IZWFkZXIubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmRpc3BsYXk6IGlubGluZS1mbGV4O1xuYWxpZ24taXRlbXM6IGNlbnRlcjtcbmp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbmN1cnNvcjogcG9pbnRlcjtcbmxpbmUtaGVpZ2h0OiAxLjQ7XG5cbnN2ZyB7XG5tYXJnaW4tbGVmdDogOHB4O1xufVxufSJdfQ== */