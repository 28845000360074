@value cls1 as a0 from "./UserDropDown-UserName.module.css";
@value cls1 as a1 from "./UserDropDown-ChevronDownStyledSvg.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
z-index: 4;
}
.cls2.active .a0 .a1 {
transform: scaleY(-1);
}
@media (max-width: 768px) {
.cls2 {
position: static
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Vc2VyRHJvcERvd24tQ29udGFpbmVyLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsMkRBQTJEO0FBQzNELHVFQUF1RTs7QUFFdkUsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsa0JBQWtCO0FBQ2xCLFVBQVU7QUFXVjtBQVJBO0FBQ0EscUJBQXFCO0FBQ3JCO0FBR0E7QUFaQTtBQWFBO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvVXNlckRyb3BEb3duLUNvbnRhaW5lci5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQHZhbHVlIGNsczEgYXMgYTAgZnJvbSBcIi4vVXNlckRyb3BEb3duLVVzZXJOYW1lLm1vZHVsZS5jc3NcIjtcbkB2YWx1ZSBjbHMxIGFzIGExIGZyb20gXCIuL1VzZXJEcm9wRG93bi1DaGV2cm9uRG93blN0eWxlZFN2Zy5tb2R1bGUuY3NzXCI7XG5cbi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbnBvc2l0aW9uOiByZWxhdGl2ZTtcbnotaW5kZXg6IDQ7XG5cbiYuYWN0aXZlIHtcbi5hMCAuYTEge1xudHJhbnNmb3JtOiBzY2FsZVkoLTEpO1xufVxufVxuXG5AbWVkaWEgKG1heC13aWR0aDogNzY4cHgpIHtcbnBvc2l0aW9uOiBzdGF0aWM7XG59XG59Il19 */