.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
position: fixed;
flex-direction: column;
justify-content: center;
top: 20px;
right: 20px;
z-index: 99999;

@media (max-width: 600px) {
top: auto;
bottom: 10px;
right: 15px;
left: 15px;
}
}