.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-right: 40px !important;

@media (max-width: 768px) {
margin-right: 20px !important;
margin-left: 20px !important;
}
}