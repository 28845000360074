.cls1 { /*!*/ }
.cls2 {
composes: cls1;

line-height: 1;
font-size: 11px;
text-align: center;
color: var(--main-second-text-color);
}
@media (max-width: 500px) {
.cls2 {
text-align: left;
margin-top: 7px;
font-size: 14px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Vc2VyRHJvcERvd24tVXNlckVtYWlsLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsY0FBYztBQUNkLGVBQWU7QUFDZixrQkFBa0I7QUFDbEIsb0NBQW9DO0FBT3BDO0FBTEE7QUFSQTtBQVNBLGdCQUFnQjtBQUNoQixlQUFlO0FBQ2Y7QUFFQTtBQURBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Vc2VyRHJvcERvd24tVXNlckVtYWlsLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5saW5lLWhlaWdodDogMTtcbmZvbnQtc2l6ZTogMTFweDtcbnRleHQtYWxpZ246IGNlbnRlcjtcbmNvbG9yOiB2YXIoLS1tYWluLXNlY29uZC10ZXh0LWNvbG9yKTtcblxuQG1lZGlhIChtYXgtd2lkdGg6IDUwMHB4KSB7XG50ZXh0LWFsaWduOiBsZWZ0O1xubWFyZ2luLXRvcDogN3B4O1xuZm9udC1zaXplOiAxNHB4O1xufVxufSJdfQ== */