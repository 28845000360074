.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
top: 1px;
display: inline-flex;
align-items: center;
justify-content: center;
min-width: 16px;
height: 16px;
padding: 0 4px;
margin-left: 8px;
background: #2281ff;
color: white;
border-radius: 8px;
font-size: 11px;
font-weight: 500;
line-height: 1;

@media (max-width: 600px) {
position: absolute;
top: 5px;
right: 50%;
transform: translateX(8px);
}
}