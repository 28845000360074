.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 12px;
}
.cls2 ol {
margin: 10px 0 0 0;
padding: 0 0 0 15px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3BhZ2VzL2xvZ2luLXN0ZXBzL1N0ZXAyT1RQLUNvZGVOb3RDb21lLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsZUFBZTtBQU1mO0FBSkE7QUFDQSxrQkFBa0I7QUFDbEIsbUJBQW1CO0FBQ25CIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3BhZ2VzL2xvZ2luLXN0ZXBzL1N0ZXAyT1RQLUNvZGVOb3RDb21lLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5mb250LXNpemU6IDEycHg7XG5cbm9sIHtcbm1hcmdpbjogMTBweCAwIDAgMDtcbnBhZGRpbmc6IDAgMCAwIDE1cHg7XG59XG59Il19 */