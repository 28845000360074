.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: inline-flex;
justify-content: center;
align-items: center;
box-sizing: border-box;
border-radius: 100%;
background: #2281ff;
font-weight: 400;
font-size: 18px;
line-height: 1;
color: #fff;

svg {
margin: 0 !important;
}

&.noBg {
background: transparent !important;
}

&.blueIcon svg {
color: #2281ff;
}

&.lightTheme {
background: #f2f9fd;
background: var(--bg-color-2);
}

&.greenIcon svg {
color: #49ca1c;
}

&.blockFlex {
display: flex;
}
}