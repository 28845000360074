.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 14px;
color: #b8bdc4;
margin: 0 !important;
opacity: 0;
visibility: hidden;
transition:
0.25s opacity,
0.25s visibility;

&.visible {
opacity: 1;
visibility: visible;
}
}