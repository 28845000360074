.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: center;
align-items: center;
margin-right: -19px;
margin-left: 6px;
width: 13px;
height: 13px;
background: var(--color-primary);
border-radius: 100%;

svg {
color: #fff;
}
}