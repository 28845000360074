@value cls1 as a0 from "./CardHistoryOperationsFilters-ToggleFiltersView.module.css";
@value cls1 as a1 from "./CardHistoryOperationsFilters-FiltersRow.module.css";
@value cls1 as a2 from "./CardHistoryOperationsFilters-QuickDateTab.module.css";
@value cls1 as a3 from "./CardHistoryOperationsFilters-FiltersRow.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding: 32px;

@media (max-width: 1100px) {
.a0 {
display: none;
}

.a1 {
display: flex !important;
flex-direction: column;

& > * {
width: auto;
}
}

.a2 {
display: none;
}
}

@media (max-width: 600px) {
.a3 {
& > * {
width: 100%;
}
}
}
}