.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
align-items: center;
font-weight: 400;
z-index: 1;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vYmlsZS9Nb2JpbGVQYWdlSGVhZGVyLUxpbmtTdHlsZWQubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxrQkFBa0I7QUFDbEIsYUFBYTtBQUNiLG1CQUFtQjtBQUNuQixnQkFBZ0I7QUFDaEIsVUFBVTtBQUNWIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL21vYmlsZS9Nb2JpbGVQYWdlSGVhZGVyLUxpbmtTdHlsZWQubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbnBvc2l0aW9uOiByZWxhdGl2ZTtcbmRpc3BsYXk6IGZsZXg7XG5hbGlnbi1pdGVtczogY2VudGVyO1xuZm9udC13ZWlnaHQ6IDQwMDtcbnotaW5kZXg6IDE7XG59Il19 */