.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border-top: 1px solid var(--light-bg-color);
margin: 25px 0 !important;
padding-top: 18px;

a {
color: #ff5c21 !important;
}

&:hover a {
color: #ff5c21 !important;
}
}