.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
left: 0;
right: 0;
width: 100%;
height: 1px;
margin-top: -1px;
background: var(--light-bg-color);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2F0b21zL0RlbGltaXRlci1TdHlsZWQubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxrQkFBa0I7QUFDbEIsT0FBTztBQUNQLFFBQVE7QUFDUixXQUFXO0FBQ1gsV0FBVztBQUNYLGdCQUFnQjtBQUNoQixpQ0FBaUM7QUFDakMiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvYXRvbXMvRGVsaW1pdGVyLVN0eWxlZC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxucG9zaXRpb246IGFic29sdXRlO1xubGVmdDogMDtcbnJpZ2h0OiAwO1xud2lkdGg6IDEwMCU7XG5oZWlnaHQ6IDFweDtcbm1hcmdpbi10b3A6IC0xcHg7XG5iYWNrZ3JvdW5kOiB2YXIoLS1saWdodC1iZy1jb2xvcik7XG59Il19 */