.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: inline-block;
margin: 0 28px;
color: #d9dde4;
}
@media (max-width: 512px) {
.cls2 {
margin: 0 10px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9BdXRoVGVtcGxhdGUtRGVsaW1pdGVyLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQscUJBQXFCO0FBQ3JCLGNBQWM7QUFDZCxjQUFjO0FBS2Q7QUFIQTtBQVBBO0FBUUE7QUFFQTtBQURBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9BdXRoVGVtcGxhdGUtRGVsaW1pdGVyLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5kaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG5tYXJnaW46IDAgMjhweDtcbmNvbG9yOiAjZDlkZGU0O1xuXG5AbWVkaWEgKG1heC13aWR0aDogNTEycHgpIHtcbm1hcmdpbjogMCAxMHB4O1xufVxufSJdfQ== */