.cls1 { /*!*/ }
.cls2 {
composes: cls1;
}
.cls2 > div {
margin-bottom: 2px;
}
.cls2.isDeclined {
font-weight: 400;
color: #ff5c21;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RhYnMvSGlzdG9yeUNhcmRUcmFuc2ZlcnNUYWItT3BlcmF0aW9uQW1vdW50VmFsdWUubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYztBQVVkO0FBUkE7QUFDQSxrQkFBa0I7QUFDbEI7QUFFQTtBQUNBLGdCQUFnQjtBQUNoQixjQUFjO0FBQ2QiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvdGFicy9IaXN0b3J5Q2FyZFRyYW5zZmVyc1RhYi1PcGVyYXRpb25BbW91bnRWYWx1ZS5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuJiA+IGRpdiB7XG5tYXJnaW4tYm90dG9tOiAycHg7XG59XG5cbiYuaXNEZWNsaW5lZCB7XG5mb250LXdlaWdodDogNDAwO1xuY29sb3I6ICNmZjVjMjE7XG59XG59Il19 */