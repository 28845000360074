.cls1 { /*!*/ }
.cls2 {
composes: cls1;

box-sizing: border-box;
flex: 1;
overflow-scrolling: touch;
padding: 38px 46px;
background: var(--bg-color-2);

@media (max-width: 1000px) {
padding-right: 0;
padding-left: 0;
}

@media (max-width: 600px) {
padding-top: 0;
}
}