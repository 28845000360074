.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: fixed;
display: flex;
top: 0;
left: 0;
flex-direction: column;
width: 100vw;
height: 100vh;
justify-content: center;
align-items: center;
background: var(--bg-color-1);
z-index: 30;
cursor: progress;
}
.cls2.withTransparent {
background: rgba(255, 255, 255, 0.3);
backdrop-filter: blur(8px);
}
.cls2.visibleHeader {
z-index: 10;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2F0b21zL1NjcmVlbkxvYWRlci1Db250YWluZXIubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxlQUFlO0FBQ2YsYUFBYTtBQUNiLE1BQU07QUFDTixPQUFPO0FBQ1Asc0JBQXNCO0FBQ3RCLFlBQVk7QUFDWixhQUFhO0FBQ2IsdUJBQXVCO0FBQ3ZCLG1CQUFtQjtBQUNuQiw2QkFBNkI7QUFDN0IsV0FBVztBQUNYLGdCQUFnQjtBQVVoQjtBQVJBO0FBQ0Esb0NBQW9DO0FBQ3BDLDBCQUEwQjtBQUMxQjtBQUVBO0FBQ0EsV0FBVztBQUNYIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL2F0b21zL1NjcmVlbkxvYWRlci1Db250YWluZXIubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbnBvc2l0aW9uOiBmaXhlZDtcbmRpc3BsYXk6IGZsZXg7XG50b3A6IDA7XG5sZWZ0OiAwO1xuZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbndpZHRoOiAxMDB2dztcbmhlaWdodDogMTAwdmg7XG5qdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbmFsaWduLWl0ZW1zOiBjZW50ZXI7XG5iYWNrZ3JvdW5kOiB2YXIoLS1iZy1jb2xvci0xKTtcbnotaW5kZXg6IDMwO1xuY3Vyc29yOiBwcm9ncmVzcztcblxuJi53aXRoVHJhbnNwYXJlbnQge1xuYmFja2dyb3VuZDogcmdiYSgyNTUsIDI1NSwgMjU1LCAwLjMpO1xuYmFja2Ryb3AtZmlsdGVyOiBibHVyKDhweCk7XG59XG5cbiYudmlzaWJsZUhlYWRlciB7XG56LWluZGV4OiAxMDtcbn1cbn0iXX0= */