.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 296px;
padding: 0;

@media (max-width: 768px) {
width: 100%;
margin-top: -15px;
margin-left: 0;
}
}