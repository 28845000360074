.cls1 { /*!*/ }
.cls2 {
composes: cls1;

& > div {
margin-bottom: 2px;
}

&.isDeclined {
font-weight: 400;
color: #ff5c21;
}
}