.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: none;
flex-direction: column;
justify-content: space-between;
align-items: stretch;
box-sizing: border-box;
width: 30px;
height: 32px;
margin: 0 15px 0 -8px;
padding: 10px 6px;
cursor: pointer;

&.opened {
span {
&:first-child {
transform: rotate(45deg);
}

&:last-child {
transform: rotate(-45deg);
}
}
}

span {
display: block;
background: #2281ff;
width: 100%;
height: 2px;
transition: transform 0.1596s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.1008s;

&:first-child {
transform-origin: 2px 4px;
}

&:last-child {
transform-origin: 4px -1px;
}
}

@media (max-width: 768px) {
display: flex;
}

@media (max-width: 600px) {
margin-left: 0;
}
}