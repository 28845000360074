.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: center;
align-items: center;
width: 28px;
height: 28px;
border-radius: 100%;
border: 1px solid var(--light-bg-color);
transition: 0.25s border-color;
}
.cls2::before {
width: 12px;
height: 12px;
border-radius: 100%;
transform: scale(0);
background: #2281ff;
content: '';
transition: 0.25s transform;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL29yZ2FuaXNtcy9DaG9vc2VNYWluQWNjb2ludC1SYWRpby5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGFBQWE7QUFDYix1QkFBdUI7QUFDdkIsbUJBQW1CO0FBQ25CLFdBQVc7QUFDWCxZQUFZO0FBQ1osbUJBQW1CO0FBQ25CLHVDQUF1QztBQUN2Qyw4QkFBOEI7QUFXOUI7QUFUQTtBQUNBLFdBQVc7QUFDWCxZQUFZO0FBQ1osbUJBQW1CO0FBQ25CLG1CQUFtQjtBQUNuQixtQkFBbUI7QUFDbkIsV0FBVztBQUNYLDJCQUEyQjtBQUMzQiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9vcmdhbmlzbXMvQ2hvb3NlTWFpbkFjY29pbnQtUmFkaW8ubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmRpc3BsYXk6IGZsZXg7XG5qdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbmFsaWduLWl0ZW1zOiBjZW50ZXI7XG53aWR0aDogMjhweDtcbmhlaWdodDogMjhweDtcbmJvcmRlci1yYWRpdXM6IDEwMCU7XG5ib3JkZXI6IDFweCBzb2xpZCB2YXIoLS1saWdodC1iZy1jb2xvcik7XG50cmFuc2l0aW9uOiAwLjI1cyBib3JkZXItY29sb3I7XG5cbiY6OmJlZm9yZSB7XG53aWR0aDogMTJweDtcbmhlaWdodDogMTJweDtcbmJvcmRlci1yYWRpdXM6IDEwMCU7XG50cmFuc2Zvcm06IHNjYWxlKDApO1xuYmFja2dyb3VuZDogIzIyODFmZjtcbmNvbnRlbnQ6ICcnO1xudHJhbnNpdGlvbjogMC4yNXMgdHJhbnNmb3JtO1xufVxufSJdfQ== */