.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 14px;
color: #b8bdc4;
margin: 0 !important;
opacity: 0;
visibility: hidden;
transition:
0.25s opacity,
0.25s visibility;
}
.cls2.visible {
opacity: 1;
visibility: visible;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9BdXRoVGVtcGxhdGUtUmVzZW5kSW5mby5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGVBQWU7QUFDZixjQUFjO0FBQ2Qsb0JBQW9CO0FBQ3BCLFVBQVU7QUFDVixrQkFBa0I7QUFDbEI7O2dCQUVnQjtBQU1oQjtBQUpBO0FBQ0EsVUFBVTtBQUNWLG1CQUFtQjtBQUNuQiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvQXV0aFRlbXBsYXRlLVJlc2VuZEluZm8ubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmZvbnQtc2l6ZTogMTRweDtcbmNvbG9yOiAjYjhiZGM0O1xubWFyZ2luOiAwICFpbXBvcnRhbnQ7XG5vcGFjaXR5OiAwO1xudmlzaWJpbGl0eTogaGlkZGVuO1xudHJhbnNpdGlvbjpcbjAuMjVzIG9wYWNpdHksXG4wLjI1cyB2aXNpYmlsaXR5O1xuXG4mLnZpc2libGUge1xub3BhY2l0eTogMTtcbnZpc2liaWxpdHk6IHZpc2libGU7XG59XG59Il19 */