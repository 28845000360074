@value cls1 as a0 from "./UserDropDown-UserName.module.css";
@value cls1 as a1 from "./UserDropDown-AvatarIconBlock.module.css";
@value cls1 as a2 from "./UserDropDown-Block.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
cursor: pointer;
user-select: none;

&:hover {
.a0 {
color: #2281ff;
}

.a1 {
background: #2281ff;
color: #fff;
}
}

@media (max-width: 900px) {
.a2 {
display: none;
}
}
}