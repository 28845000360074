.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: center;
align-items: center;
width: 80px;
height: 80px;
border-radius: 80px;
background: rgba(0, 0, 0, 0.3);
font-weight: 700;
font-size: 30px;
color: #fff;
}
.cls2 svg {
color: #2281ff;
}
.cls2.noBg {
background: transparent;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3BhZ2VzL01vYmlsZVBpbkNvZGVQYWdlLU51bS5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGFBQWE7QUFDYix1QkFBdUI7QUFDdkIsbUJBQW1CO0FBQ25CLFdBQVc7QUFDWCxZQUFZO0FBQ1osbUJBQW1CO0FBQ25CLDhCQUE4QjtBQUM5QixnQkFBZ0I7QUFDaEIsZUFBZTtBQUNmLFdBQVc7QUFTWDtBQVBBO0FBQ0EsY0FBYztBQUNkO0FBRUE7QUFDQSx1QkFBdUI7QUFDdkIiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvcGFnZXMvTW9iaWxlUGluQ29kZVBhZ2UtTnVtLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5kaXNwbGF5OiBmbGV4O1xuanVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG5hbGlnbi1pdGVtczogY2VudGVyO1xud2lkdGg6IDgwcHg7XG5oZWlnaHQ6IDgwcHg7XG5ib3JkZXItcmFkaXVzOiA4MHB4O1xuYmFja2dyb3VuZDogcmdiYSgwLCAwLCAwLCAwLjMpO1xuZm9udC13ZWlnaHQ6IDcwMDtcbmZvbnQtc2l6ZTogMzBweDtcbmNvbG9yOiAjZmZmO1xuXG5zdmcge1xuY29sb3I6ICMyMjgxZmY7XG59XG5cbiYubm9CZyB7XG5iYWNrZ3JvdW5kOiB0cmFuc3BhcmVudDtcbn1cbn0iXX0= */