.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-bottom: 0;
line-height: 1.3;
}
@media (max-width: 600px) {
.cls2 {
margin-top: 0;
font-weight: 400;
font-size: 28px
}

.cls2.sub {
font-size: 20px;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2F0b21zL1BhZ2VUaXRsZS1TdHlsZWQubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxnQkFBZ0I7QUFDaEIsZ0JBQWdCO0FBV2hCO0FBVEE7QUFOQTtBQU9BLGFBQWE7QUFDYixnQkFBZ0I7QUFDaEI7QUFNQTs7QUFKQTtBQUNBLGVBQWU7QUFDZjtBQUNBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL2F0b21zL1BhZ2VUaXRsZS1TdHlsZWQubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbm1hcmdpbi1ib3R0b206IDA7XG5saW5lLWhlaWdodDogMS4zO1xuXG5AbWVkaWEgKG1heC13aWR0aDogNjAwcHgpIHtcbm1hcmdpbi10b3A6IDA7XG5mb250LXdlaWdodDogNDAwO1xuZm9udC1zaXplOiAyOHB4O1xuXG4mLnN1YiB7XG5mb250LXNpemU6IDIwcHg7XG59XG59XG59Il19 */