@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800,900&display=swap&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:400&display=swap');

html {
        min-height: -webkit-fill-available;
        font-size: 20px !important;
        background: #f9fafb;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    
    :global(html.theme-dark) {
        background: #2f3e5b !important;
    }
    
    @media (max-width: 1024px) {
        html {
            font-size: 18px !important;
        }
    }
    
    @media (max-width: 768px) {
        html {
            font-size: 16px !important;
        }
    }
    
    @media (max-width: 512px) {
        html {
            font-size: 14px !important;
        }
    }
    
    @media (max-width: 400px) {
        html {
            font-size: 12px !important;
        }
    }

    body {
        height: 100svh;
        -webkit-font-smoothing: antialiased;
        margin: 0;
        transition: .5s opacity;
    }
    
    :global(body.ready) {
        opacity: 1 !important;
    }

    p {
        margin: 0;
    }
    
    input:-webkit-autofill {transition: background-color 5000s ease-in-out 0s;}