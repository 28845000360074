.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
position: fixed;
flex-direction: column;
justify-content: center;
top: 20px;
right: 20px;
z-index: 99999;
}
@media (max-width: 600px) {
.cls2 {
top: auto;
bottom: 10px;
right: 15px;
left: 15px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL05vdGljZXMtQ29udGFpbmVyLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsYUFBYTtBQUNiLGVBQWU7QUFDZixzQkFBc0I7QUFDdEIsdUJBQXVCO0FBQ3ZCLFNBQVM7QUFDVCxXQUFXO0FBQ1gsY0FBYztBQVFkO0FBTkE7QUFYQTtBQVlBLFNBQVM7QUFDVCxZQUFZO0FBQ1osV0FBVztBQUNYO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9Ob3RpY2VzLUNvbnRhaW5lci5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuZGlzcGxheTogZmxleDtcbnBvc2l0aW9uOiBmaXhlZDtcbmZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG5qdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbnRvcDogMjBweDtcbnJpZ2h0OiAyMHB4O1xuei1pbmRleDogOTk5OTk7XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xudG9wOiBhdXRvO1xuYm90dG9tOiAxMHB4O1xucmlnaHQ6IDE1cHg7XG5sZWZ0OiAxNXB4O1xufVxufSJdfQ== */