.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 13px;
height: 13px;
border-radius: 13px;
background-color: #000;
transition: 0.25s background-color;
}
.cls2.active {
background-color: #2281ff;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3BhZ2VzL01vYmlsZVBpbkNvZGVQYWdlLVByb2dyZXNzRG90Lm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsV0FBVztBQUNYLFlBQVk7QUFDWixtQkFBbUI7QUFDbkIsc0JBQXNCO0FBQ3RCLGtDQUFrQztBQUtsQztBQUhBO0FBQ0EseUJBQXlCO0FBQ3pCIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3BhZ2VzL01vYmlsZVBpbkNvZGVQYWdlLVByb2dyZXNzRG90Lm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG53aWR0aDogMTNweDtcbmhlaWdodDogMTNweDtcbmJvcmRlci1yYWRpdXM6IDEzcHg7XG5iYWNrZ3JvdW5kLWNvbG9yOiAjMDAwO1xudHJhbnNpdGlvbjogMC4yNXMgYmFja2dyb3VuZC1jb2xvcjtcblxuJi5hY3RpdmUge1xuYmFja2dyb3VuZC1jb2xvcjogIzIyODFmZjtcbn1cbn0iXX0= */