.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 12px;
}
.cls2 ol {
margin: 10px 0 0 0;
padding: 0 0 0 15px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3BhZ2VzL3JlZ2lzdHJhdGlvbi1zdGVwcy9TdGVwMi1Db2RlTm90Q29tZS5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGVBQWU7QUFNZjtBQUpBO0FBQ0Esa0JBQWtCO0FBQ2xCLG1CQUFtQjtBQUNuQiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9wYWdlcy9yZWdpc3RyYXRpb24tc3RlcHMvU3RlcDItQ29kZU5vdENvbWUubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmZvbnQtc2l6ZTogMTJweDtcblxub2wge1xubWFyZ2luOiAxMHB4IDAgMCAwO1xucGFkZGluZzogMCAwIDAgMTVweDtcbn1cbn0iXX0= */