.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-right: 15px;
}
.cls2 span {
display: block;
width: 10px;
height: 10px;
margin-top: 7px;
border-radius: 100%;
background: #2281ff;
}
@media (max-width: 600px) {
.cls2 {
display: none
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Ob3RpY2VEcm9wRG93bi1SZWFkSW5kaWNhdG9yLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsa0JBQWtCO0FBY2xCO0FBWkE7QUFDQSxjQUFjO0FBQ2QsV0FBVztBQUNYLFlBQVk7QUFDWixlQUFlO0FBQ2YsbUJBQW1CO0FBQ25CLG1CQUFtQjtBQUNuQjtBQUVBO0FBZEE7QUFlQTtBQUVBO0FBREEiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbW9sZWN1bGVzL05vdGljZURyb3BEb3duLVJlYWRJbmRpY2F0b3IubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbm1hcmdpbi1yaWdodDogMTVweDtcblxuc3BhbiB7XG5kaXNwbGF5OiBibG9jaztcbndpZHRoOiAxMHB4O1xuaGVpZ2h0OiAxMHB4O1xubWFyZ2luLXRvcDogN3B4O1xuYm9yZGVyLXJhZGl1czogMTAwJTtcbmJhY2tncm91bmQ6ICMyMjgxZmY7XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xuZGlzcGxheTogbm9uZTtcbn1cbn0iXX0= */