.cls1 { /*!*/ }
.cls2 {
composes: cls1;

box-sizing: border-box;
padding: 0 12%;
}
@media (max-width: 600px) {
.cls2 {
padding: 24px 28px 0
}
}
.cls2.isMobile {
flex-direction: column;
display: flex;
justify-content: space-between;
flex: 1;
padding: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9BdXRoVGVtcGxhdGUtQ29udGVudC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLHNCQUFzQjtBQUN0QixjQUFjO0FBYWQ7QUFYQTtBQU5BO0FBT0E7QUFVQTtBQVRBO0FBRUE7QUFDQSxzQkFBc0I7QUFDdEIsYUFBYTtBQUNiLDhCQUE4QjtBQUM5QixPQUFPO0FBQ1AsVUFBVTtBQUNWIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9BdXRoVGVtcGxhdGUtQ29udGVudC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuYm94LXNpemluZzogYm9yZGVyLWJveDtcbnBhZGRpbmc6IDAgMTIlO1xuXG5AbWVkaWEgKG1heC13aWR0aDogNjAwcHgpIHtcbnBhZGRpbmc6IDI0cHggMjhweCAwO1xufVxuXG4mLmlzTW9iaWxlIHtcbmZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG5kaXNwbGF5OiBmbGV4O1xuanVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuZmxleDogMTtcbnBhZGRpbmc6IDA7XG59XG59Il19 */