.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: space-between;
flex-wrap: wrap;
align-items: center;
margin-bottom: 20px;
padding: 0 32px;

&.noMarginBottom {
margin-bottom: 0;
}

@media (max-width: 1100px) {
padding: 0 20px;
}

@media (max-width: 420px) {
padding: 0 15px;
}
}