@value cls1 as a0 from "./Spoiler-Header.module.css";
@value cls1 as a1 from "./Spoiler-Header.module.css";
@value cls1 as a2 from "./Spoiler-ContentInner.module.css";
@value cls1 as a3 from "./Spoiler-Content.module.css";
@value cls1 as a4 from "./Spoiler-Title.module.css";
@value cls1 as a5 from "./Spoiler-Content.module.css";
@value cls1 as a6 from "./Spoiler-Header.module.css";
@value cls1 as a7 from "./Spoiler-Content.module.css";
@value cls1 as a8 from "./Spoiler-Header.module.css";
@value cls1 as a9 from "./Spoiler-Content.module.css";
@value cls1 as a10 from "./Spoiler-ContentInner.module.css";
@value cls1 as a11 from "./Spoiler-IconCircle.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border-radius: 6px;
transition:
0.2s transform,
0.2s box-shadow;

&.view-default {
box-shadow: 0 18px 30px rgba(73, 90, 102, 0.07);

.a0 {
padding: 20px 25px;
}
}

&.view-modern {
box-shadow: 0 6px 12px rgba(73, 90, 102, 0.07);
border: 1px solid var(--light-bg-color);

.a1 {
padding: 25px 30px;

@media (max-width: 600px) {
padding: 15px;
line-height: 1.3;
}
}

.a2 {
border-top: 1px solid var(--light-bg-color);
}

.a3 {
padding: 40px;
}

.a4 {
margin-left: 20px;
font-size: 18px;
}
}

&.view-text {
.a5 {
padding: 0;
}

.a6 svg {
transition: 0.3s transform;
}

&.active {
.a7 {
padding-top: 10px;
}
.a8 svg {
transform: rotate(180deg);
}
}
}

&:not(.view-text):not(.noSurfacing):not(.active) {
&:hover {
transform: translateY(-3px);
box-shadow: 0 10px 20px rgba(26, 30, 42, 0.14);
}
}

&:not(.active) {
.a9 {
max-height: 0 !important;
}
}

&:not(.view-text) {
.a10 {
padding: 30px;

@media (max-width: 600px) {
padding: 20px 15px;
}
}
}

&.active:not(.view-text) {
transform: none;
box-shadow: 0 18px 30px rgba(73, 90, 102, 0) !important;

.a11 {
transform: rotate(180deg);
}
}
}