.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: inline-flex;
justify-content: center;
align-items: center;
box-sizing: border-box;
border-radius: 100%;
background: #2281ff;
font-weight: 400;
font-size: 18px;
line-height: 1;
color: #fff;
}
.cls2 svg {
margin: 0 !important;
}
.cls2.noBg {
background: transparent !important;
}
.cls2.blueIcon svg {
color: #2281ff;
}
.cls2.lightTheme {
background: #f2f9fd;
background: var(--bg-color-2);
}
.cls2.greenIcon svg {
color: #49ca1c;
}
.cls2.blockFlex {
display: flex;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2F0b21zL1JvdW5kSWNvbi1Db250YWluZXIubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxvQkFBb0I7QUFDcEIsdUJBQXVCO0FBQ3ZCLG1CQUFtQjtBQUNuQixzQkFBc0I7QUFDdEIsbUJBQW1CO0FBQ25CLG1CQUFtQjtBQUNuQixnQkFBZ0I7QUFDaEIsZUFBZTtBQUNmLGNBQWM7QUFDZCxXQUFXO0FBMEJYO0FBeEJBO0FBQ0Esb0JBQW9CO0FBQ3BCO0FBRUE7QUFDQSxrQ0FBa0M7QUFDbEM7QUFFQTtBQUNBLGNBQWM7QUFDZDtBQUVBO0FBQ0EsbUJBQW1CO0FBQ25CLDZCQUE2QjtBQUM3QjtBQUVBO0FBQ0EsY0FBYztBQUNkO0FBRUE7QUFDQSxhQUFhO0FBQ2IiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvYXRvbXMvUm91bmRJY29uLUNvbnRhaW5lci5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuZGlzcGxheTogaW5saW5lLWZsZXg7XG5qdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbmFsaWduLWl0ZW1zOiBjZW50ZXI7XG5ib3gtc2l6aW5nOiBib3JkZXItYm94O1xuYm9yZGVyLXJhZGl1czogMTAwJTtcbmJhY2tncm91bmQ6ICMyMjgxZmY7XG5mb250LXdlaWdodDogNDAwO1xuZm9udC1zaXplOiAxOHB4O1xubGluZS1oZWlnaHQ6IDE7XG5jb2xvcjogI2ZmZjtcblxuc3ZnIHtcbm1hcmdpbjogMCAhaW1wb3J0YW50O1xufVxuXG4mLm5vQmcge1xuYmFja2dyb3VuZDogdHJhbnNwYXJlbnQgIWltcG9ydGFudDtcbn1cblxuJi5ibHVlSWNvbiBzdmcge1xuY29sb3I6ICMyMjgxZmY7XG59XG5cbiYubGlnaHRUaGVtZSB7XG5iYWNrZ3JvdW5kOiAjZjJmOWZkO1xuYmFja2dyb3VuZDogdmFyKC0tYmctY29sb3ItMik7XG59XG5cbiYuZ3JlZW5JY29uIHN2ZyB7XG5jb2xvcjogIzQ5Y2ExYztcbn1cblxuJi5ibG9ja0ZsZXgge1xuZGlzcGxheTogZmxleDtcbn1cbn0iXX0= */