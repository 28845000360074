@value cls1 as a0 from "./ChooseMainAccoint-Radio.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
gap: 15px;
align-items: center;
justify-content: space-between;
padding: 14px 20px;
border: 1px solid var(--light-bg-color);
box-sizing: border-box;
text-align: center;
border-radius: 6px;
font-size: 18px;
font-weight: 600;
color: var(--main-text-color);
cursor: pointer;
transition-property: border-color, transform;
transition-duration: 0.25s;

&:last-child {
margin: 0;
}

&:hover {
border-color: #b9bcc1;
}

&.loading {
cursor: progress;
}

&.active {
color: #2281ff;
border-color: #2281ff;

.a0 {
border-color: #2281ff;

&::before {
transform: scale(1);
}
}
}

@media (max-width: 600px) {
padding: 0;
border: none;
font-size: 16px;
}
}