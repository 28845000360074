.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 100%;
border-spacing: 0;
margin-bottom: 65px;

thead td {
border-bottom: 2px solid var(--light-bg-color);
padding-bottom: 15px;
font-weight: bold;
}

tbody tr:first-child td {
padding-top: 20px;
}

tr {
&:nth-child(2n) {
td {
background: var(--bg-color-2);

&:first-child {
border-top-left-radius: 4px;
border-bottom-left-radius: 4px;
}

&:last-child {
border-top-right-radius: 4px;
border-bottom-right-radius: 4px;
}
}
}
}

&.theme-dark {
tr:nth-child(2n) td {
background: #243046;
}
}

td {
padding: 12px;
font-size: 14px;

&:first-child {
padding-left: 32px;
}

&:last-child {
padding-right: 32px;
}
}

@media (max-width: 600px) {
td {
&:first-child {
padding-left: 20px;
}

&:last-child {
padding-right: 20px;
}
}
}
}