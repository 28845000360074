.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%;
min-height: 100vh;
box-sizing: border-box;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9Qcml2YXRlQ2FyZFRlbXBsYXRlLUNvbnRhaW5lci5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGFBQWE7QUFDYixzQkFBc0I7QUFDdEIsOEJBQThCO0FBQzlCLFdBQVc7QUFDWCxpQkFBaUI7QUFDakIsc0JBQXNCO0FBQ3RCIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9Qcml2YXRlQ2FyZFRlbXBsYXRlLUNvbnRhaW5lci5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuZGlzcGxheTogZmxleDtcbmZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG5qdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG53aWR0aDogMTAwJTtcbm1pbi1oZWlnaHQ6IDEwMHZoO1xuYm94LXNpemluZzogYm9yZGVyLWJveDtcbn0iXX0= */