.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 125px;
color: var(--main-text-color);
}
@media (max-width: 500px) {
.cls2 {
width: 110px;
margin-right: 25px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9IZWFkZXItTG9nb1N2Z1N0eWxlZC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLFlBQVk7QUFDWiw2QkFBNkI7QUFNN0I7QUFKQTtBQU5BO0FBT0EsWUFBWTtBQUNaO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvSGVhZGVyLUxvZ29TdmdTdHlsZWQubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbndpZHRoOiAxMjVweDtcbmNvbG9yOiB2YXIoLS1tYWluLXRleHQtY29sb3IpO1xuXG5AbWVkaWEgKG1heC13aWR0aDogNTAwcHgpIHtcbndpZHRoOiAxMTBweDtcbm1hcmdpbi1yaWdodDogMjVweDtcbn1cbn0iXX0= */