@value cls1 as a0 from "./MobilePageHeader-LinkStyled.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
width: calc(100% - 30px);
font-weight: 600;
font-size: 15px;
z-index: 8;

&.white {
color: #fff;

.a0 {
color: #fff;
}
}

&.spaceBottom {
margin-bottom: 55px;
}
}