.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%;
min-height: 100vh;
box-sizing: border-box;
}