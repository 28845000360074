@value cls1 as a0 from "./SidebarNav-NavItem.module.css";
@value cls1 as a1 from "./SidebarNav-NavItem.module.css";
@value cls1 as a2 from "./SidebarNav-NavItem.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
flex-direction: column;
}
@media (max-width: 768px) {
.cls2 {
align-items: center
}
}
.cls2.theme-light .a0:hover {
background: #f2f9fd;
}
.cls2.theme-dark .a1:hover {
background: #2f3e5b;
}
.cls2.theme-dark .a1 svg {
color: #2281ff;
}
@media (max-width: 600px) {
.cls2 {
position: fixed;
bottom: 0;
z-index: 99;
flex-direction: row;
border-top: 1px solid var(--lighter-bg-color);
background: var(--bg-color-1);
padding-bottom: calc(env(safe-area-inset-bottom) - 7px)
}

.cls2 .a2:hover {
background: transparent !important;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL29yZ2FuaXNtcy9TaWRlYmFyTmF2LU5hdi5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLHdEQUF3RDtBQUN4RCx3REFBd0Q7QUFDeEQsd0RBQXdEOztBQUV4RCxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxrQkFBa0I7QUFDbEIsYUFBYTtBQUNiLHNCQUFzQjtBQXNDdEI7QUFwQ0E7QUFQQTtBQVFBO0FBbUNBO0FBbENBO0FBSUE7QUFDQSxtQkFBbUI7QUFDbkI7QUFNQTtBQUNBLG1CQUFtQjtBQUNuQjtBQUNBO0FBQ0EsY0FBYztBQUNkO0FBSUE7QUE5QkE7QUErQkEsZUFBZTtBQUNmLFNBQVM7QUFDVCxXQUFXO0FBQ1gsbUJBQW1CO0FBQ25CLDZDQUE2QztBQUM3Qyw2QkFBNkI7QUFDN0I7QUFNQTs7QUFKQTtBQUNBLGtDQUFrQztBQUNsQztBQUNBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL29yZ2FuaXNtcy9TaWRlYmFyTmF2LU5hdi5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQHZhbHVlIGNsczEgYXMgYTAgZnJvbSBcIi4vU2lkZWJhck5hdi1OYXZJdGVtLm1vZHVsZS5jc3NcIjtcbkB2YWx1ZSBjbHMxIGFzIGExIGZyb20gXCIuL1NpZGViYXJOYXYtTmF2SXRlbS5tb2R1bGUuY3NzXCI7XG5AdmFsdWUgY2xzMSBhcyBhMiBmcm9tIFwiLi9TaWRlYmFyTmF2LU5hdkl0ZW0ubW9kdWxlLmNzc1wiO1xuXG4uY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5wb3NpdGlvbjogcmVsYXRpdmU7XG5kaXNwbGF5OiBmbGV4O1xuZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcblxuQG1lZGlhIChtYXgtd2lkdGg6IDc2OHB4KSB7XG5hbGlnbi1pdGVtczogY2VudGVyO1xufVxuXG4mLnRoZW1lLWxpZ2h0IHtcbi5hMCB7XG4mOmhvdmVyIHtcbmJhY2tncm91bmQ6ICNmMmY5ZmQ7XG59XG59XG59XG5cbiYudGhlbWUtZGFyayB7XG4uYTEge1xuJjpob3ZlciB7XG5iYWNrZ3JvdW5kOiAjMmYzZTViO1xufVxuc3ZnIHtcbmNvbG9yOiAjMjI4MWZmO1xufVxufVxufVxuXG5AbWVkaWEgKG1heC13aWR0aDogNjAwcHgpIHtcbnBvc2l0aW9uOiBmaXhlZDtcbmJvdHRvbTogMDtcbnotaW5kZXg6IDk5O1xuZmxleC1kaXJlY3Rpb246IHJvdztcbmJvcmRlci10b3A6IDFweCBzb2xpZCB2YXIoLS1saWdodGVyLWJnLWNvbG9yKTtcbmJhY2tncm91bmQ6IHZhcigtLWJnLWNvbG9yLTEpO1xucGFkZGluZy1ib3R0b206IGNhbGMoZW52KHNhZmUtYXJlYS1pbnNldC1ib3R0b20pIC0gN3B4KTtcblxuLmEyOmhvdmVyIHtcbmJhY2tncm91bmQ6IHRyYW5zcGFyZW50ICFpbXBvcnRhbnQ7XG59XG59XG59Il19 */