.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: inline-flex !important;
align-items: center !important;
color: white !important;
background: #49ca1c !important;
border-radius: 20px !important;
padding: 2px 4px 2px 3px !important;
font-size: 12px !important;
line-height: 1 !important;
}
.cls2.gray {
background: #ccc !important;
}
.cls2 svg {
min-width: 9px !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3dpZGdldHMvTGl2ZUNoYXQtVGlja2V0Q291bnQubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCwrQkFBK0I7QUFDL0IsOEJBQThCO0FBQzlCLHVCQUF1QjtBQUN2Qiw4QkFBOEI7QUFDOUIsOEJBQThCO0FBQzlCLG1DQUFtQztBQUNuQywwQkFBMEI7QUFDMUIseUJBQXlCO0FBU3pCO0FBUEE7QUFDQSwyQkFBMkI7QUFDM0I7QUFFQTtBQUNBLHlCQUF5QjtBQUN6QiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy93aWRnZXRzL0xpdmVDaGF0LVRpY2tldENvdW50Lm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5kaXNwbGF5OiBpbmxpbmUtZmxleCAhaW1wb3J0YW50O1xuYWxpZ24taXRlbXM6IGNlbnRlciAhaW1wb3J0YW50O1xuY29sb3I6IHdoaXRlICFpbXBvcnRhbnQ7XG5iYWNrZ3JvdW5kOiAjNDljYTFjICFpbXBvcnRhbnQ7XG5ib3JkZXItcmFkaXVzOiAyMHB4ICFpbXBvcnRhbnQ7XG5wYWRkaW5nOiAycHggNHB4IDJweCAzcHggIWltcG9ydGFudDtcbmZvbnQtc2l6ZTogMTJweCAhaW1wb3J0YW50O1xubGluZS1oZWlnaHQ6IDEgIWltcG9ydGFudDtcblxuJi5ncmF5IHtcbmJhY2tncm91bmQ6ICNjY2MgIWltcG9ydGFudDtcbn1cblxuc3ZnIHtcbm1pbi13aWR0aDogOXB4ICFpbXBvcnRhbnQ7XG59XG59Il19 */