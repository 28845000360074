.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: none;
margin-top: 10px;
font-size: 13px;
color: var(--main-second-text-color);
}
@media (max-width: 600px) {
.cls2 {
display: block
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Ob3RpY2VEcm9wRG93bi1EYXRlLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsYUFBYTtBQUNiLGdCQUFnQjtBQUNoQixlQUFlO0FBQ2Ysb0NBQW9DO0FBS3BDO0FBSEE7QUFSQTtBQVNBO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvTm90aWNlRHJvcERvd24tRGF0ZS5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuZGlzcGxheTogbm9uZTtcbm1hcmdpbi10b3A6IDEwcHg7XG5mb250LXNpemU6IDEzcHg7XG5jb2xvcjogdmFyKC0tbWFpbi1zZWNvbmQtdGV4dC1jb2xvcik7XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xuZGlzcGxheTogYmxvY2s7XG59XG59Il19 */