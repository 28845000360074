.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 296px;
padding: 0;
}
@media (max-width: 768px) {
.cls2 {
width: 100%;
margin-top: -15px;
margin-left: 0
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Ob3RpY2VEcm9wRG93bi1Ecm9wZG93blN0eWxlZC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLFlBQVk7QUFDWixVQUFVO0FBT1Y7QUFMQTtBQU5BO0FBT0EsV0FBVztBQUNYLGlCQUFpQjtBQUNqQjtBQUVBO0FBREEiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbW9sZWN1bGVzL05vdGljZURyb3BEb3duLURyb3Bkb3duU3R5bGVkLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG53aWR0aDogMjk2cHg7XG5wYWRkaW5nOiAwO1xuXG5AbWVkaWEgKG1heC13aWR0aDogNzY4cHgpIHtcbndpZHRoOiAxMDAlO1xubWFyZ2luLXRvcDogLTE1cHg7XG5tYXJnaW4tbGVmdDogMDtcbn1cbn0iXX0= */