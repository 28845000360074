.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
width: 100%;
min-height: 100vh;
max-width: 100vw;
overflow: hidden;

@media (max-width: 600px) {
padding-bottom: calc(env(safe-area-inset-bottom) + 55px);
box-sizing: border-box;
overflow: auto;
}
}