.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
font-size: 8px;
font-weight: 600;
color: var(--blue-to-white);
border: 1px solid var(--bg-color-6);
border-radius: 8px;
padding: 3px 5px;

&.isActive {
cursor: pointer;

svg {
color: #49ca1c;
}
}

svg {
margin-right: 3px;
color: var(--blue-to-white);
}
}