.cls1 { /*!*/ }
.cls2 {
composes: cls1;
}
@keyframes :global(placeholderLoader) {
from {
background-position: 100% 50%;
}
to {
background-position: -25% 50%;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9Db21tb25Dc3MubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYztBQVVkO0FBUkE7QUFDQTtBQUNBLDZCQUE2QjtBQUM3QjtBQUNBO0FBQ0EsNkJBQTZCO0FBQzdCO0FBQ0EiLCJmaWxlIjoic3JjL0NvbW1vbkNzcy5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuQGtleWZyYW1lcyA6Z2xvYmFsKHBsYWNlaG9sZGVyTG9hZGVyKSB7XG5mcm9tIHtcbmJhY2tncm91bmQtcG9zaXRpb246IDEwMCUgNTAlO1xufVxudG8ge1xuYmFja2dyb3VuZC1wb3NpdGlvbjogLTI1JSA1MCU7XG59XG59XG59Il19 */