.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: block;
font-weight: 600;
margin: 15px 0;

@media (max-width: 600px) {
background: var(--bg-color-3);
border-radius: 6px;
font-size: 13px;
padding: 3px 12px;
white-space: nowrap;
color: var(--main-text-color);
font-weight: 400;
}
}