@value cls1 as a0 from "./Copy-CopySvgStyled.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
color: inherit;
cursor: pointer;

& > div {
display: inline-flex;
position: relative;
align-items: center;
max-width: 100%;
}

.a0 {
margin-left: 8px;
min-width: 13px;
}
}