.cls1 { /*!*/ }
.cls2 {
composes: cls1;

opacity: 0;
transform: translateX(100px);
transition:
opacity 0.25s,
transform 0.25s;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21tb24uY3NzLWV4aXRBY3RpdmUubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxVQUFVO0FBQ1YsNEJBQTRCO0FBQzVCOztlQUVlO0FBQ2YiLCJmaWxlIjoic3JjL2NvbW1vbi5jc3MtZXhpdEFjdGl2ZS5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxub3BhY2l0eTogMDtcbnRyYW5zZm9ybTogdHJhbnNsYXRlWCgxMDBweCk7XG50cmFuc2l0aW9uOlxub3BhY2l0eSAwLjI1cyxcbnRyYW5zZm9ybSAwLjI1cztcbn0iXX0= */