@value cls1 as a0 from "./VerifyAlert-RoundIconWrap.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
margin-top: 10px;
margin-bottom: 30px;
padding: 14px 16px;
font-size: 14px;
background-color: var(--bg-color-1) !important;
color: #9ba1aa;
box-sizing: border-box;
transition:
0.25s transform,
0.25s box-shadow;
line-height: 1.45;
}
.cls2.column {
flex-direction: column;
}
@media (max-width: 600px) {
.cls2 {
align-items: flex-start;
flex-direction: column
}

.cls2 .a0 {
width: 36px;
height: 36px;
border: 3px solid #fff;
border-radius: 100%;
margin-top: -35px;
margin-bottom: 5px;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9WZXJpZnlBbGVydC1WZXJpZnlBbGVydFN0eWxlZC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLCtEQUErRDs7QUFFL0QsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsYUFBYTtBQUNiLG1CQUFtQjtBQUNuQixnQkFBZ0I7QUFDaEIsbUJBQW1CO0FBQ25CLGtCQUFrQjtBQUNsQixlQUFlO0FBQ2YsOENBQThDO0FBQzlDLGNBQWM7QUFDZCxzQkFBc0I7QUFDdEI7O2dCQUVnQjtBQUNoQixpQkFBaUI7QUFtQmpCO0FBakJBO0FBQ0Esc0JBQXNCO0FBQ3RCO0FBRUE7QUFyQkE7QUFzQkEsdUJBQXVCO0FBQ3ZCO0FBV0E7O0FBVEE7QUFDQSxXQUFXO0FBQ1gsWUFBWTtBQUNaLHNCQUFzQjtBQUN0QixtQkFBbUI7QUFDbkIsaUJBQWlCO0FBQ2pCLGtCQUFrQjtBQUNsQjtBQUNBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9WZXJpZnlBbGVydC1WZXJpZnlBbGVydFN0eWxlZC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQHZhbHVlIGNsczEgYXMgYTAgZnJvbSBcIi4vVmVyaWZ5QWxlcnQtUm91bmRJY29uV3JhcC5tb2R1bGUuY3NzXCI7XG5cbi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmRpc3BsYXk6IGZsZXg7XG5hbGlnbi1pdGVtczogY2VudGVyO1xubWFyZ2luLXRvcDogMTBweDtcbm1hcmdpbi1ib3R0b206IDMwcHg7XG5wYWRkaW5nOiAxNHB4IDE2cHg7XG5mb250LXNpemU6IDE0cHg7XG5iYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1iZy1jb2xvci0xKSAhaW1wb3J0YW50O1xuY29sb3I6ICM5YmExYWE7XG5ib3gtc2l6aW5nOiBib3JkZXItYm94O1xudHJhbnNpdGlvbjpcbjAuMjVzIHRyYW5zZm9ybSxcbjAuMjVzIGJveC1zaGFkb3c7XG5saW5lLWhlaWdodDogMS40NTtcblxuJi5jb2x1bW4ge1xuZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG5hbGlnbi1pdGVtczogZmxleC1zdGFydDtcbmZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG5cbi5hMCB7XG53aWR0aDogMzZweDtcbmhlaWdodDogMzZweDtcbmJvcmRlcjogM3B4IHNvbGlkICNmZmY7XG5ib3JkZXItcmFkaXVzOiAxMDAlO1xubWFyZ2luLXRvcDogLTM1cHg7XG5tYXJnaW4tYm90dG9tOiA1cHg7XG59XG59XG59Il19 */