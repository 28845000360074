.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding: 30px 25px 30px 30px;
}
@media (max-width: 500px) {
.cls2 {
padding: 20px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Vc2VyRHJvcERvd24tRHJvcERvd25Db250ZW50Lm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsNEJBQTRCO0FBSzVCO0FBSEE7QUFMQTtBQU1BO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvVXNlckRyb3BEb3duLURyb3BEb3duQ29udGVudC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxucGFkZGluZzogMzBweCAyNXB4IDMwcHggMzBweDtcblxuQG1lZGlhIChtYXgtd2lkdGg6IDUwMHB4KSB7XG5wYWRkaW5nOiAyMHB4O1xufVxufSJdfQ== */