.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: space-between;
align-items: baseline;
padding: 12px 20px;
font-size: 15px;
color: var(--main-text-color);
border-bottom: 1px solid var(--light-bg-color);

a,
button {
font-size: 12px;
color: #9ba1aa !important;

&:hover {
color: #2281ff !important;
}
}

@media (max-width: 600px) {
display: none;
}
}