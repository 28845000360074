.cls1 { /*!*/ }
.cls2 {
composes: cls1;

list-style: none;
padding: 0;
margin: 20px 0 0 0;
font-size: 14px;

li {
margin: 20px 0;

&:last-child {
margin-bottom: 0 !important;
}

a {
color: var(--main-text-color);
}

&:hover {
color: #2281ff !important;

a {
color: #2281ff !important;
}
}
}

@media (max-width: 600px) {
font-size: 16px;
}
}