.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
justify-content: space-between;
width: 100vw;
min-height: 100vh;
box-sizing: border-box;
background: var(--bg-color-2) url('../../assets/auth-bg.image.svg') no-repeat 100% center;
background-size: 60%;

@media (max-width: 600px) {
background: none;
width: 100%;
min-height: 100svh;
}
}