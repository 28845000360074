.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: none;
flex-direction: column;
justify-content: space-between;
align-items: stretch;
box-sizing: border-box;
width: 30px;
height: 32px;
margin: 0 15px 0 -8px;
padding: 10px 6px;
cursor: pointer;
}
.cls2.opened span:first-child {
transform: rotate(45deg);
}
.cls2.opened span:last-child {
transform: rotate(-45deg);
}
.cls2 span {
display: block;
background: #2281ff;
width: 100%;
height: 2px;
transition: transform 0.1596s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.1008s;
}
.cls2 span:first-child {
transform-origin: 2px 4px;
}
.cls2 span:last-child {
transform-origin: 4px -1px;
}
@media (max-width: 768px) {
.cls2 {
display: flex
}
}
@media (max-width: 600px) {
.cls2 {
margin-left: 0
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9IZWFkZXItTWVudUljb24ubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxhQUFhO0FBQ2Isc0JBQXNCO0FBQ3RCLDhCQUE4QjtBQUM5QixvQkFBb0I7QUFDcEIsc0JBQXNCO0FBQ3RCLFdBQVc7QUFDWCxZQUFZO0FBQ1oscUJBQXFCO0FBQ3JCLGlCQUFpQjtBQUNqQixlQUFlO0FBcUNmO0FBakNBO0FBQ0Esd0JBQXdCO0FBQ3hCO0FBRUE7QUFDQSx5QkFBeUI7QUFDekI7QUFJQTtBQUNBLGNBQWM7QUFDZCxtQkFBbUI7QUFDbkIsV0FBVztBQUNYLFdBQVc7QUFDWCwwRUFBMEU7QUFTMUU7QUFQQTtBQUNBLHlCQUF5QjtBQUN6QjtBQUVBO0FBQ0EsMEJBQTBCO0FBQzFCO0FBR0E7QUExQ0E7QUEyQ0E7QUFNQTtBQUxBO0FBRUE7QUE5Q0E7QUErQ0E7QUFFQTtBQURBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9IZWFkZXItTWVudUljb24ubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmRpc3BsYXk6IG5vbmU7XG5mbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuanVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuYWxpZ24taXRlbXM6IHN0cmV0Y2g7XG5ib3gtc2l6aW5nOiBib3JkZXItYm94O1xud2lkdGg6IDMwcHg7XG5oZWlnaHQ6IDMycHg7XG5tYXJnaW46IDAgMTVweCAwIC04cHg7XG5wYWRkaW5nOiAxMHB4IDZweDtcbmN1cnNvcjogcG9pbnRlcjtcblxuJi5vcGVuZWQge1xuc3BhbiB7XG4mOmZpcnN0LWNoaWxkIHtcbnRyYW5zZm9ybTogcm90YXRlKDQ1ZGVnKTtcbn1cblxuJjpsYXN0LWNoaWxkIHtcbnRyYW5zZm9ybTogcm90YXRlKC00NWRlZyk7XG59XG59XG59XG5cbnNwYW4ge1xuZGlzcGxheTogYmxvY2s7XG5iYWNrZ3JvdW5kOiAjMjI4MWZmO1xud2lkdGg6IDEwMCU7XG5oZWlnaHQ6IDJweDtcbnRyYW5zaXRpb246IHRyYW5zZm9ybSAwLjE1OTZzIGN1YmljLWJlemllcigwLjUyLCAwLjE2LCAwLjUyLCAwLjg0KSAwLjEwMDhzO1xuXG4mOmZpcnN0LWNoaWxkIHtcbnRyYW5zZm9ybS1vcmlnaW46IDJweCA0cHg7XG59XG5cbiY6bGFzdC1jaGlsZCB7XG50cmFuc2Zvcm0tb3JpZ2luOiA0cHggLTFweDtcbn1cbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDc2OHB4KSB7XG5kaXNwbGF5OiBmbGV4O1xufVxuXG5AbWVkaWEgKG1heC13aWR0aDogNjAwcHgpIHtcbm1hcmdpbi1sZWZ0OiAwO1xufVxufSJdfQ== */