.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
gap: 8px;
padding-left: 89px;
font-size: 12px;
margin: -12px 0 18px;
transition: 0.25s color;
cursor: pointer;
text-decoration: none;
color: var(--main-third-text-color);

&::before {
content: '';
width: 4px;
height: 4px;
border-radius: 100%;
background: var(--main-second-text-color);
transition: 0.25s background;
}

span {
margin-left: -5px;
color: var(--main-text-color);
}

&.active,
&:hover {
color: #2281ff;

&::before {
background: #2281ff;
}
}

@media (max-width: 768px) {
padding-left: 0;
margin-left: 0;
}

@media (max-width: 600px) {
display: none;
}
}