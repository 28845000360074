.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: inline-flex;
font-size: 15px;
font-weight: 600;
width: 78px;
z-index: 10;
}