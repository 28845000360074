.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute !important;
background: white !important;
box-shadow: rgba(0, 0, 0, 0.15) 0 5px 38px !important;
border-radius: 10px !important;
padding: 10px !important;
min-width: 130px !important;
margin-right: 10px !important;
right: 100% !important;
cursor: pointer !important;
}
.cls2 span {
font-weight: 600 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3dpZGdldHMvTGl2ZUNoYXQtVGlja2V0Q29udGFpbmVyLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsNkJBQTZCO0FBQzdCLDRCQUE0QjtBQUM1QixxREFBcUQ7QUFDckQsOEJBQThCO0FBQzlCLHdCQUF3QjtBQUN4QiwyQkFBMkI7QUFDM0IsNkJBQTZCO0FBQzdCLHNCQUFzQjtBQUN0QiwwQkFBMEI7QUFLMUI7QUFIQTtBQUNBLDJCQUEyQjtBQUMzQiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy93aWRnZXRzL0xpdmVDaGF0LVRpY2tldENvbnRhaW5lci5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxucG9zaXRpb246IGFic29sdXRlICFpbXBvcnRhbnQ7XG5iYWNrZ3JvdW5kOiB3aGl0ZSAhaW1wb3J0YW50O1xuYm94LXNoYWRvdzogcmdiYSgwLCAwLCAwLCAwLjE1KSAwIDVweCAzOHB4ICFpbXBvcnRhbnQ7XG5ib3JkZXItcmFkaXVzOiAxMHB4ICFpbXBvcnRhbnQ7XG5wYWRkaW5nOiAxMHB4ICFpbXBvcnRhbnQ7XG5taW4td2lkdGg6IDEzMHB4ICFpbXBvcnRhbnQ7XG5tYXJnaW4tcmlnaHQ6IDEwcHggIWltcG9ydGFudDtcbnJpZ2h0OiAxMDAlICFpbXBvcnRhbnQ7XG5jdXJzb3I6IHBvaW50ZXIgIWltcG9ydGFudDtcblxuc3BhbiB7XG5mb250LXdlaWdodDogNjAwICFpbXBvcnRhbnQ7XG59XG59Il19 */