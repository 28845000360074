.cls1 { /*!*/ }
.cls2 {
composes: cls1;

min-width: 42px;
height: 42px;
margin-right: 15px;
color: #2281ff;
}
.cls2 svg {
height: 42px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RhYnMvSGlzdG9yeUNhcmRUcmFuc2ZlcnNUYWItU3lzdGVtSWNvbi5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGVBQWU7QUFDZixZQUFZO0FBQ1osa0JBQWtCO0FBQ2xCLGNBQWM7QUFLZDtBQUhBO0FBQ0EsWUFBWTtBQUNaIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3RhYnMvSGlzdG9yeUNhcmRUcmFuc2ZlcnNUYWItU3lzdGVtSWNvbi5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxubWluLXdpZHRoOiA0MnB4O1xuaGVpZ2h0OiA0MnB4O1xubWFyZ2luLXJpZ2h0OiAxNXB4O1xuY29sb3I6ICMyMjgxZmY7XG5cbnN2ZyB7XG5oZWlnaHQ6IDQycHg7XG59XG59Il19 */