:global(.chaport-window) {
        overflow: visible !important;
    }
    
    :global(.chaport-inner) {
        background: transparent !important
    }
    
    :global(.chaport-inner-iframe) {
        border-radius: 10px !important;
        overflow: hidden !important;
        background: rgb(255, 255, 255) !important;
    }
    
    @media (max-width: 768px) {
        :global(.chaport-launcher-button) {
            right: 15px !important;
            bottom: 70px !important;
        }
    }