.cls1 { /*!*/ }
.cls2 {
composes: cls1;

box-sizing: border-box;
flex: 1;
overflow-scrolling: touch;
padding: 38px 46px;
background: var(--bg-color-2);
}
@media (max-width: 1000px) {
.cls2 {
padding-right: 0;
padding-left: 0
}
}
@media (max-width: 600px) {
.cls2 {
padding-top: 0
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9Qcml2YXRlQ2FyZFRlbXBsYXRlLVBhZ2VDb250ZW50Lm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsc0JBQXNCO0FBQ3RCLE9BQU87QUFDUCx5QkFBeUI7QUFDekIsa0JBQWtCO0FBQ2xCLDZCQUE2QjtBQVU3QjtBQVJBO0FBVEE7QUFVQSxnQkFBZ0I7QUFDaEI7QUFNQTtBQUxBO0FBRUE7QUFkQTtBQWVBO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvUHJpdmF0ZUNhcmRUZW1wbGF0ZS1QYWdlQ29udGVudC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuYm94LXNpemluZzogYm9yZGVyLWJveDtcbmZsZXg6IDE7XG5vdmVyZmxvdy1zY3JvbGxpbmc6IHRvdWNoO1xucGFkZGluZzogMzhweCA0NnB4O1xuYmFja2dyb3VuZDogdmFyKC0tYmctY29sb3ItMik7XG5cbkBtZWRpYSAobWF4LXdpZHRoOiAxMDAwcHgpIHtcbnBhZGRpbmctcmlnaHQ6IDA7XG5wYWRkaW5nLWxlZnQ6IDA7XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xucGFkZGluZy10b3A6IDA7XG59XG59Il19 */