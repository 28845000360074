.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
justify-content: space-between;
width: 100vw;
min-height: 100vh;
box-sizing: border-box;
background: var(--bg-color-2) url('../../assets/auth-bg.image.svg') no-repeat 100% center;
background-size: 60%;
}
@media (max-width: 600px) {
.cls2 {
background: none;
width: 100%;
min-height: 100svh
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9BdXRoVGVtcGxhdGUtQXV0aFBhZ2VTdHlsZWQubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxhQUFhO0FBQ2Isc0JBQXNCO0FBQ3RCLDhCQUE4QjtBQUM5QixZQUFZO0FBQ1osaUJBQWlCO0FBQ2pCLHNCQUFzQjtBQUN0Qix5RkFBeUY7QUFDekYsb0JBQW9CO0FBT3BCO0FBTEE7QUFaQTtBQWFBLGdCQUFnQjtBQUNoQixXQUFXO0FBQ1g7QUFFQTtBQURBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9BdXRoVGVtcGxhdGUtQXV0aFBhZ2VTdHlsZWQubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmRpc3BsYXk6IGZsZXg7XG5mbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuanVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xud2lkdGg6IDEwMHZ3O1xubWluLWhlaWdodDogMTAwdmg7XG5ib3gtc2l6aW5nOiBib3JkZXItYm94O1xuYmFja2dyb3VuZDogdmFyKC0tYmctY29sb3ItMikgdXJsKCcuLi8uLi9hc3NldHMvYXV0aC1iZy5pbWFnZS5zdmcnKSBuby1yZXBlYXQgMTAwJSBjZW50ZXI7XG5iYWNrZ3JvdW5kLXNpemU6IDYwJTtcblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG5iYWNrZ3JvdW5kOiBub25lO1xud2lkdGg6IDEwMCU7XG5taW4taGVpZ2h0OiAxMDBzdmg7XG59XG59Il19 */