.cls1 { /*!*/ }
.cls2 {
composes: cls1;
}
@media (max-width: 600px) {
.cls2 {
width: 100%;
gap: 12px !important;
flex-direction: column
}

.cls2 button {
width: 100%;
height: 48px;
justify-content: center;
}
.cls2.inverse button:nth-child(2) {
order: 2;
background: transparent;
color: #2281ff;
}
.cls2:not(.inverse) button:nth-child(1) {
order: 1;
background: transparent;
color: #2281ff;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2F0b21zL0FkYXB0aXZlQnV0dG9uR3JvdXAubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYztBQTZCZDtBQTNCQTtBQUhBO0FBSUEsV0FBVztBQUNYLG9CQUFvQjtBQUNwQjtBQXdCQTs7QUF0QkE7QUFDQSxXQUFXO0FBQ1gsWUFBWTtBQUNaLHVCQUF1QjtBQUN2QjtBQUdBO0FBQ0EsUUFBUTtBQUNSLHVCQUF1QjtBQUN2QixjQUFjO0FBQ2Q7QUFJQTtBQUNBLFFBQVE7QUFDUix1QkFBdUI7QUFDdkIsY0FBYztBQUNkO0FBRUEiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvYXRvbXMvQWRhcHRpdmVCdXR0b25Hcm91cC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG53aWR0aDogMTAwJTtcbmdhcDogMTJweCAhaW1wb3J0YW50O1xuZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcblxuYnV0dG9uIHtcbndpZHRoOiAxMDAlO1xuaGVpZ2h0OiA0OHB4O1xuanVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG59XG5cbiYuaW52ZXJzZSB7XG5idXR0b246bnRoLWNoaWxkKDIpIHtcbm9yZGVyOiAyO1xuYmFja2dyb3VuZDogdHJhbnNwYXJlbnQ7XG5jb2xvcjogIzIyODFmZjtcbn1cbn1cblxuJjpub3QoLmludmVyc2UpIHtcbmJ1dHRvbjpudGgtY2hpbGQoMSkge1xub3JkZXI6IDE7XG5iYWNrZ3JvdW5kOiB0cmFuc3BhcmVudDtcbmNvbG9yOiAjMjI4MWZmO1xufVxufVxufVxufSJdfQ== */