.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 70px !important;
font-weight: 900 !important;
line-height: 1.15 !important;
margin-bottom: 0 !important;

@media (max-width: 768px) {
font-size: 50px !important;
}

@media (max-width: 600px) {
margin-top: 0 !important;
font-weight: 400 !important;
font-size: 34px !important;
}
}