@value cls1 as a0 from "./HistoryCardTransfersTab-Row.module.css";
@value cls1 as a1 from "./HistoryCardTransfersTab-Column.module.css";
@value cls1 as a2 from "./HistoryCardTransfersTab-Date.module.css";
@value cls1 as a3 from "./HistoryCardTransfersTab-Column.module.css";
@value cls1 as a4 from "./HistoryCardTransfersTab-Date.module.css";
@value cls1 as a5 from "./HistoryCardTransfersTab-Column.module.css";
@value cls1 as a6 from "./HistoryCardTransfersTab-Date.module.css";
@value cls1 as a7 from "./HistoryCardTransfersTab-StatusLabelStyled.module.css";
@value ###ASTROTURF_IMPORTED_0### as a8 from "###ASTROTURF_SOURCE_0###";
@value cls1 as a9 from "./HistoryCardTransfersTab-Column.module.css";
@value cls1 as a10 from "./HistoryCardTransfersTab-Date.module.css";
@value cls1 as a11 from "./HistoryCardTransfersTab-Column.module.css";
@value cls1 as a12 from "./HistoryCardTransfersTab-Date.module.css";
@value cls1 as a13 from "./HistoryCardTransfersTab-SystemIcon.module.css";
@value ###ASTROTURF_IMPORTED_1### as a14 from "###ASTROTURF_SOURCE_1###";
@value ###ASTROTURF_IMPORTED_2### as a15 from "###ASTROTURF_SOURCE_2###";
@value cls1 as a16 from "./HistoryCardTransfersTab-OperationIcon.module.css";
@value ###ASTROTURF_IMPORTED_3### as a17 from "###ASTROTURF_SOURCE_3###";
@value cls1 as a18 from "./HistoryCardTransfersTab-Column.module.css";
@value cls1 as a19 from "./HistoryCardTransfersTab-SystemIcon.module.css";
@value cls1 as a20 from "./HistoryCardTransfersTab-StatusLabelStyled.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: table;
width: 100%;
border-spacing: 0;
margin-bottom: 30px;

.a0:hover {
.a1, .a2 {
background: var(--bg-color-4);
}
}

&.theme-dark {
.a3, .a4 {
border-bottom: 1px solid var(--bg-color-2);
}
}

.a5, .a6 {
padding: 25px 10px 25px 0;
border-bottom: 1px solid var(--bg-color-3);
transition: 0.25s background-color;
cursor: pointer;

&:last-child {
padding-right: 32px;
text-align: right;
}

&:first-child {
padding-left: 32px;
}
}

@media screen and (max-width: 1100px) {
.a7 {
height: 30px;
width: auto;
padding: 0;
margin-top: 5px;
border: none;

svg {
margin-right: 7px;
}
}
.a8 {
justify-content: flex-end;
}
.a9 {
&:last-child {
padding-right: 20px;
}

&:first-child {
padding-left: 20px;
}
}
.a10 {
display: none !important;
}
.a11, .a12 {
padding: 15px 10px 13px 0;
}
.a13 {
margin-right: 0;
}
}

@media screen and (max-width: 500px) {
.a14,
.a15 {
font-size: 12px;
}
.a16 {
height: auto;
background: none !important;
margin-right: 0;
}
.a17 {
margin-top: 0;
font-size: 13px;
}
.a18 {
padding-right: 0;

&:last-child {
padding: 20px 15px 20px 0;
}

&:first-child {
padding-left: 15px;
}
}
.a19 {
min-width: 38px;
margin-right: 10px;
}
.a20 {
height: auto;
margin-top: 0;
}
}
}