.cls1 { /*!*/ }
.cls2 {
composes: cls1;
}
.cls2:not(.modalContentNoOverflowAuto) {
overflow: auto;
}
.cls2 {
padding: 20px 0;
margin-right: -45px;
padding-right: 45px; }
.cls2::-webkit-scrollbar {
width: 6px;
}
.cls2::-webkit-scrollbar-track {
background-color: var(--main-second-text-color);
}
.cls2.theme-dark::-webkit-scrollbar-track {
background-color: #28344a;
}
.cls2::-webkit-scrollbar-thumb {
background-color: #2281ff;
border-radius: 8px;
}
@media (max-width: 600px) {
.cls2 {
margin: 0 -20px;
padding: 10px 20px
}

.cls2::-webkit-scrollbar {
display: none;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Nb2RhbC1Nb2RhbENvbnRlbnQubW9kdWxlLmNzcyIsIjxubyBzb3VyY2U+Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjO0FBb0NkO0FBbENBO0FBQ0EsY0FBYztBQUNkO0FDTkE7QURPQSxlQUFlO0FBQ2YsbUJBQW1CO0FBQ25CLHNCQ1RBO0FEV0E7QUFDQSxVQUFVO0FBQ1Y7QUFFQTtBQUNBLCtDQUErQztBQUMvQztBQUdBO0FBQ0EseUJBQXlCO0FBQ3pCO0FBR0E7QUFDQSx5QkFBeUI7QUFDekIsa0JBQWtCO0FBQ2xCO0FBRUE7QUE3QkE7QUE4QkEsZUFBZTtBQUNmO0FBTUE7O0FBSkE7QUFDQSxhQUFhO0FBQ2I7QUFDQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvTW9kYWwtTW9kYWxDb250ZW50Lm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG4mOm5vdCgubW9kYWxDb250ZW50Tm9PdmVyZmxvd0F1dG8pIHtcbm92ZXJmbG93OiBhdXRvO1xufVxucGFkZGluZzogMjBweCAwO1xubWFyZ2luLXJpZ2h0OiAtNDVweDtcbnBhZGRpbmctcmlnaHQ6IDQ1cHg7XG5cbiY6Oi13ZWJraXQtc2Nyb2xsYmFyIHtcbndpZHRoOiA2cHg7XG59XG5cbiY6Oi13ZWJraXQtc2Nyb2xsYmFyLXRyYWNrIHtcbmJhY2tncm91bmQtY29sb3I6IHZhcigtLW1haW4tc2Vjb25kLXRleHQtY29sb3IpO1xufVxuXG4mLnRoZW1lLWRhcmsge1xuJjo6LXdlYmtpdC1zY3JvbGxiYXItdHJhY2sge1xuYmFja2dyb3VuZC1jb2xvcjogIzI4MzQ0YTtcbn1cbn1cblxuJjo6LXdlYmtpdC1zY3JvbGxiYXItdGh1bWIge1xuYmFja2dyb3VuZC1jb2xvcjogIzIyODFmZjtcbmJvcmRlci1yYWRpdXM6IDhweDtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG5tYXJnaW46IDAgLTIwcHg7XG5wYWRkaW5nOiAxMHB4IDIwcHg7XG5cbiY6Oi13ZWJraXQtc2Nyb2xsYmFyIHtcbmRpc3BsYXk6IG5vbmU7XG59XG59XG59IixudWxsXX0= */