.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
justify-content: center;
width: 20px;
margin-right: 21px;

@media (max-width: 600px) {
margin: 0 0 7px;
}
}