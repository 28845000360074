.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
top: 7px;
right: 9px;
width: 8px;
height: 8px;
background: rgba(255, 82, 82, 1);
box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
border: 2px solid var(--bg-color-2);
border-radius: 100%;
transition: 0.25s border-color;
animation: pulse-red 2s infinite;
}
@keyframes pulse-red {
0% {
transform: scale(0.9);
box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
}

70% {
transform: scale(1);
box-shadow: 0 0 0 6px rgba(255, 82, 82, 0);
}

100% {
transform: scale(0.9);
box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
}
}
@media (max-width: 500px) {
.cls2 {
top: -3px;
right: 0;
border-width: 1px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Ob3RpY2VEcm9wRG93bi1Ob3RpY2VJbmRpY2F0b3IubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxrQkFBa0I7QUFDbEIsUUFBUTtBQUNSLFVBQVU7QUFDVixVQUFVO0FBQ1YsV0FBVztBQUNYLGdDQUFnQztBQUNoQyx3Q0FBd0M7QUFDeEMsbUNBQW1DO0FBQ25DLG1CQUFtQjtBQUNuQiw4QkFBOEI7QUFDOUIsZ0NBQWdDO0FBd0JoQztBQXRCQTtBQUNBO0FBQ0EscUJBQXFCO0FBQ3JCLDBDQUEwQztBQUMxQzs7QUFFQTtBQUNBLG1CQUFtQjtBQUNuQiwwQ0FBMEM7QUFDMUM7O0FBRUE7QUFDQSxxQkFBcUI7QUFDckIsd0NBQXdDO0FBQ3hDO0FBQ0E7QUFFQTtBQWhDQTtBQWlDQSxTQUFTO0FBQ1QsUUFBUTtBQUNSO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvTm90aWNlRHJvcERvd24tTm90aWNlSW5kaWNhdG9yLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5wb3NpdGlvbjogYWJzb2x1dGU7XG50b3A6IDdweDtcbnJpZ2h0OiA5cHg7XG53aWR0aDogOHB4O1xuaGVpZ2h0OiA4cHg7XG5iYWNrZ3JvdW5kOiByZ2JhKDI1NSwgODIsIDgyLCAxKTtcbmJveC1zaGFkb3c6IDAgMCAwIDAgcmdiYSgyNTUsIDgyLCA4MiwgMSk7XG5ib3JkZXI6IDJweCBzb2xpZCB2YXIoLS1iZy1jb2xvci0yKTtcbmJvcmRlci1yYWRpdXM6IDEwMCU7XG50cmFuc2l0aW9uOiAwLjI1cyBib3JkZXItY29sb3I7XG5hbmltYXRpb246IHB1bHNlLXJlZCAycyBpbmZpbml0ZTtcblxuQGtleWZyYW1lcyBwdWxzZS1yZWQge1xuMCUge1xudHJhbnNmb3JtOiBzY2FsZSgwLjkpO1xuYm94LXNoYWRvdzogMCAwIDAgMCByZ2JhKDI1NSwgODIsIDgyLCAwLjcpO1xufVxuXG43MCUge1xudHJhbnNmb3JtOiBzY2FsZSgxKTtcbmJveC1zaGFkb3c6IDAgMCAwIDZweCByZ2JhKDI1NSwgODIsIDgyLCAwKTtcbn1cblxuMTAwJSB7XG50cmFuc2Zvcm06IHNjYWxlKDAuOSk7XG5ib3gtc2hhZG93OiAwIDAgMCAwIHJnYmEoMjU1LCA4MiwgODIsIDApO1xufVxufVxuXG5AbWVkaWEgKG1heC13aWR0aDogNTAwcHgpIHtcbnRvcDogLTNweDtcbnJpZ2h0OiAwO1xuYm9yZGVyLXdpZHRoOiAxcHg7XG59XG59Il19 */