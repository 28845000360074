.cls1 { /*!*/ }
.cls2 {
composes: cls1;

line-height: 1;
font-size: 11px;
text-align: center;
color: var(--main-second-text-color);

@media (max-width: 500px) {
text-align: left;
margin-top: 7px;
font-size: 14px;
}
}