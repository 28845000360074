.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute;
background: var(--bg-color-2);
border: 1px solid var(--bg-color-3);
font-weight: 600;

&.from {
top: 0;
left: 0;
color: #2281ff;
}

&.to {
bottom: 0;
right: 0;
color: #49ca1c;
}

&.smallSize {
min-width: 22px !important;
width: 22px !important;
height: 22px !important;
font-size: 13px;
}

@media (max-width: 600px) {
background: #2281ff;
color: #fff !important;
}
}