@value cls1 as a0 from "./Copy-CopySvgStyled.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
color: inherit;
cursor: pointer;
}
.cls2 > div {
display: inline-flex;
position: relative;
align-items: center;
max-width: 100%;
}
.cls2 .a0 {
margin-left: 8px;
min-width: 13px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2F0b21zL0NvcHktQ29udGFpbmVyLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsd0RBQXdEOztBQUV4RCxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxrQkFBa0I7QUFDbEIsY0FBYztBQUNkLGVBQWU7QUFhZjtBQVhBO0FBQ0Esb0JBQW9CO0FBQ3BCLGtCQUFrQjtBQUNsQixtQkFBbUI7QUFDbkIsZUFBZTtBQUNmO0FBRUE7QUFDQSxnQkFBZ0I7QUFDaEIsZUFBZTtBQUNmIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL2F0b21zL0NvcHktQ29udGFpbmVyLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAdmFsdWUgY2xzMSBhcyBhMCBmcm9tIFwiLi9Db3B5LUNvcHlTdmdTdHlsZWQubW9kdWxlLmNzc1wiO1xuXG4uY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5wb3NpdGlvbjogcmVsYXRpdmU7XG5jb2xvcjogaW5oZXJpdDtcbmN1cnNvcjogcG9pbnRlcjtcblxuJiA+IGRpdiB7XG5kaXNwbGF5OiBpbmxpbmUtZmxleDtcbnBvc2l0aW9uOiByZWxhdGl2ZTtcbmFsaWduLWl0ZW1zOiBjZW50ZXI7XG5tYXgtd2lkdGg6IDEwMCU7XG59XG5cbi5hMCB7XG5tYXJnaW4tbGVmdDogOHB4O1xubWluLXdpZHRoOiAxM3B4O1xufVxufSJdfQ== */