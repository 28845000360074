.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
align-items: center;
justify-content: space-between;
box-sizing: border-box;
border-radius: 20px;
cursor: pointer;
border: 1px solid #dfe2e8;
transition: 0.25s background;
}
.cls2::after {
position: absolute;
border-radius: 100%;
transition-property: background, transform, box-shadow;
transition-duration: 0.25s;
content: '';
}
.cls2.checked::after {
transform: translateX(100%);
}
.cls2.checked {
border-color: #2281ff;
}
.cls2::after {
background: #2281ff;
}
.cls2 {

width: 40px;
height: 22px;
padding: 0 3px; }
.cls2::after {
width: 16px;
height: 16px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9UaGVtZVN3aXRjaGVyLVN3aXRjaC5tb2R1bGUuY3NzIiwiPG5vIHNvdXJjZT4iXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsa0JBQWtCO0FBQ2xCLGFBQWE7QUFDYixtQkFBbUI7QUFDbkIsOEJBQThCO0FBQzlCLHNCQUFzQjtBQUN0QixtQkFBbUI7QUFDbkIsZUFBZTtBQUNmLHlCQUF5QjtBQUN6Qiw0QkFBNEI7QUE4QjVCO0FBNUJBO0FBQ0Esa0JBQWtCO0FBQ2xCLG1CQUFtQjtBQUNuQixzREFBc0Q7QUFDdEQsMEJBQTBCO0FBQzFCLFdBQVc7QUFDWDtBQUVBO0FBQ0EsMkJBQTJCO0FBQzNCO0FBRUE7QUFDQSxxQkFBcUI7QUFDckI7QUFFQTtBQUNBLG1CQUFtQjtBQUNuQjtBQ2hDQTs7QURrQ0EsV0FBVztBQUNYLFlBQVk7QUFDWixpQkNwQ0E7QURzQ0E7QUFDQSxXQUFXO0FBQ1gsWUFBWTtBQUNaIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9UaGVtZVN3aXRjaGVyLVN3aXRjaC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxucG9zaXRpb246IHJlbGF0aXZlO1xuZGlzcGxheTogZmxleDtcbmFsaWduLWl0ZW1zOiBjZW50ZXI7XG5qdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG5ib3gtc2l6aW5nOiBib3JkZXItYm94O1xuYm9yZGVyLXJhZGl1czogMjBweDtcbmN1cnNvcjogcG9pbnRlcjtcbmJvcmRlcjogMXB4IHNvbGlkICNkZmUyZTg7XG50cmFuc2l0aW9uOiAwLjI1cyBiYWNrZ3JvdW5kO1xuXG4mOjphZnRlciB7XG5wb3NpdGlvbjogYWJzb2x1dGU7XG5ib3JkZXItcmFkaXVzOiAxMDAlO1xudHJhbnNpdGlvbi1wcm9wZXJ0eTogYmFja2dyb3VuZCwgdHJhbnNmb3JtLCBib3gtc2hhZG93O1xudHJhbnNpdGlvbi1kdXJhdGlvbjogMC4yNXM7XG5jb250ZW50OiAnJztcbn1cblxuJi5jaGVja2VkOjphZnRlciB7XG50cmFuc2Zvcm06IHRyYW5zbGF0ZVgoMTAwJSk7XG59XG5cbiYuY2hlY2tlZCB7XG5ib3JkZXItY29sb3I6ICMyMjgxZmY7XG59XG5cbiY6OmFmdGVyIHtcbmJhY2tncm91bmQ6ICMyMjgxZmY7XG59XG5cbndpZHRoOiA0MHB4O1xuaGVpZ2h0OiAyMnB4O1xucGFkZGluZzogMCAzcHg7XG5cbiY6OmFmdGVyIHtcbndpZHRoOiAxNnB4O1xuaGVpZ2h0OiAxNnB4O1xufVxufSIsbnVsbF19 */