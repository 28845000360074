.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: none;
margin-top: 10px;
font-size: 13px;
color: var(--main-second-text-color);

@media (max-width: 600px) {
display: block;
}
}