.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: center;
padding: 12px;
font-size: 12px;
}
@media (max-width: 600px) {
.cls2 {
padding-bottom: 0
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Ob3RpY2VEcm9wRG93bi1Gb290ZXIubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxhQUFhO0FBQ2IsdUJBQXVCO0FBQ3ZCLGFBQWE7QUFDYixlQUFlO0FBS2Y7QUFIQTtBQVJBO0FBU0E7QUFFQTtBQURBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Ob3RpY2VEcm9wRG93bi1Gb290ZXIubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmRpc3BsYXk6IGZsZXg7XG5qdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbnBhZGRpbmc6IDEycHg7XG5mb250LXNpemU6IDEycHg7XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xucGFkZGluZy1ib3R0b206IDA7XG59XG59Il19 */