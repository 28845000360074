.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
align-items: center;
cursor: pointer;
transition: 0.25s color;
}
.cls2.internal {
padding: 5px 9px;
font: 600 13px 'Open Sans';
}
.cls2 svg {
width: 15px;
height: 15px;
border: 2px solid transparent;
border-radius: 100%;
transform: translate3d(0, 0, 0);
margin-right: 5px;
}
.cls2.internal:not(.active):hover {
opacity: 1;
}
.cls2.active {
cursor: default;
}
.cls2.active::after {
width: 6px;
height: 6px;
background-color: #2281ff;
border-radius: 100%;
margin-left: 8px;
content: '';
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Td2l0Y2hMYW5nLUxhbmcubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxrQkFBa0I7QUFDbEIsYUFBYTtBQUNiLG1CQUFtQjtBQUNuQixlQUFlO0FBQ2YsdUJBQXVCO0FBZ0N2QjtBQTlCQTtBQUNBLGdCQUFnQjtBQUNoQiwwQkFBMEI7QUFDMUI7QUFFQTtBQUNBLFdBQVc7QUFDWCxZQUFZO0FBQ1osNkJBQTZCO0FBQzdCLG1CQUFtQjtBQUNuQiwrQkFBK0I7QUFDL0IsaUJBQWlCO0FBQ2pCO0FBRUE7QUFDQSxVQUFVO0FBQ1Y7QUFFQTtBQUNBLGVBQWU7QUFVZjtBQVJBO0FBQ0EsVUFBVTtBQUNWLFdBQVc7QUFDWCx5QkFBeUI7QUFDekIsbUJBQW1CO0FBQ25CLGdCQUFnQjtBQUNoQixXQUFXO0FBQ1giLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbW9sZWN1bGVzL1N3aXRjaExhbmctTGFuZy5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxucG9zaXRpb246IHJlbGF0aXZlO1xuZGlzcGxheTogZmxleDtcbmFsaWduLWl0ZW1zOiBjZW50ZXI7XG5jdXJzb3I6IHBvaW50ZXI7XG50cmFuc2l0aW9uOiAwLjI1cyBjb2xvcjtcblxuJi5pbnRlcm5hbCB7XG5wYWRkaW5nOiA1cHggOXB4O1xuZm9udDogNjAwIDEzcHggJ09wZW4gU2Fucyc7XG59XG5cbnN2ZyB7XG53aWR0aDogMTVweDtcbmhlaWdodDogMTVweDtcbmJvcmRlcjogMnB4IHNvbGlkIHRyYW5zcGFyZW50O1xuYm9yZGVyLXJhZGl1czogMTAwJTtcbnRyYW5zZm9ybTogdHJhbnNsYXRlM2QoMCwgMCwgMCk7XG5tYXJnaW4tcmlnaHQ6IDVweDtcbn1cblxuJi5pbnRlcm5hbDpub3QoLmFjdGl2ZSk6aG92ZXIge1xub3BhY2l0eTogMTtcbn1cblxuJi5hY3RpdmUge1xuY3Vyc29yOiBkZWZhdWx0O1xuXG4mOjphZnRlciB7XG53aWR0aDogNnB4O1xuaGVpZ2h0OiA2cHg7XG5iYWNrZ3JvdW5kLWNvbG9yOiAjMjI4MWZmO1xuYm9yZGVyLXJhZGl1czogMTAwJTtcbm1hcmdpbi1sZWZ0OiA4cHg7XG5jb250ZW50OiAnJztcbn1cbn1cbn0iXX0= */