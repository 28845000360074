.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
width: 100%;
min-height: 100vh;
max-width: 100vw;
overflow: hidden;
}
@media (max-width: 600px) {
.cls2 {
padding-bottom: calc(env(safe-area-inset-bottom) + 55px);
box-sizing: border-box;
overflow: auto
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9Qcml2YXRlVGVtcGxhdGUtQ29udGFpbmVyLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsa0JBQWtCO0FBQ2xCLGFBQWE7QUFDYixXQUFXO0FBQ1gsaUJBQWlCO0FBQ2pCLGdCQUFnQjtBQUNoQixnQkFBZ0I7QUFPaEI7QUFMQTtBQVZBO0FBV0Esd0RBQXdEO0FBQ3hELHNCQUFzQjtBQUN0QjtBQUVBO0FBREEiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvdGVtcGxhdGVzL1ByaXZhdGVUZW1wbGF0ZS1Db250YWluZXIubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbnBvc2l0aW9uOiByZWxhdGl2ZTtcbmRpc3BsYXk6IGZsZXg7XG53aWR0aDogMTAwJTtcbm1pbi1oZWlnaHQ6IDEwMHZoO1xubWF4LXdpZHRoOiAxMDB2dztcbm92ZXJmbG93OiBoaWRkZW47XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xucGFkZGluZy1ib3R0b206IGNhbGMoZW52KHNhZmUtYXJlYS1pbnNldC1ib3R0b20pICsgNTVweCk7XG5ib3gtc2l6aW5nOiBib3JkZXItYm94O1xub3ZlcmZsb3c6IGF1dG87XG59XG59Il19 */