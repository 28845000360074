@value cls1 as a0 from "./NoticeDropDown-ReadIndicator.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
width: 100%;
padding: 20px;
text-align: left;
border-bottom: 1px solid var(--light-bg-color);
font-size: 14px;
font-weight: 600;
color: var(--main-text-color);
transition: 0.25s background-color;

&.isRead {
font-weight: 400;
color: var(--main-third-text-color);

.a0 {
span {
background: var(--main-second-text-color);
}
}
}

&:hover {
background-color: var(--bg-color-2);
}

@media (max-width: 600px) {
flex-direction: column;
padding: 12px 15px;
border-bottom: none;
background: var(--loader-bg-color-1);
border-radius: 20px;
}
}