.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border: 1px solid #ff5c21;
border-radius: 4px;
padding: 30px 15px 15px;
box-sizing: border-box;
text-align: center;
line-height: 1.6;
font-size: 14px;
width: 100%;

svg {
color: #ff5c21;
}

& > * {
margin-bottom: 20px;
}
}