.cls1 { /*!*/ }
.cls2 {
composes: cls1;

background: var(--bg-color-2);
margin-top: 48px;

&.isHomePage {
position: absolute;
bottom: -90px;
left: 48px;
right: 48px;
margin: 0;
padding: 0;
background: none;

@media (max-width: 600px) {
position: static;
margin: -10px 0 20px;
padding: 0 15px;
}
}
}