.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
justify-content: center;
width: 20px;
margin-right: 21px;
}
@media (max-width: 600px) {
.cls2 {
margin: 0 0 7px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL29yZ2FuaXNtcy9TaWRlYmFyTmF2LUljb24ubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxhQUFhO0FBQ2IsbUJBQW1CO0FBQ25CLHVCQUF1QjtBQUN2QixXQUFXO0FBQ1gsa0JBQWtCO0FBS2xCO0FBSEE7QUFUQTtBQVVBO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9vcmdhbmlzbXMvU2lkZWJhck5hdi1JY29uLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5kaXNwbGF5OiBmbGV4O1xuYWxpZ24taXRlbXM6IGNlbnRlcjtcbmp1c3RpZnktY29udGVudDogY2VudGVyO1xud2lkdGg6IDIwcHg7XG5tYXJnaW4tcmlnaHQ6IDIxcHg7XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xubWFyZ2luOiAwIDAgN3B4O1xufVxufSJdfQ== */