.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
justify-content: center;
align-items: center;
width: 40px;
height: 40px;
border-radius: 100%;
background: var(--bg-color-2);
color: var(--blue-to-white);
transition:
0.25s background,
0.25s color;

&:hover {
background: #2281ff;
color: #fff;
}
}