.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 13px;
}
@media (max-width: 1200px) {
.cls2 {
overflow: visible !important;
max-height: 100% !important
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL29yZ2FuaXNtcy9DYXJkSGlzdG9yeU9wZXJhdGlvbnNGaWx0ZXJzLUZpbHRlcnNSb3cubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxlQUFlO0FBTWY7QUFKQTtBQUxBO0FBTUEsNEJBQTRCO0FBQzVCO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9vcmdhbmlzbXMvQ2FyZEhpc3RvcnlPcGVyYXRpb25zRmlsdGVycy1GaWx0ZXJzUm93Lm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5mb250LXNpemU6IDEzcHg7XG5cbkBtZWRpYSAobWF4LXdpZHRoOiAxMjAwcHgpIHtcbm92ZXJmbG93OiB2aXNpYmxlICFpbXBvcnRhbnQ7XG5tYXgtaGVpZ2h0OiAxMDAlICFpbXBvcnRhbnQ7XG59XG59Il19 */