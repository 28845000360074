.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 12px;
max-width: 340px;
color: var(--main-third-text-color);
line-height: 1.3;
margin-top: 7px !important;
}
@media (max-width: 600px) {
.cls2 {
margin-top: 4px !important
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9IaXN0b3J5T3BlcmF0aW9uc1RlbXBsYXRlLU9wZXJhdGlvbkNvbW1lbnQubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxlQUFlO0FBQ2YsZ0JBQWdCO0FBQ2hCLG1DQUFtQztBQUNuQyxnQkFBZ0I7QUFDaEIsMEJBQTBCO0FBSzFCO0FBSEE7QUFUQTtBQVVBO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvSGlzdG9yeU9wZXJhdGlvbnNUZW1wbGF0ZS1PcGVyYXRpb25Db21tZW50Lm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5mb250LXNpemU6IDEycHg7XG5tYXgtd2lkdGg6IDM0MHB4O1xuY29sb3I6IHZhcigtLW1haW4tdGhpcmQtdGV4dC1jb2xvcik7XG5saW5lLWhlaWdodDogMS4zO1xubWFyZ2luLXRvcDogN3B4ICFpbXBvcnRhbnQ7XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xubWFyZ2luLXRvcDogNHB4ICFpbXBvcnRhbnQ7XG59XG59Il19 */