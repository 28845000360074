.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: center;
align-items: center;
margin-right: -19px;
margin-left: 6px;
width: 13px;
height: 13px;
background: var(--color-primary);
border-radius: 100%;
}
.cls2 svg {
color: #fff;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Vc2VyRHJvcERvd24tVmVyaWZpZWRMYWJlbC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGFBQWE7QUFDYix1QkFBdUI7QUFDdkIsbUJBQW1CO0FBQ25CLG1CQUFtQjtBQUNuQixnQkFBZ0I7QUFDaEIsV0FBVztBQUNYLFlBQVk7QUFDWixnQ0FBZ0M7QUFDaEMsbUJBQW1CO0FBS25CO0FBSEE7QUFDQSxXQUFXO0FBQ1giLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbW9sZWN1bGVzL1VzZXJEcm9wRG93bi1WZXJpZmllZExhYmVsLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5kaXNwbGF5OiBmbGV4O1xuanVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG5hbGlnbi1pdGVtczogY2VudGVyO1xubWFyZ2luLXJpZ2h0OiAtMTlweDtcbm1hcmdpbi1sZWZ0OiA2cHg7XG53aWR0aDogMTNweDtcbmhlaWdodDogMTNweDtcbmJhY2tncm91bmQ6IHZhcigtLWNvbG9yLXByaW1hcnkpO1xuYm9yZGVyLXJhZGl1czogMTAwJTtcblxuc3ZnIHtcbmNvbG9yOiAjZmZmO1xufVxufSJdfQ== */