.cls1 { /*!*/ }
.cls2 {
composes: cls1;

word-break: break-all;
margin-top: 5px;
padding: 4px 6px;
border-radius: 5px;
background: var(--bg-color-2);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9zdG9yZS9ub3RpY2VTdG9yZS1Ob3RpY2VTZXJ2ZXJFcnJvci5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLHFCQUFxQjtBQUNyQixlQUFlO0FBQ2YsZ0JBQWdCO0FBQ2hCLGtCQUFrQjtBQUNsQiw2QkFBNkI7QUFDN0IiLCJmaWxlIjoic3JjL3N0b3JlL25vdGljZVN0b3JlLU5vdGljZVNlcnZlckVycm9yLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG53b3JkLWJyZWFrOiBicmVhay1hbGw7XG5tYXJnaW4tdG9wOiA1cHg7XG5wYWRkaW5nOiA0cHggNnB4O1xuYm9yZGVyLXJhZGl1czogNXB4O1xuYmFja2dyb3VuZDogdmFyKC0tYmctY29sb3ItMik7XG59Il19 */