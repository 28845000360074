.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: inline-flex;
font-size: 15px;
font-weight: 600;
width: 78px;
z-index: 10;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Td2l0Y2hMYW5nLVN3aXRjaExhbmdTdHlsZWQubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxrQkFBa0I7QUFDbEIsb0JBQW9CO0FBQ3BCLGVBQWU7QUFDZixnQkFBZ0I7QUFDaEIsV0FBVztBQUNYLFdBQVc7QUFDWCIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvU3dpdGNoTGFuZy1Td2l0Y2hMYW5nU3R5bGVkLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5wb3NpdGlvbjogcmVsYXRpdmU7XG5kaXNwbGF5OiBpbmxpbmUtZmxleDtcbmZvbnQtc2l6ZTogMTVweDtcbmZvbnQtd2VpZ2h0OiA2MDA7XG53aWR0aDogNzhweDtcbnotaW5kZXg6IDEwO1xufSJdfQ== */