.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-weight: 600;
font-size: 18px;
line-height: 1;

@media (max-width: 600px) {
&.asPageTitleOnMobile {
font-weight: 400;
font-size: 28px;
}
}
}