.cls1 { /*!*/ }
.cls2 {
composes: cls1;

min-width: 42px !important;
height: 42px !important;
margin-right: 15px !important;
color: #2281ff;
}
.cls2 svg {
height: 42px;
}
@media (max-width: 600px) {
.cls2 {
min-width: 35px !important;
height: 35px !important;
background: #2281ff !important;
color: #fff !important
}

.cls2 svg {
height: auto !important;
width: 20px !important;
color: #fff;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9IaXN0b3J5T3BlcmF0aW9uc1RlbXBsYXRlLVRyYW5zZmVySWNvbi5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLDBCQUEwQjtBQUMxQix1QkFBdUI7QUFDdkIsNkJBQTZCO0FBQzdCLGNBQWM7QUFrQmQ7QUFoQkE7QUFDQSxZQUFZO0FBQ1o7QUFFQTtBQVpBO0FBYUEsMEJBQTBCO0FBQzFCLHVCQUF1QjtBQUN2Qiw4QkFBOEI7QUFDOUI7QUFRQTs7QUFOQTtBQUNBLHVCQUF1QjtBQUN2QixzQkFBc0I7QUFDdEIsV0FBVztBQUNYO0FBQ0EiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvdGVtcGxhdGVzL0hpc3RvcnlPcGVyYXRpb25zVGVtcGxhdGUtVHJhbnNmZXJJY29uLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5taW4td2lkdGg6IDQycHggIWltcG9ydGFudDtcbmhlaWdodDogNDJweCAhaW1wb3J0YW50O1xubWFyZ2luLXJpZ2h0OiAxNXB4ICFpbXBvcnRhbnQ7XG5jb2xvcjogIzIyODFmZjtcblxuc3ZnIHtcbmhlaWdodDogNDJweDtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG5taW4td2lkdGg6IDM1cHggIWltcG9ydGFudDtcbmhlaWdodDogMzVweCAhaW1wb3J0YW50O1xuYmFja2dyb3VuZDogIzIyODFmZiAhaW1wb3J0YW50O1xuY29sb3I6ICNmZmYgIWltcG9ydGFudDtcblxuc3ZnIHtcbmhlaWdodDogYXV0byAhaW1wb3J0YW50O1xud2lkdGg6IDIwcHggIWltcG9ydGFudDtcbmNvbG9yOiAjZmZmO1xufVxufVxufSJdfQ== */