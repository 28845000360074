.cls1 { /*!*/ }
.cls2 {
composes: cls1;

max-width: 700px;
font-size: 18px;
}
.cls2 span {
color: #2281ff;
}
.cls2 b {
font-weight: 600;
}
.cls2 a {
text-decoration: none;
color: #2281ff;
transition: 0.25s color;
}
.cls2 a:hover {
color: #7ccefd;
}
@media (max-width: 500px) {
.cls2 {
font-size: 15px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9BdXRoVGVtcGxhdGUtQXV0aFN0ZXBJbmZvLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsZ0JBQWdCO0FBQ2hCLGVBQWU7QUF1QmY7QUFyQkE7QUFDQSxjQUFjO0FBQ2Q7QUFFQTtBQUNBLGdCQUFnQjtBQUNoQjtBQUVBO0FBQ0EscUJBQXFCO0FBQ3JCLGNBQWM7QUFDZCx1QkFBdUI7QUFLdkI7QUFIQTtBQUNBLGNBQWM7QUFDZDtBQUdBO0FBeEJBO0FBeUJBO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvQXV0aFRlbXBsYXRlLUF1dGhTdGVwSW5mby5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxubWF4LXdpZHRoOiA3MDBweDtcbmZvbnQtc2l6ZTogMThweDtcblxuc3BhbiB7XG5jb2xvcjogIzIyODFmZjtcbn1cblxuYiB7XG5mb250LXdlaWdodDogNjAwO1xufVxuXG5hIHtcbnRleHQtZGVjb3JhdGlvbjogbm9uZTtcbmNvbG9yOiAjMjI4MWZmO1xudHJhbnNpdGlvbjogMC4yNXMgY29sb3I7XG5cbiY6aG92ZXIge1xuY29sb3I6ICM3Y2NlZmQ7XG59XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA1MDBweCkge1xuZm9udC1zaXplOiAxNXB4O1xufVxufSJdfQ== */