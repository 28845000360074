.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
gap: 8px;
padding-left: 89px;
font-size: 12px;
margin: -12px 0 18px;
transition: 0.25s color;
cursor: pointer;
text-decoration: none;
color: var(--main-third-text-color);
}
.cls2::before {
content: '';
width: 4px;
height: 4px;
border-radius: 100%;
background: var(--main-second-text-color);
transition: 0.25s background;
}
.cls2 span {
margin-left: -5px;
color: var(--main-text-color);
}
.cls2.active,
.cls2:hover {
color: #2281ff;
}
.cls2.active::before, .cls2:hover::before {
background: #2281ff;
}
@media (max-width: 768px) {
.cls2 {
padding-left: 0;
margin-left: 0
}
}
@media (max-width: 600px) {
.cls2 {
display: none
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL29yZ2FuaXNtcy9TaWRlYmFyTmF2LVN1Yk5hdkl0ZW0ubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxhQUFhO0FBQ2IsbUJBQW1CO0FBQ25CLFFBQVE7QUFDUixrQkFBa0I7QUFDbEIsZUFBZTtBQUNmLG9CQUFvQjtBQUNwQix1QkFBdUI7QUFDdkIsZUFBZTtBQUNmLHFCQUFxQjtBQUNyQixtQ0FBbUM7QUFpQ25DO0FBL0JBO0FBQ0EsV0FBVztBQUNYLFVBQVU7QUFDVixXQUFXO0FBQ1gsbUJBQW1CO0FBQ25CLHlDQUF5QztBQUN6Qyw0QkFBNEI7QUFDNUI7QUFFQTtBQUNBLGlCQUFpQjtBQUNqQiw2QkFBNkI7QUFDN0I7QUFFQTs7QUFFQSxjQUFjO0FBS2Q7QUFIQTtBQUNBLG1CQUFtQjtBQUNuQjtBQUdBO0FBckNBO0FBc0NBLGVBQWU7QUFDZjtBQU1BO0FBTEE7QUFFQTtBQTFDQTtBQTJDQTtBQUVBO0FBREEiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvb3JnYW5pc21zL1NpZGViYXJOYXYtU3ViTmF2SXRlbS5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuZGlzcGxheTogZmxleDtcbmFsaWduLWl0ZW1zOiBjZW50ZXI7XG5nYXA6IDhweDtcbnBhZGRpbmctbGVmdDogODlweDtcbmZvbnQtc2l6ZTogMTJweDtcbm1hcmdpbjogLTEycHggMCAxOHB4O1xudHJhbnNpdGlvbjogMC4yNXMgY29sb3I7XG5jdXJzb3I6IHBvaW50ZXI7XG50ZXh0LWRlY29yYXRpb246IG5vbmU7XG5jb2xvcjogdmFyKC0tbWFpbi10aGlyZC10ZXh0LWNvbG9yKTtcblxuJjo6YmVmb3JlIHtcbmNvbnRlbnQ6ICcnO1xud2lkdGg6IDRweDtcbmhlaWdodDogNHB4O1xuYm9yZGVyLXJhZGl1czogMTAwJTtcbmJhY2tncm91bmQ6IHZhcigtLW1haW4tc2Vjb25kLXRleHQtY29sb3IpO1xudHJhbnNpdGlvbjogMC4yNXMgYmFja2dyb3VuZDtcbn1cblxuc3BhbiB7XG5tYXJnaW4tbGVmdDogLTVweDtcbmNvbG9yOiB2YXIoLS1tYWluLXRleHQtY29sb3IpO1xufVxuXG4mLmFjdGl2ZSxcbiY6aG92ZXIge1xuY29sb3I6ICMyMjgxZmY7XG5cbiY6OmJlZm9yZSB7XG5iYWNrZ3JvdW5kOiAjMjI4MWZmO1xufVxufVxuXG5AbWVkaWEgKG1heC13aWR0aDogNzY4cHgpIHtcbnBhZGRpbmctbGVmdDogMDtcbm1hcmdpbi1sZWZ0OiAwO1xufVxuXG5AbWVkaWEgKG1heC13aWR0aDogNjAwcHgpIHtcbmRpc3BsYXk6IG5vbmU7XG59XG59Il19 */