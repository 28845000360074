@value cls1 as a0 from "./Header-SwitchLangStyled.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
z-index: 11;
display: flex;
justify-content: space-between;
align-items: center;
padding: 12px 50px;
background: var(--bg-color-1);
box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.1);

@media (max-width: 600px) {
position: fixed;
top: 0;
left: 0;
right: 0;
height: 50px;
padding: 0 15px;
backdrop-filter: saturate(180%) blur(20px);
z-index: 20;

&.theme-light {
background: rgba(255, 255, 255, 0.72);
}

&.theme-dark {
background: rgba(0, 0, 0, 0.72);
}
}

@media (max-width: 512px) {
box-shadow: 0 6px 12px rgba(73, 90, 102, 0.07);

.a0 {
display: none;
}
}
}