.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-bottom: 30px;
font-size: 32px;
font-weight: 700;
}
.cls2 a {
text-decoration: none;
color: var(--main-second-text-color);
}
@media (max-width: 768px) {
.cls2 {
font-size: 24px
}
}
@media (max-width: 512px) {
.cls2 {
font-size: 18px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9BdXRoVGVtcGxhdGUtU3dpdGNoUGFnZS5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLG1CQUFtQjtBQUNuQixlQUFlO0FBQ2YsZ0JBQWdCO0FBY2hCO0FBWkE7QUFDQSxxQkFBcUI7QUFDckIsb0NBQW9DO0FBQ3BDO0FBRUE7QUFaQTtBQWFBO0FBTUE7QUFMQTtBQUVBO0FBaEJBO0FBaUJBO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvQXV0aFRlbXBsYXRlLVN3aXRjaFBhZ2UubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbm1hcmdpbi1ib3R0b206IDMwcHg7XG5mb250LXNpemU6IDMycHg7XG5mb250LXdlaWdodDogNzAwO1xuXG5hIHtcbnRleHQtZGVjb3JhdGlvbjogbm9uZTtcbmNvbG9yOiB2YXIoLS1tYWluLXNlY29uZC10ZXh0LWNvbG9yKTtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDc2OHB4KSB7XG5mb250LXNpemU6IDI0cHg7XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA1MTJweCkge1xuZm9udC1zaXplOiAxOHB4O1xufVxufSJdfQ== */