.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: block;
font-weight: 600;
margin: 15px 0;
}
@media (max-width: 600px) {
.cls2 {
background: var(--bg-color-3);
border-radius: 6px;
font-size: 13px;
padding: 3px 12px;
white-space: nowrap;
color: var(--main-text-color);
font-weight: 400
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb250YWluZXJzL0RhdGVJbnB1dFdpdGhTaWRlYmFyLUxpbmtTdHlsZWQubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxjQUFjO0FBQ2QsZ0JBQWdCO0FBQ2hCLGNBQWM7QUFXZDtBQVRBO0FBUEE7QUFRQSw2QkFBNkI7QUFDN0Isa0JBQWtCO0FBQ2xCLGVBQWU7QUFDZixpQkFBaUI7QUFDakIsbUJBQW1CO0FBQ25CLDZCQUE2QjtBQUM3QjtBQUVBO0FBREEiLCJmaWxlIjoic3JjL2NvbnRhaW5lcnMvRGF0ZUlucHV0V2l0aFNpZGViYXItTGlua1N0eWxlZC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuZGlzcGxheTogYmxvY2s7XG5mb250LXdlaWdodDogNjAwO1xubWFyZ2luOiAxNXB4IDA7XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xuYmFja2dyb3VuZDogdmFyKC0tYmctY29sb3ItMyk7XG5ib3JkZXItcmFkaXVzOiA2cHg7XG5mb250LXNpemU6IDEzcHg7XG5wYWRkaW5nOiAzcHggMTJweDtcbndoaXRlLXNwYWNlOiBub3dyYXA7XG5jb2xvcjogdmFyKC0tbWFpbi10ZXh0LWNvbG9yKTtcbmZvbnQtd2VpZ2h0OiA0MDA7XG59XG59Il19 */