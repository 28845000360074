.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
top: 1px;
display: inline-flex;
align-items: center;
justify-content: center;
min-width: 16px;
height: 16px;
padding: 0 4px;
margin-left: 8px;
background: #2281ff;
color: white;
border-radius: 8px;
font-size: 11px;
font-weight: 500;
line-height: 1;
}
@media (max-width: 600px) {
.cls2 {
position: absolute;
top: 5px;
right: 50%;
transform: translateX(8px)
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL29yZ2FuaXNtcy9TaWRlYmFyTmF2LUJhZGdlLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsa0JBQWtCO0FBQ2xCLFFBQVE7QUFDUixvQkFBb0I7QUFDcEIsbUJBQW1CO0FBQ25CLHVCQUF1QjtBQUN2QixlQUFlO0FBQ2YsWUFBWTtBQUNaLGNBQWM7QUFDZCxnQkFBZ0I7QUFDaEIsbUJBQW1CO0FBQ25CLFlBQVk7QUFDWixrQkFBa0I7QUFDbEIsZUFBZTtBQUNmLGdCQUFnQjtBQUNoQixjQUFjO0FBUWQ7QUFOQTtBQW5CQTtBQW9CQSxrQkFBa0I7QUFDbEIsUUFBUTtBQUNSLFVBQVU7QUFDVjtBQUVBO0FBREEiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvb3JnYW5pc21zL1NpZGViYXJOYXYtQmFkZ2UubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbnBvc2l0aW9uOiByZWxhdGl2ZTtcbnRvcDogMXB4O1xuZGlzcGxheTogaW5saW5lLWZsZXg7XG5hbGlnbi1pdGVtczogY2VudGVyO1xuanVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG5taW4td2lkdGg6IDE2cHg7XG5oZWlnaHQ6IDE2cHg7XG5wYWRkaW5nOiAwIDRweDtcbm1hcmdpbi1sZWZ0OiA4cHg7XG5iYWNrZ3JvdW5kOiAjMjI4MWZmO1xuY29sb3I6IHdoaXRlO1xuYm9yZGVyLXJhZGl1czogOHB4O1xuZm9udC1zaXplOiAxMXB4O1xuZm9udC13ZWlnaHQ6IDUwMDtcbmxpbmUtaGVpZ2h0OiAxO1xuXG5AbWVkaWEgKG1heC13aWR0aDogNjAwcHgpIHtcbnBvc2l0aW9uOiBhYnNvbHV0ZTtcbnRvcDogNXB4O1xucmlnaHQ6IDUwJTtcbnRyYW5zZm9ybTogdHJhbnNsYXRlWCg4cHgpO1xufVxufSJdfQ== */