.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: fixed;
display: flex;
top: 0;
left: 0;
flex-direction: column;
width: 100vw;
height: 100vh;
justify-content: center;
align-items: center;
background: var(--bg-color-1);
z-index: 30;
cursor: progress;

&.withTransparent {
background: rgba(255, 255, 255, 0.3);
backdrop-filter: blur(8px);
}

&.visibleHeader {
z-index: 10;
}
}