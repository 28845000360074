.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: none;

@media (max-width: 600px) {
display: block;
min-width: 26px;
}
}