@value cls1 as a0 from "./Modal-ModalContent.module.css";

.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
position: relative;
max-width: 520px;
width: 100%;
max-height: calc(100% - 20px);
background: var(--bg-color-1);
box-sizing: border-box;
border-radius: 6px;
padding: 35px 45px;
margin: 0 auto;
}
.cls2.noXPadding {
padding-left: 0 !important;
padding-right: 0 !important;
}
.cls2.noXPadding .a0 {
margin-right: 0;
padding-right: 0;
}
.cls2.noYPadding {
padding-top: 0 !important;
padding-bottom: 0 !important;
}
@media (max-width: 600px) {
.cls2 {
opacity: 1;
padding: 30px 20px 0;
border-radius: 20px 20px 0 0;
max-height: calc(100% - 20px - env(safe-area-inset-top))
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Nb2RhbC1Db250YWluZXIubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSx3REFBd0Q7O0FBRXhELFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGFBQWE7QUFDYixrQkFBa0I7QUFDbEIsZ0JBQWdCO0FBQ2hCLFdBQVc7QUFDWCw2QkFBNkI7QUFDN0IsNkJBQTZCO0FBQzdCLHNCQUFzQjtBQUN0QixrQkFBa0I7QUFDbEIsa0JBQWtCO0FBQ2xCLGNBQWM7QUF1QmQ7QUFyQkE7QUFDQSwwQkFBMEI7QUFDMUIsMkJBQTJCO0FBTTNCO0FBSkE7QUFDQSxlQUFlO0FBQ2YsZ0JBQWdCO0FBQ2hCO0FBR0E7QUFDQSx5QkFBeUI7QUFDekIsNEJBQTRCO0FBQzVCO0FBRUE7QUE3QkE7QUE4QkEsVUFBVTtBQUNWLG9CQUFvQjtBQUNwQiw0QkFBNEI7QUFDNUI7QUFFQTtBQURBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Nb2RhbC1Db250YWluZXIubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIkB2YWx1ZSBjbHMxIGFzIGEwIGZyb20gXCIuL01vZGFsLU1vZGFsQ29udGVudC5tb2R1bGUuY3NzXCI7XG5cbi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmRpc3BsYXk6IGZsZXg7XG5wb3NpdGlvbjogcmVsYXRpdmU7XG5tYXgtd2lkdGg6IDUyMHB4O1xud2lkdGg6IDEwMCU7XG5tYXgtaGVpZ2h0OiBjYWxjKDEwMCUgLSAyMHB4KTtcbmJhY2tncm91bmQ6IHZhcigtLWJnLWNvbG9yLTEpO1xuYm94LXNpemluZzogYm9yZGVyLWJveDtcbmJvcmRlci1yYWRpdXM6IDZweDtcbnBhZGRpbmc6IDM1cHggNDVweDtcbm1hcmdpbjogMCBhdXRvO1xuXG4mLm5vWFBhZGRpbmcge1xucGFkZGluZy1sZWZ0OiAwICFpbXBvcnRhbnQ7XG5wYWRkaW5nLXJpZ2h0OiAwICFpbXBvcnRhbnQ7XG5cbi5hMCB7XG5tYXJnaW4tcmlnaHQ6IDA7XG5wYWRkaW5nLXJpZ2h0OiAwO1xufVxufVxuXG4mLm5vWVBhZGRpbmcge1xucGFkZGluZy10b3A6IDAgIWltcG9ydGFudDtcbnBhZGRpbmctYm90dG9tOiAwICFpbXBvcnRhbnQ7XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xub3BhY2l0eTogMTtcbnBhZGRpbmc6IDMwcHggMjBweCAwO1xuYm9yZGVyLXJhZGl1czogMjBweCAyMHB4IDAgMDtcbm1heC1oZWlnaHQ6IGNhbGMoMTAwJSAtIDIwcHggLSBlbnYoc2FmZS1hcmVhLWluc2V0LXRvcCkpO1xufVxufSJdfQ== */