.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: fixed;
display: none;
z-index: 4;
margin: auto;
transition: 0.25s transform;
}
@media (max-width: 1200px) {
.cls2 {
display: block;
right: 50%;
bottom: 18px;
transform: translateX(125%)
}
}
@media (max-width: 768px) {
.cls2 {
transform: translateX(50%)
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL29yZ2FuaXNtcy9DYXJkSGlzdG9yeU9wZXJhdGlvbnNGaWx0ZXJzLVNob3dGaWx0ZXJzQnV0dG9uV3JhcC5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLGVBQWU7QUFDZixhQUFhO0FBQ2IsVUFBVTtBQUNWLFlBQVk7QUFDWiwyQkFBMkI7QUFZM0I7QUFWQTtBQVRBO0FBVUEsY0FBYztBQUNkLFVBQVU7QUFDVixZQUFZO0FBQ1o7QUFNQTtBQUxBO0FBRUE7QUFoQkE7QUFpQkE7QUFFQTtBQURBIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL29yZ2FuaXNtcy9DYXJkSGlzdG9yeU9wZXJhdGlvbnNGaWx0ZXJzLVNob3dGaWx0ZXJzQnV0dG9uV3JhcC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxucG9zaXRpb246IGZpeGVkO1xuZGlzcGxheTogbm9uZTtcbnotaW5kZXg6IDQ7XG5tYXJnaW46IGF1dG87XG50cmFuc2l0aW9uOiAwLjI1cyB0cmFuc2Zvcm07XG5cbkBtZWRpYSAobWF4LXdpZHRoOiAxMjAwcHgpIHtcbmRpc3BsYXk6IGJsb2NrO1xucmlnaHQ6IDUwJTtcbmJvdHRvbTogMThweDtcbnRyYW5zZm9ybTogdHJhbnNsYXRlWCgxMjUlKTtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDc2OHB4KSB7XG50cmFuc2Zvcm06IHRyYW5zbGF0ZVgoNTAlKTtcbn1cbn0iXX0= */