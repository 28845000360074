.cls1 { /*!*/ }
.cls2 {
composes: cls1;

min-width: 42px !important;
height: 42px !important;
margin-right: 15px !important;
color: #2281ff;

svg {
height: 42px;
}

@media (max-width: 600px) {
min-width: 35px !important;
height: 35px !important;
background: #2281ff !important;
color: #fff !important;

svg {
height: auto !important;
width: 20px !important;
color: #fff;
}
}
}