.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 13px;
height: 13px;
border-radius: 13px;
background-color: #000;
transition: 0.25s background-color;

&.active {
background-color: #2281ff;
}
}