.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
align-items: center;
cursor: pointer;
transition: 0.25s color;

&.internal {
padding: 5px 9px;
font: 600 13px 'Open Sans';
}

svg {
width: 15px;
height: 15px;
border: 2px solid transparent;
border-radius: 100%;
transform: translate3d(0, 0, 0);
margin-right: 5px;
}

&.internal:not(.active):hover {
opacity: 1;
}

&.active {
cursor: default;

&::after {
width: 6px;
height: 6px;
background-color: #2281ff;
border-radius: 100%;
margin-left: 8px;
content: '';
}
}
}