.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 100%;

@media (max-width: 600px) {
position: relative;
margin-top: 50px;
}
}