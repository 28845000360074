.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 100%;
}
@media (max-width: 600px) {
.cls2 {
position: relative;
margin-top: 50px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb250YWluZXJzL0RhdGVJbnB1dFdpdGhTaWRlYmFyLVdyYXAubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxXQUFXO0FBTVg7QUFKQTtBQUxBO0FBTUEsa0JBQWtCO0FBQ2xCO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29udGFpbmVycy9EYXRlSW5wdXRXaXRoU2lkZWJhci1XcmFwLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG53aWR0aDogMTAwJTtcblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG5wb3NpdGlvbjogcmVsYXRpdmU7XG5tYXJnaW4tdG9wOiA1MHB4O1xufVxufSJdfQ== */