.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
z-index: 4;

@media (max-width: 768px) {
position: static;
display: flex;
align-items: center;
}
}