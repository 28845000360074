.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-right: 15px;

span {
display: block;
width: 10px;
height: 10px;
margin-top: 7px;
border-radius: 100%;
background: #2281ff;
}

@media (max-width: 600px) {
display: none;
}
}