.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex !important;
width: 32px !important;
height: 32px !important;
justify-content: center !important;
align-items: center !important;
border-radius: 100% !important;
color: #fff !important;
background: #2281ff !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3dpZGdldHMvTGl2ZUNoYXQtVGlja2V0SWNvbi5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLHdCQUF3QjtBQUN4QixzQkFBc0I7QUFDdEIsdUJBQXVCO0FBQ3ZCLGtDQUFrQztBQUNsQyw4QkFBOEI7QUFDOUIsOEJBQThCO0FBQzlCLHNCQUFzQjtBQUN0Qiw4QkFBOEI7QUFDOUIiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvd2lkZ2V0cy9MaXZlQ2hhdC1UaWNrZXRJY29uLm1vZHVsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2xzMSB7IC8qISovIH1cbi5jbHMyIHtcbmNvbXBvc2VzOiBjbHMxO1xuXG5kaXNwbGF5OiBmbGV4ICFpbXBvcnRhbnQ7XG53aWR0aDogMzJweCAhaW1wb3J0YW50O1xuaGVpZ2h0OiAzMnB4ICFpbXBvcnRhbnQ7XG5qdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlciAhaW1wb3J0YW50O1xuYWxpZ24taXRlbXM6IGNlbnRlciAhaW1wb3J0YW50O1xuYm9yZGVyLXJhZGl1czogMTAwJSAhaW1wb3J0YW50O1xuY29sb3I6ICNmZmYgIWltcG9ydGFudDtcbmJhY2tncm91bmQ6ICMyMjgxZmYgIWltcG9ydGFudDtcbn0iXX0= */