.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
justify-content: space-between;
align-items: baseline;
padding: 12px 20px;
font-size: 15px;
color: var(--main-text-color);
border-bottom: 1px solid var(--light-bg-color);
}
.cls2 a,
.cls2 button {
font-size: 12px;
color: #9ba1aa !important;
}
.cls2 a:hover, .cls2 button:hover {
color: #2281ff !important;
}
@media (max-width: 600px) {
.cls2 {
display: none
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Ob3RpY2VEcm9wRG93bi1IZWFkZXIubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxhQUFhO0FBQ2IsOEJBQThCO0FBQzlCLHFCQUFxQjtBQUNyQixrQkFBa0I7QUFDbEIsZUFBZTtBQUNmLDZCQUE2QjtBQUM3Qiw4Q0FBOEM7QUFlOUM7QUFiQTs7QUFFQSxlQUFlO0FBQ2YseUJBQXlCO0FBS3pCO0FBSEE7QUFDQSx5QkFBeUI7QUFDekI7QUFHQTtBQXJCQTtBQXNCQTtBQUVBO0FBREEiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbW9sZWN1bGVzL05vdGljZURyb3BEb3duLUhlYWRlci5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuZGlzcGxheTogZmxleDtcbmp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbmFsaWduLWl0ZW1zOiBiYXNlbGluZTtcbnBhZGRpbmc6IDEycHggMjBweDtcbmZvbnQtc2l6ZTogMTVweDtcbmNvbG9yOiB2YXIoLS1tYWluLXRleHQtY29sb3IpO1xuYm9yZGVyLWJvdHRvbTogMXB4IHNvbGlkIHZhcigtLWxpZ2h0LWJnLWNvbG9yKTtcblxuYSxcbmJ1dHRvbiB7XG5mb250LXNpemU6IDEycHg7XG5jb2xvcjogIzliYTFhYSAhaW1wb3J0YW50O1xuXG4mOmhvdmVyIHtcbmNvbG9yOiAjMjI4MWZmICFpbXBvcnRhbnQ7XG59XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xuZGlzcGxheTogbm9uZTtcbn1cbn0iXX0= */