.cls1 { /*!*/ }
.cls2 {
composes: cls1;

padding-top: 20px;
border-top: 1px solid var(--light-bg-color);

&.noFooterBorder {
border-top: none;
}

@media (max-width: 600px) {
padding-top: 15px;
padding-bottom: calc(env(safe-area-inset-bottom) - 7px);
}
}