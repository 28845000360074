.cls1 { /*!*/ }
.cls2 {
composes: cls1;

&:not(.modalContentNoOverflowAuto) {
overflow: auto;
}
padding: 20px 0;
margin-right: -45px;
padding-right: 45px;

&::-webkit-scrollbar {
width: 6px;
}

&::-webkit-scrollbar-track {
background-color: var(--main-second-text-color);
}

&.theme-dark {
&::-webkit-scrollbar-track {
background-color: #28344a;
}
}

&::-webkit-scrollbar-thumb {
background-color: #2281ff;
border-radius: 8px;
}

@media (max-width: 600px) {
margin: 0 -20px;
padding: 10px 20px;

&::-webkit-scrollbar {
display: none;
}
}
}