.cls1 { /*!*/ }
.cls2 {
composes: cls1;

margin-bottom: 0;
line-height: 1.3;

@media (max-width: 600px) {
margin-top: 0;
font-weight: 400;
font-size: 28px;

&.sub {
font-size: 20px;
}
}
}