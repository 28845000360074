.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border-top: 1px solid var(--light-bg-color);
margin: 25px 0 !important;
padding-top: 18px;
}
.cls2 a {
color: #ff5c21 !important;
}
.cls2:hover a {
color: #ff5c21 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Vc2VyRHJvcERvd24tTG9nb3V0TGlzdEl0ZW0ubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCwyQ0FBMkM7QUFDM0MseUJBQXlCO0FBQ3pCLGlCQUFpQjtBQVNqQjtBQVBBO0FBQ0EseUJBQXlCO0FBQ3pCO0FBRUE7QUFDQSx5QkFBeUI7QUFDekIiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbW9sZWN1bGVzL1VzZXJEcm9wRG93bi1Mb2dvdXRMaXN0SXRlbS5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuYm9yZGVyLXRvcDogMXB4IHNvbGlkIHZhcigtLWxpZ2h0LWJnLWNvbG9yKTtcbm1hcmdpbjogMjVweCAwICFpbXBvcnRhbnQ7XG5wYWRkaW5nLXRvcDogMThweDtcblxuYSB7XG5jb2xvcjogI2ZmNWMyMSAhaW1wb3J0YW50O1xufVxuXG4mOmhvdmVyIGEge1xuY29sb3I6ICNmZjVjMjEgIWltcG9ydGFudDtcbn1cbn0iXX0= */