.cls1 { /*!*/ }
.cls2 {
composes: cls1;

width: 160px;
padding: 5px 20px 0;
border-right: 1px solid var(--light-bg-color);
}
.cls2 button {
width: 100%;
margin-bottom: 10px;
}
.cls2.onlyCalendar {
padding: 0;
}
@media (max-width: 600px) {
.cls2 {
position: absolute;
display: flex;
gap: 10px;
overflow: auto;
width: calc(100% + 40px);
top: -60px;
left: -20px;
right: -20px;
box-sizing: border-box;
padding-left: 20px !important;
padding-right: 20px !important
}

.cls2::-webkit-scrollbar {
display: none;
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb250YWluZXJzL0RhdGVJbnB1dFdpdGhTaWRlYmFyLURhdGVTZWxlY3RvcnMubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxRQUFRLElBQUksRUFBRTtBQUNkO0FBQ0EsY0FBYzs7QUFFZCxZQUFZO0FBQ1osbUJBQW1CO0FBQ25CLDZDQUE2QztBQTRCN0M7QUExQkE7QUFDQSxXQUFXO0FBQ1gsbUJBQW1CO0FBQ25CO0FBRUE7QUFDQSxVQUFVO0FBQ1Y7QUFFQTtBQWhCQTtBQWlCQSxrQkFBa0I7QUFDbEIsYUFBYTtBQUNiLFNBQVM7QUFDVCxjQUFjO0FBQ2Qsd0JBQXdCO0FBQ3hCLFVBQVU7QUFDVixXQUFXO0FBQ1gsWUFBWTtBQUNaLHNCQUFzQjtBQUN0Qiw2QkFBNkI7QUFDN0I7QUFNQTs7QUFKQTtBQUNBLGFBQWE7QUFDYjtBQUNBIiwiZmlsZSI6InNyYy9jb250YWluZXJzL0RhdGVJbnB1dFdpdGhTaWRlYmFyLURhdGVTZWxlY3RvcnMubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbndpZHRoOiAxNjBweDtcbnBhZGRpbmc6IDVweCAyMHB4IDA7XG5ib3JkZXItcmlnaHQ6IDFweCBzb2xpZCB2YXIoLS1saWdodC1iZy1jb2xvcik7XG5cbmJ1dHRvbiB7XG53aWR0aDogMTAwJTtcbm1hcmdpbi1ib3R0b206IDEwcHg7XG59XG5cbiYub25seUNhbGVuZGFyIHtcbnBhZGRpbmc6IDA7XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiA2MDBweCkge1xucG9zaXRpb246IGFic29sdXRlO1xuZGlzcGxheTogZmxleDtcbmdhcDogMTBweDtcbm92ZXJmbG93OiBhdXRvO1xud2lkdGg6IGNhbGMoMTAwJSArIDQwcHgpO1xudG9wOiAtNjBweDtcbmxlZnQ6IC0yMHB4O1xucmlnaHQ6IC0yMHB4O1xuYm94LXNpemluZzogYm9yZGVyLWJveDtcbnBhZGRpbmctbGVmdDogMjBweCAhaW1wb3J0YW50O1xucGFkZGluZy1yaWdodDogMjBweCAhaW1wb3J0YW50O1xuXG4mOjotd2Via2l0LXNjcm9sbGJhciB7XG5kaXNwbGF5OiBub25lO1xufVxufVxufSJdfQ== */