.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: absolute !important;
background: white !important;
box-shadow: rgba(0, 0, 0, 0.15) 0 5px 38px !important;
border-radius: 10px !important;
padding: 10px !important;
min-width: 130px !important;
margin-right: 10px !important;
right: 100% !important;
cursor: pointer !important;

span {
font-weight: 600 !important;
}
}