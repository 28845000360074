.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border-bottom: 1px solid var(--light-bg-color);
padding-bottom: 15px;
line-height: 1.5;
font-size: 24px;
font-weight: bold;
}
.cls2.noTitleBorder {
border-bottom: none;
padding-bottom: 0;
}
@media (max-width: 600px) {
.cls2 {
font-weight: 400;
font-size: 22px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Nb2RhbC1UaXRsZS5tb2R1bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFFBQVEsSUFBSSxFQUFFO0FBQ2Q7QUFDQSxjQUFjOztBQUVkLDhDQUE4QztBQUM5QyxvQkFBb0I7QUFDcEIsZ0JBQWdCO0FBQ2hCLGVBQWU7QUFDZixpQkFBaUI7QUFXakI7QUFUQTtBQUNBLG1CQUFtQjtBQUNuQixpQkFBaUI7QUFDakI7QUFFQTtBQWRBO0FBZUEsZ0JBQWdCO0FBQ2hCO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvTW9kYWwtVGl0bGUubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jbHMxIHsgLyohKi8gfVxuLmNsczIge1xuY29tcG9zZXM6IGNsczE7XG5cbmJvcmRlci1ib3R0b206IDFweCBzb2xpZCB2YXIoLS1saWdodC1iZy1jb2xvcik7XG5wYWRkaW5nLWJvdHRvbTogMTVweDtcbmxpbmUtaGVpZ2h0OiAxLjU7XG5mb250LXNpemU6IDI0cHg7XG5mb250LXdlaWdodDogYm9sZDtcblxuJi5ub1RpdGxlQm9yZGVyIHtcbmJvcmRlci1ib3R0b206IG5vbmU7XG5wYWRkaW5nLWJvdHRvbTogMDtcbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG5mb250LXdlaWdodDogNDAwO1xuZm9udC1zaXplOiAyMnB4O1xufVxufSJdfQ== */