.cls1 { /*!*/ }
.cls2 {
composes: cls1;

background: var(--bg-color-2);
margin-top: 48px;
}
.cls2.isHomePage {
position: absolute;
bottom: -90px;
left: 48px;
right: 48px;
margin: 0;
padding: 0;
background: none;
}
@media (max-width: 600px) {
.cls2.isHomePage {
position: static;
margin: -10px 0 20px;
padding: 0 15px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL3RlbXBsYXRlcy9Qcml2YXRlVGVtcGxhdGUtRm9vdGVyU3R5bGVkLm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsNkJBQTZCO0FBQzdCLGdCQUFnQjtBQWlCaEI7QUFmQTtBQUNBLGtCQUFrQjtBQUNsQixhQUFhO0FBQ2IsVUFBVTtBQUNWLFdBQVc7QUFDWCxTQUFTO0FBQ1QsVUFBVTtBQUNWLGdCQUFnQjtBQU9oQjtBQUxBO0FBVEE7QUFVQSxnQkFBZ0I7QUFDaEIsb0JBQW9CO0FBQ3BCO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy90ZW1wbGF0ZXMvUHJpdmF0ZVRlbXBsYXRlLUZvb3RlclN0eWxlZC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuYmFja2dyb3VuZDogdmFyKC0tYmctY29sb3ItMik7XG5tYXJnaW4tdG9wOiA0OHB4O1xuXG4mLmlzSG9tZVBhZ2Uge1xucG9zaXRpb246IGFic29sdXRlO1xuYm90dG9tOiAtOTBweDtcbmxlZnQ6IDQ4cHg7XG5yaWdodDogNDhweDtcbm1hcmdpbjogMDtcbnBhZGRpbmc6IDA7XG5iYWNrZ3JvdW5kOiBub25lO1xuXG5AbWVkaWEgKG1heC13aWR0aDogNjAwcHgpIHtcbnBvc2l0aW9uOiBzdGF0aWM7XG5tYXJnaW46IC0xMHB4IDAgMjBweDtcbnBhZGRpbmc6IDAgMTVweDtcbn1cbn1cbn0iXX0= */