.cls1 { /*!*/ }
.cls2 {
composes: cls1;
}
.cls2 svg {
margin-right: 20px;
transform: scale(1);
transition: 0.25s transform;
}
.cls2.fullView svg {
transform: scale(-1);
}
@media (max-width: 1200px) {
.cls2 {
display: none
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL29yZ2FuaXNtcy9DYXJkSGlzdG9yeU9wZXJhdGlvbnNGaWx0ZXJzLVRvZ2dsZUZpbHRlcnNWaWV3Lm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7QUFpQmQ7QUFmQTtBQUNBLGtCQUFrQjtBQUNsQixtQkFBbUI7QUFDbkIsMkJBQTJCO0FBQzNCO0FBR0E7QUFDQSxvQkFBb0I7QUFDcEI7QUFHQTtBQWZBO0FBZ0JBO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9vcmdhbmlzbXMvQ2FyZEhpc3RvcnlPcGVyYXRpb25zRmlsdGVycy1Ub2dnbGVGaWx0ZXJzVmlldy5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxuc3ZnIHtcbm1hcmdpbi1yaWdodDogMjBweDtcbnRyYW5zZm9ybTogc2NhbGUoMSk7XG50cmFuc2l0aW9uOiAwLjI1cyB0cmFuc2Zvcm07XG59XG5cbiYuZnVsbFZpZXcge1xuc3ZnIHtcbnRyYW5zZm9ybTogc2NhbGUoLTEpO1xufVxufVxuXG5AbWVkaWEgKG1heC13aWR0aDogMTIwMHB4KSB7XG5kaXNwbGF5OiBub25lO1xufVxufSJdfQ== */