.cls1 { /*!*/ }
.cls2 {
composes: cls1;

list-style: none;
padding: 0;
margin: 20px 0 0 0;
font-size: 14px;
}
.cls2 li {
margin: 20px 0;
}
.cls2 li:last-child {
margin-bottom: 0 !important;
}
.cls2 li a {
color: var(--main-text-color);
}
.cls2 li:hover {
color: #2281ff !important;
}
.cls2 li:hover a {
color: #2281ff !important;
}
@media (max-width: 600px) {
.cls2 {
font-size: 16px
}
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL21vbGVjdWxlcy9Vc2VyRHJvcERvd24tTmF2Lm1vZHVsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsUUFBUSxJQUFJLEVBQUU7QUFDZDtBQUNBLGNBQWM7O0FBRWQsZ0JBQWdCO0FBQ2hCLFVBQVU7QUFDVixrQkFBa0I7QUFDbEIsZUFBZTtBQXlCZjtBQXZCQTtBQUNBLGNBQWM7QUFpQmQ7QUFmQTtBQUNBLDJCQUEyQjtBQUMzQjtBQUVBO0FBQ0EsNkJBQTZCO0FBQzdCO0FBRUE7QUFDQSx5QkFBeUI7QUFLekI7QUFIQTtBQUNBLHlCQUF5QjtBQUN6QjtBQUlBO0FBNUJBO0FBNkJBO0FBRUE7QUFEQSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9tb2xlY3VsZXMvVXNlckRyb3BEb3duLU5hdi5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsczEgeyAvKiEqLyB9XG4uY2xzMiB7XG5jb21wb3NlczogY2xzMTtcblxubGlzdC1zdHlsZTogbm9uZTtcbnBhZGRpbmc6IDA7XG5tYXJnaW46IDIwcHggMCAwIDA7XG5mb250LXNpemU6IDE0cHg7XG5cbmxpIHtcbm1hcmdpbjogMjBweCAwO1xuXG4mOmxhc3QtY2hpbGQge1xubWFyZ2luLWJvdHRvbTogMCAhaW1wb3J0YW50O1xufVxuXG5hIHtcbmNvbG9yOiB2YXIoLS1tYWluLXRleHQtY29sb3IpO1xufVxuXG4mOmhvdmVyIHtcbmNvbG9yOiAjMjI4MWZmICFpbXBvcnRhbnQ7XG5cbmEge1xuY29sb3I6ICMyMjgxZmYgIWltcG9ydGFudDtcbn1cbn1cbn1cblxuQG1lZGlhIChtYXgtd2lkdGg6IDYwMHB4KSB7XG5mb250LXNpemU6IDE2cHg7XG59XG59Il19 */