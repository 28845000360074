.cls1 { /*!*/ }
.cls2 {
composes: cls1;

svg {
margin-right: 20px;
transform: scale(1);
transition: 0.25s transform;
}

&.fullView {
svg {
transform: scale(-1);
}
}

@media (max-width: 1200px) {
display: none;
}
}